/* eslint-disable @typescript-eslint/explicit-function-return-type */
import dayjs from 'dayjs';

import { DateConstants } from '@ecp/utils/date';
import {
  accountRegex,
  amexCardRegex,
  discoverCardRegex,
  masterCardRegex,
  routingRegex,
  visaCardRegex,
} from '@ecp/utils/payment';

import type { ProductName } from '@ecp/features/shared/product';
import type { Question, Questions } from '@ecp/types';

import metadata from './metadata';
/**
 * Temporary workaround for: https://github.com/microsoft/TypeScript/issues/41247.
 * Remove after TS 4.7.0 upgrade:
 * https://github.com/microsoft/TypeScript/issues/47920
 * https://www.staging-typescript.org/play?ts=4.7.0-pr-46827-30#code/C4TwDgpgBA0hIGcoF4oHICGaoB90CNs80BjI9AEzQG4AoWgegagGEALCEgaymDY2BQMAG2FQwAJwD2kCaChd4SElIB2AMykSAtrylQEU7dFCRaK1QkGHjABWlgEAeXVxEKKAG9aUX0IBcUAAMADQ+fviBAEQcolJRYX5QAB6BABxQTFAAoskkEAhIkjIQciBQAJZI6sIYAOZ1EBS0AL4GAlXqFQVQthhyFSIAPABKnFoUQ24IIVAArqpcqlIA7qoAfOt0jMwAKuDQFRpa2h1qUFLqUBAYJGziDqXyVVCnR8AYR020NhD2Ms5XEoAHQYYHAKQAMQqySaAAoAJR0X7-RwuabA-DAhBzfBWCRHOpwgCMSJ2UAAysAKqIhKprhJpBJZmgqJUkNoqghCeY1IZhBBgcIpESUQ5ARiKGTyexODw+AIhLTirJ5Ip3P1oJIChBVMBeZZBCJhKiJUoPN4khhAqFwr5IlAYhA4gk7VASIFgBI5hBWu1qQguj0xioJJNprMFktVhtqJlmBR2a8uTysvtIJVjjozvSJBAPl9mgKjR5jab0SCwRDobCKIi6MWoPhS6Jy0DEJjsbj8STpVlZdxePwjcrHgMegA3EQ+91qTQ6PQGIwmA60UzQSG3YBIVCeKAAbXVgXxhIAuoF8FIpAKMPSWnQKJxanmoOoFiRqec7nLdt6+Ag4VSKBNw-BAEUCCcpAqCgHyfTVX3fT96W-bgAEkEAAQRWDBFEAwI9yqLCcIgC8rxvO9wKgSDoLoCwrBSC03UI7DFE9b1fTaBAOkDbokBA7c6CyAAhKQ+CgJwYHMDhuF-OZ-0AskUK4dCiNw5IySybJGS0WYjkfZIDAqOpVAEOYXwoKQClUNBBBIPMBGgfp8AqL1+nKFUnl4g1+UFYUiWSYEAC8NOYABZKpTmAb9Ew8soqOnQ5qlqBpvjowRkgAJkYpJtDYn1Ej8BBonUEQEAgKI-S4gMgz4rcEG2LIMOWPhSmuZIMG0MABVoCBkjALRBHXVhry0C0oAkQJVDmbR8FKWY6km6bZuZJtFpm1r73JDDaUuIcdQMNgpDmYRE1m4bhQkGYmzkqBUIuVRhHKOYyqXYwLiuFrdA4PNgR6vqBtnQ1ehEfNgGgXc3RWNgXJIrxxsCDKAFZEfmhHkdmB0kcRqAWgK+1am4fD4eCVGSagCgbRx2YsiGABaC4AQAQkobAjnEAnoF2tBCDdfBhB9ImJrJhaycx5Gqcq7iaqgEMJiGE9VDqWYWBGiQtm869fJFOE+mLMHMX5iAQuGxlOGAR7X2SxpEwwJBSiZDWBSFbXddB3yjLYYAAH0zK6o3tnJVC9VKEr8kqTqBWMPUczXA4oFCqQpz58GvDdbRE4gOEKCqD5VHyNblso6iYNaWi+Vs-pssKj45EROHcbTjOs4RVOkiSLJEwQQ7jtO6ApvWiQ3QbpIrBkOu9xaSXqt4+PE4wZOoAAMhl8Yw3lr1CUjRZljWdWsjbg+D4APRP0+z-Pi+z-JQ+b98KJtuFFY2vyQoHhKccEAqmVpJ4GQkJEN+qpyjriQFDGkWo8xlQkBOQkDwdR50OMcWOGZbBQT1BlRMu4UgFzmlAEAOCJA4zLkDbQIBUHvDGkBYkQQcb+k6DPPoAxhjkPQRQdWaVXhkLQcAISGBMFwyAlEahURaFVXoT0Rh1JmHcIwXvZgElZjLCgMzVQEAmhNCgN9X0dFNbOyJKQlhwBgQBWrDCeECJjYUm7idBkDtGxgA8AY7hwIQANWYCrYOyRgBzAAaYHkQ17BNAqCQByO44b7iKgYDeitzxQDhKoAhLdkDrCbGRG4d5tgcO1BQSJgTs4hLBmEy0fgqjZAnLqSaKAUn0gAKRQCysgRpJMmLOAoBTKA9JkkdKgHUhpTTiRT3EUgPJwTQl0CAA
 */
export const satisfies = <T extends Questions>(p: T): T => p;

const CommonFieldsMeta = satisfies({
  paymentMethod: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
  },
  paymentAcknowledgement: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please check the box',
        $regex: '^true',
      },
    ],
  },
  autoWithdrawAgree: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please check the box',
        $regex: '^true',
      },
    ],
  },
  customerAcknowledgement: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please check the box',
        $regex: '^true',
      },
    ],
  },
  acknowledgementName: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please enter your first and last name separated by a space',
        $regex: '^\\S+\\s\\S',
      },
    ],
  },
  checkboxAcknowledgement: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please check the box',
        $regex: '^true',
      },
    ],
  },
  pniAcknowledgementName: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please enter your first and last name separated by a space',
        $regex: '^\\S+\\s\\S',
      },
    ],
  },
  signatureAcknowledgement: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please check the box',
        $regex: '^true',
      },
    ],
  },
  sniAcknowledgementName: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please enter your first and last name separated by a space',
        $regex: '^\\S+\\s\\S',
      },
    ],
  },
});

const BankFieldsMeta = satisfies({
  accountUse: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    options: [
      {
        label: 'Personal',
        value: 'PERSONAL',
      },
      {
        label: 'Business use',
        value: 'BUSINESS',
      },
    ],
  },
  accountType: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    options: [
      {
        label: 'Checking',
        value: 'CHECKING',
      },
      {
        label: 'Savings',
        value: 'SAVINGS',
      },
    ],
  },
  routingNumber: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please enter a valid routing number',
        $regex: routingRegex.source,
      },
    ],
  },
  accountNumber: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Please enter a valid account number',
        $regex: accountRegex.source,
      },
    ],
  },
});

const MortgageFieldsMeta = satisfies({
  mortgageKnowMortgageCompany: {
    businessType: ['home'],
    answerType: 'Boolean',
    questionType: 'optional',
    label: 'Know Mortgage Company',
  },
  mortgageCompany: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['home'],
    label: 'Mortgage Company Name',
    criteria: [
      {
        error: `Please enter a shorter lender's name (80 characters maximum)`,
        $regex: '^.{1,80}$',
      },
    ],
  },
  mortgageLoanNumber: {
    answerType: 'String',
    questionType: 'required',
    businessType: ['home'],
    label: 'Mortgage loan number (optional)',
    criteria: [
      {
        error: 'Please enter a shorter loan number (30 characters maximum)',
        $regex: '^.{1,30}$',
      },
    ],
  },
  mortgageCity: {
    description: 'City',
    label: 'City (optional)',
    minLength: 0,
    maxLength: 30,
    answerType: 'String',
    businessType: ['home', 'auto'],
  },
  mortgageZip: {
    description: 'Zip Code',
    label: 'Zip Code (optional)',
    minLength: 5,
    maxLength: 5,
    answerType: 'String',
    businessType: ['home', 'auto'],
    criteria: [
      {
        $regex: '^[0-9]{5}$',
      },
    ],
  },
  mortgageAddressLine2: {
    description: 'Address Line 2',
    label: 'Suite/Unit # (optional)',
    answerType: 'String',
    businessType: ['home', 'auto'],
    criteria: [
      {
        $regex: '',
      },
    ],
  },
  mortgageAddressLine1: {
    description: 'Address Line 1',
    label: 'Address (optional)',
    answerType: 'String',
    businessType: ['home', 'auto'],
    criteria: [
      {
        error: `Please enter a shorter address (40 characters maximum)`,
        $regex: `^[-\\w\\d\\s'.#,]{1,40}$`,
      },
    ],
  },
  mortgageState: {
    description: 'State',
    label: 'State (optional)',
    answerType: 'String',
    businessType: ['home', 'auto'],
    options: [
      {
        value: 'AL',
        label: 'AL',
      },
      {
        value: 'AK',
        label: 'AK',
      },
      {
        value: 'AZ',
        label: 'AZ',
      },
      {
        value: 'AR',
        label: 'AR',
      },
      {
        value: 'CA',
        label: 'CA',
      },
      {
        value: 'CO',
        label: 'CO',
      },
      {
        value: 'CT',
        label: 'CT',
      },
      {
        value: 'DE',
        label: 'DE',
      },
      {
        value: 'DC',
        label: 'DC',
      },
      {
        value: 'FL',
        label: 'FL',
      },
      {
        value: 'GA',
        label: 'GA',
      },
      {
        value: 'HI',
        label: 'HI',
      },
      {
        value: 'ID',
        label: 'ID',
      },
      {
        value: 'IL',
        label: 'IL',
      },
      {
        value: 'IN',
        label: 'IN',
      },
      {
        value: 'IA',
        label: 'IA',
      },
      {
        value: 'KS',
        label: 'KS',
      },
      {
        value: 'KY',
        label: 'KY',
      },
      {
        value: 'LA',
        label: 'LA',
      },
      {
        value: 'ME',
        label: 'ME',
      },
      {
        value: 'MA',
        label: 'MA',
      },
      {
        value: 'MD',
        label: 'MD',
      },
      {
        value: 'MI',
        label: 'MI',
      },
      {
        value: 'MN',
        label: 'MN',
      },
      {
        value: 'MS',
        label: 'MS',
      },
      {
        value: 'MO',
        label: 'MO',
      },
      {
        value: 'MT',
        label: 'MT',
      },
      {
        value: 'NE',
        label: 'NE',
      },
      {
        value: 'NV',
        label: 'NV',
      },
      {
        value: 'NH',
        label: 'NH',
      },
      {
        value: 'NJ',
        label: 'NJ',
      },
      {
        value: 'NM',
        label: 'NM',
      },
      {
        value: 'NY',
        label: 'NY',
      },
      {
        value: 'NC',
        label: 'NC',
      },
      {
        value: 'ND',
        label: 'ND',
      },
      {
        value: 'OH',
        label: 'OH',
      },
      {
        value: 'OK',
        label: 'OK',
      },
      {
        value: 'OR',
        label: 'OR',
      },
      {
        value: 'PA',
        label: 'PA',
      },
      {
        value: 'RI',
        label: 'RI',
      },
      {
        value: 'SC',
        label: 'SC',
      },
      {
        value: 'SD',
        label: 'SD',
      },
      {
        value: 'TN',
        label: 'TN',
      },
      {
        value: 'TX',
        label: 'TX',
      },
      {
        value: 'UT',
        label: 'UT',
      },
      {
        value: 'VT',
        label: 'VT',
      },
      {
        value: 'VA',
        label: 'VA',
      },
      {
        value: 'WA',
        label: 'WA',
      },
      {
        value: 'WV',
        label: 'WV',
      },
      {
        value: 'WI',
        label: 'WI',
      },
      {
        value: 'WY',
        label: 'WY',
      },
    ],
  },
});

function getCreditCardFieldsMeta(productName: ProductName) {
  return satisfies({
    creditCardFullName: {
      answerType: 'String',
      questionType: 'required',
      businessType: ['auto', 'home', 'renters'],
      criteria: [
        {
          error: 'Please enter your first and last name separated by a space',
          $regex: '^\\S+\\s\\S',
        },
      ],
      required: `{"@static.checkout.${productName}.paymentMethod": {"$in": ["CreditCard"]}}`,
    },
    creditCardExpirationDate: {
      answerType: 'String',
      questionType: 'required',
      businessType: ['auto', 'home', 'renters'],
      criteria: [
        {
          error: 'Invalid expiration date',
          $and: [
            {
              $regex: '^20[0-9][0-9]-[0-1][0-9]-[0-3][0-9]$',
            },
            {
              $gt: {
                $date: `${dayjs().format(DateConstants.ANSWERS_FORMAT)}`,
              },
            },
          ],
        },
      ],
      required: `{"@static.checkout.${productName}.paymentMethod": {"$in": ["CreditCard"]}}`,
    },
    creditCardCardNumber: {
      answerType: 'String',
      questionType: 'required',
      businessType: ['auto', 'home', 'renters'],
      criteria: [
        {
          error: 'Please enter a valid card number',
          $or: [
            { [`@static.checkout.${productName}.paymentMethod`]: { $eq: 'CostcoVisa' } },
            // regexes for credit cards:
            {
              $regex: amexCardRegex.source,
            },
            {
              $regex: visaCardRegex.source,
            },
            {
              $regex: masterCardRegex.source,
            },
            {
              $regex: discoverCardRegex.source,
            },
          ],
        },
        {
          error: 'Please enter a valid Costco Visa card number',
          $or: [
            { [`@static.checkout.${productName}.paymentMethod`]: { $ne: 'CostcoVisa' } },
            { $regex: '^4[0-9]{12}(?:[0-9]{3})?$' },
          ], // regex for a visa card number
        },
      ],
      required: `{"@static.checkout.${productName}.paymentMethod": {"$in": ["CreditCard"]}}`,
    },
  });
}
function getCostcoCardFieldsMeta(productName: ProductName) {
  return satisfies({
    costcoCardFullName: {
      answerType: 'String',
      questionType: 'required',
      businessType: ['auto', 'home', 'renters'],
      criteria: [
        {
          error: 'Please enter your first and last name separated by a space',
          $regex: '^\\S+\\s\\S',
        },
      ],
      required: `{"@static.checkout.${productName}.paymentMethod": {"$in": ["CostcoVisa"]}}`,
    },
    costcoCardExpirationDate: {
      answerType: 'String',
      questionType: 'required',
      businessType: ['auto', 'home', 'renters'],
      criteria: [
        {
          error: 'Invalid expiration date',
          $and: [
            {
              $regex: '^20[0-9][0-9]-[0-1][0-9]-[0-3][0-9]$',
            },

            {
              $gt: {
                $date: `${dayjs().format(DateConstants.ANSWERS_FORMAT)}`,
              },
            },
          ],
        },
      ],
      required: `{"@static.checkout.${productName}.paymentMethod": {"$in": ["CostcoVisa"]}}`,
    },
    costcoCardNumber: {
      answerType: 'String',
      questionType: 'required',
      businessType: ['auto', 'home', 'renters'],
      criteria: [
        {
          error: 'Please enter a valid card number',
          $or: [
            { [`@static.checkout.${productName}.paymentMethod`]: { $eq: 'CostcoVisa' } },
            // regexes for credit cards:
            {
              // for AMEX cards we need the first character to be 3, a 4 or a 7
              // and then 14 characters after that for a total of 15 characters total
              $regex: '^3[47][0-9]{13}$',
            },
            {
              // VISA cards start with 4. New VISA cards will always have 16 digits
              // but older ones can have 13 and this will match both cases
              $regex: '^4[0-9]{12}(?:[0-9]{3})?$',
            },
            {
              // MasterCard numbers either start with the numbers 51 through 55
              // or with the numbers 2221 through 2720. All have 16 digits.
              $regex:
                '^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$',
            },
            {
              // Discover card numbers begin with 6011 or 65. All have 16 digits
              $regex: '^6(?:011|5[0-9]{2})[0-9]{12}$',
            },
          ],
        },
        {
          error: 'Please enter a valid Costco Visa card number',
          $or: [
            { [`@static.checkout.${productName}.paymentMethod`]: { $ne: 'CostcoVisa' } },
            { $regex: '^4[0-9]{12}(?:[0-9]{3})?$' },
          ], // regex for a visa card number
        },
      ],
      required: `{"@static.checkout.${productName}.paymentMethod": {"$in": ["CostcoVisa"]}}`,
    },
  });
}

export const AutoCheckoutFieldsMeta = {
  ...CommonFieldsMeta,
  ...BankFieldsMeta,
  ...getCreditCardFieldsMeta('auto'),
  ...getCostcoCardFieldsMeta('auto'),
};

export const HomeCheckoutFieldsMeta = {
  ...CommonFieldsMeta,
  ...BankFieldsMeta,
  ...getCreditCardFieldsMeta('home'),
  ...getCostcoCardFieldsMeta('home'),
  ...MortgageFieldsMeta,
};

export const RentersCheckoutFieldsMeta = {
  ...CommonFieldsMeta,
  ...BankFieldsMeta,
  ...getCreditCardFieldsMeta('renters'),
  ...getCostcoCardFieldsMeta('renters'),
};

export const CheckoutFieldsMetaByType = {
  auto: AutoCheckoutFieldsMeta,
  home: HomeCheckoutFieldsMeta,
  renters: RentersCheckoutFieldsMeta,
};

const autoCreditCardFieldsMeta = getCreditCardFieldsMeta('auto');
const autoCostcoCardFieldsMeta = getCostcoCardFieldsMeta('auto');
const homeCreditCardFieldsMeta = getCreditCardFieldsMeta('home');
const homeCostcoCardFieldsMeta = getCostcoCardFieldsMeta('home');
const rentersCreditCardFieldsMeta = getCreditCardFieldsMeta('renters');

export const PaymentFieldsInitial: Questions = {
  // static fields for auto
  'static.checkout.auto.paymentMethod': {
    ...AutoCheckoutFieldsMeta.paymentMethod,
    required: 'true',
  },
  'static.checkout.auto.paymentAcknowledgement': {
    ...AutoCheckoutFieldsMeta.paymentAcknowledgement,
    required: 'true',
  },
  'static.checkout.auto.acknowledgementName': {
    ...AutoCheckoutFieldsMeta.acknowledgementName,
    required: 'true',
  },
  'static.checkout.auto.pniAcknowledgementName': {
    ...AutoCheckoutFieldsMeta.pniAcknowledgementName,
    required: 'true',
  },
  // Start of Checkbox Acknowledgements
  'static.checkout.checkbox.auto.VehiclesTitled': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.VehiclesAtResidence': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.NoVehicleBusiness': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.ValidLicense': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.NoOtherOperators': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.NoClassicOrAntique': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.NoCustomizations': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.NoSharingProgram': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.NoPerformance': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.auto.NoExistingDamage': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.hasDangerousDogs': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.hasMoreThanTwoFullTimeResidentEmployees': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.hasNoDangerousDogBreed': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isBusinessNotConductedOnPremises': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isBusinessConductedOnPremises': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isOilTankOnPremises': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isSwimmingPoolOnPremises': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.hasWoodStove': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isMobileOrManufactured': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.hasDogBiteHistory': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.hasAnimalLiabilityDefaultCoverage': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.hasExoticAnimals': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isAnimalLiabilityCoverageRequired': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isPrimaryOccupancy': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.home.isNotRented': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.hasNoDangerousDogBreed': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.hasDangerousDogs': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.hasMoreThanTwoFullTimeResidentEmployees': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.hasDogBiteHistory': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.hasExoticAnimals': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.isNotRetired': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.Sewer Back-up': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.Septic Back-up': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.Sump Overflow': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.Coverage A': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.Coverage B': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  'static.checkout.checkbox.renters.Coverage C': {
    ...AutoCheckoutFieldsMeta.checkboxAcknowledgement,
    required: 'true',
  },
  // End of Checkbox Acknowledgements
  'static.checkout.auto.sniAcknowledgementName': {
    ...AutoCheckoutFieldsMeta.sniAcknowledgementName,
    required: 'false',
  },

  // Auto Bank pay static questions
  'static.checkout.auto.routingNumber': {
    ...AutoCheckoutFieldsMeta.routingNumber,
    required: `{"@static.checkout.auto.paymentMethod": {"$eq": "EFT"}}`,
  },
  'static.checkout.auto.accountNumber': {
    ...AutoCheckoutFieldsMeta.accountNumber,
    required: `{"@static.checkout.auto.paymentMethod": {"$eq": "EFT"}}`,
  },
  'static.checkout.auto.accountUse': {
    ...AutoCheckoutFieldsMeta.accountUse,
    required: metadata.requireAccountTrackingAuto,
  },
  'static.checkout.auto.accountType': {
    ...AutoCheckoutFieldsMeta.accountType,
    required: metadata.requireAccountTrackingAuto,
  },

  // Reccuring Payment static questions
  'static.checkout.auto.collectReccuringPay': {
    answerType: 'Boolean',
    businessType: ['auto'],
  },
  'static.checkout.auto.recurringPayment.routingNumber': {
    ...AutoCheckoutFieldsMeta.routingNumber,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.auto.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.auto.recurringPayment.accountNumber': {
    ...AutoCheckoutFieldsMeta.accountNumber,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.auto.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.auto.recurringPayment.accountUse': {
    ...AutoCheckoutFieldsMeta.accountUse,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.auto.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.auto.recurringPayment.accountType': {
    ...AutoCheckoutFieldsMeta.accountType,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.auto.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.auto.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.auto.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.auto.useInitialPayPaymentInd': {
    answerType: 'Boolean',
    businessType: ['auto'],
    criteria: [],
    defaultValue: 'false',
    required: `{"$and": [{"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}}, {"@static.checkout.auto.paymentMethod": {"$eq": "EFT"}}]}`,
  },
  'static.checkout.auto.recurringPaymentAmount': {
    answerType: 'String',
    businessType: ['auto'],
    description: 'recurringPaymentAmount',
    required: `{"@delta.coverageOption.amfam-adv.auto.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}}`,
    options: [
      {
        value: 'MINIMUM_DUE',
        label: 'Minimum Due',
      },
      {
        value: 'REMAINING_ACCOUNT_BALANCE',
        label: 'Account Balance',
      },
    ],
  },
  'static.checkout.auto.paymentPlan': {
    answerType: 'String',
    businessType: ['auto'],
    criteria: [],
    defaultValue: 'false',
    required: 'false',
  },
  'static.checkout.auto.customPaymentAmount': {
    answerType: 'Number',
    businessType: ['auto'],
    criteria: [
      {
        $gte: '@static.checkout.auto.minCustomPaymentAmount',
        error: 'Payment Amount must be between initial payment and the full pay amount.',
      },
      {
        $lte: '@static.checkout.auto.maxCustomPaymentAmount',
        error: 'Payment Amount must be between initial payment and the full pay amount.',
      },
    ],
    required: `{"@static.checkout.auto.paymentPlan": {"$eq": "Custom"}}`,
  },
  'static.checkout.auto.minCustomPaymentAmount': {
    answerType: 'Number',
    businessType: ['auto'],
    criteria: [],
  },
  'static.checkout.auto.maxCustomPaymentAmount': {
    answerType: 'Number',
    businessType: ['auto'],
    criteria: [],
  },
  'static.checkout.auto.creditCardFullName': autoCreditCardFieldsMeta.creditCardFullName,
  'static.checkout.auto.creditCardExpirationDate':
    autoCreditCardFieldsMeta.creditCardExpirationDate,
  'static.checkout.auto.creditCardCardNumber': autoCreditCardFieldsMeta.creditCardCardNumber,
  'static.checkout.auto.costcoCardFullName': autoCostcoCardFieldsMeta.costcoCardFullName,
  'static.checkout.auto.costcoCardExpirationDate':
    autoCostcoCardFieldsMeta.costcoCardExpirationDate,
  'static.checkout.auto.costcoCardNumber': autoCostcoCardFieldsMeta.costcoCardNumber,
  // static fields for home
  'static.checkout.home.paymentMethod': {
    ...HomeCheckoutFieldsMeta.paymentMethod,
    required: 'true',
  },
  'static.checkout.home.autoWithdrawAgree': {
    answerType: 'Boolean',
    businessType: ['home'],
    questionType: 'required',
    // TODO Can't declare criteria here as we don't have paymentPlan/paymentTerm question, so use useAddConditionValues for now
    required: 'true',
  },
  'static.checkout.home.customerAcknowledgement': {
    answerType: 'Boolean',
    businessType: ['home'],
    questionType: 'required',
    // TODO Can't declare criteria here as we don't have paymentPlan/paymentTerm question, so use useAddConditionValues for now
    required: 'true',
  },
  'static.checkout.home.paymentAcknowledgement': {
    ...HomeCheckoutFieldsMeta.paymentAcknowledgement,
    required: 'true',
  },
  'static.checkout.home.acknowledgementName': {
    ...HomeCheckoutFieldsMeta.acknowledgementName,
    required: 'true',
  },
  'static.checkout.home.pniAcknowledgementName': {
    ...HomeCheckoutFieldsMeta.pniAcknowledgementName,
    required: 'true',
  },
  'static.checkout.home.sniAcknowledgementName': {
    ...HomeCheckoutFieldsMeta.sniAcknowledgementName,
    required: 'false',
  },
  // Home Bank pay static
  'static.checkout.home.routingNumber': {
    ...HomeCheckoutFieldsMeta.routingNumber,
    required: `{"@static.checkout.home.paymentMethod": {"$eq": "EFT"}}`,
  },
  'static.checkout.home.accountNumber': {
    ...HomeCheckoutFieldsMeta.accountNumber,
    required: `{"@static.checkout.home.paymentMethod": {"$eq": "EFT"}}`,
  },
  'static.checkout.home.accountUse': {
    ...HomeCheckoutFieldsMeta.accountUse,
    required: metadata.requireAccountTrackingHome,
  },
  'static.checkout.home.accountType': {
    ...HomeCheckoutFieldsMeta.accountType,
    required: metadata.requireAccountTrackingHome,
  },

  // Reccuring Payment static questions
  'static.checkout.home.collectReccuringPay': {
    answerType: 'Boolean',
    businessType: ['home'],
  },
  'static.checkout.home.recurringPayment.routingNumber': {
    ...HomeCheckoutFieldsMeta.routingNumber,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.home.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.home.recurringPayment.accountNumber': {
    ...HomeCheckoutFieldsMeta.accountNumber,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.home.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },

  'static.checkout.home.recurringPayment.accountUse': {
    ...HomeCheckoutFieldsMeta.accountUse,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.home.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },

  'static.checkout.home.recurringPayment.accountType': {
    ...HomeCheckoutFieldsMeta.accountType,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.home.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.home.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.home.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },

  'static.checkout.home.useInitialPayPaymentInd': {
    answerType: 'Boolean',
    businessType: ['home'],
    criteria: [],
    defaultValue: 'false',
    required: `{"$and": [{"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}}, {"@static.checkout.home.paymentMethod": {"$eq": "EFT"}}]}`,
  },
  'static.checkout.home.recurringPaymentAmount': {
    answerType: 'String',
    businessType: ['home'],
    description: 'recurringPaymentAmount',
    required: `{"@delta.coverageOption.amfam-adv.home.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}}`,
    options: [
      {
        value: 'MINIMUM_DUE',
        label: 'Minimum Due',
      },
      {
        value: 'REMAINING_ACCOUNT_BALANCE',
        label: 'Account Balance',
      },
    ],
  },
  'static.checkout.home.paymentPlan': {
    answerType: 'String',
    businessType: ['home'],
    criteria: [],
    defaultValue: 'false',
    required: 'false',
  },
  'static.checkout.home.customPaymentAmount': {
    answerType: 'Number',
    businessType: ['home'],
    criteria: [
      {
        $gte: '@static.checkout.home.minCustomPaymentAmount',
        error: 'Payment Amount must be between initial payment and the full pay amount.',
      },
      {
        $lte: '@static.checkout.home.maxCustomPaymentAmount',
        error: 'Payment Amount must be between initial payment and the full pay amount.',
      },
    ],
    required: `{"@static.checkout.home.paymentPlan": {"$eq": "Custom"}}`,
  },
  'static.checkout.home.minCustomPaymentAmount': {
    answerType: 'Number',
    businessType: ['home'],
    criteria: [],
  },
  'static.checkout.home.maxCustomPaymentAmount': {
    answerType: 'Number',
    businessType: ['home'],
    criteria: [],
  },
  'static.checkout.home.creditCardFullName': homeCreditCardFieldsMeta.creditCardFullName,
  'static.checkout.home.creditCardExpirationDate':
    homeCreditCardFieldsMeta.creditCardExpirationDate,
  'static.checkout.home.creditCardCardNumber': homeCreditCardFieldsMeta.creditCardCardNumber,
  'static.checkout.home.costcoCardFullName': homeCostcoCardFieldsMeta.costcoCardFullName,
  'static.checkout.home.costcoCardExpirationDate':
    homeCostcoCardFieldsMeta.costcoCardExpirationDate,
  'static.checkout.home.costcoCardNumber': homeCostcoCardFieldsMeta.costcoCardNumber,
  'static.checkout.home.mortgageKnowMortgageCompany': {
    ...HomeCheckoutFieldsMeta.mortgageKnowMortgageCompany,
    required: `{"@static.checkout.home.paymentMethod": {"$eq": "Mortgage"}}`,
  },
  'static.checkout.home.mortgageCompany': {
    ...HomeCheckoutFieldsMeta.mortgageCompany,
    required: `{"$and": [{"@static.checkout.home.paymentMethod": {"$eq": "Mortgage"}}, {"@static.checkout.home.mortgageKnowMortgageCompany": {"$eq": true}}]}`,
  },
  'static.checkout.home.mortgageLoanNumber': {
    ...HomeCheckoutFieldsMeta.mortgageLoanNumber,
    required: 'false',
  },
  'static.checkout.home.mortgageCity': {
    ...HomeCheckoutFieldsMeta.mortgageCity,
    required: 'false',
  },
  'static.checkout.home.mortgageZip': {
    ...HomeCheckoutFieldsMeta.mortgageZip,
    required: 'false',
  },
  'static.checkout.home.mortgageAddressLine2': {
    ...HomeCheckoutFieldsMeta.mortgageAddressLine2,
    required: 'false',
  },
  'static.checkout.home.mortgageAddressLine1': {
    ...HomeCheckoutFieldsMeta.mortgageAddressLine1,
    required: 'false',
  },
  'static.checkout.home.mortgageState': {
    ...HomeCheckoutFieldsMeta.mortgageState,
    required: 'false',
  },
  // static fields for renters
  'static.checkout.renters.paymentMethod': {
    ...RentersCheckoutFieldsMeta.paymentMethod,
    required: 'true',
  },
  // TODO This would require tweak for condo and tenants
  'static.checkout.renters.autoWithdrawAgree': {
    answerType: 'Boolean',
    businessType: ['renters'],
    questionType: 'required',
    // TODO Can't declare criteria here as we don't have paymentPlan/paymentTerm question, so use useAddConditionValues for now
    required: 'true',
  },
  // TODO This would require tweak for condo and tenants
  'static.checkout.renters.customerAcknowledgement': {
    answerType: 'Boolean',
    businessType: ['renters'],
    questionType: 'required',
    // TODO Can't declare criteria here as we don't have paymentPlan/paymentTerm question, so use useAddConditionValues for now
    required: 'true',
  },
  'static.checkout.renters.paymentAcknowledgement': {
    ...RentersCheckoutFieldsMeta.paymentAcknowledgement,
    required: 'true',
  },
  'static.checkout.renters.acknowledgementName': {
    ...RentersCheckoutFieldsMeta.acknowledgementName,
    required: 'true',
  },
  'static.checkout.renters.pniAcknowledgementName': {
    ...RentersCheckoutFieldsMeta.pniAcknowledgementName,
    required: 'true',
  },
  'static.checkout.renters.sniAcknowledgementName': {
    ...RentersCheckoutFieldsMeta.sniAcknowledgementName,
    required: 'false',
  },
  // Renters Bank pay static
  'static.checkout.renters.routingNumber': {
    ...RentersCheckoutFieldsMeta.routingNumber,
    required: `{"@static.checkout.renters.paymentMethod": {"$eq": "EFT"}}`,
  },
  'static.checkout.renters.accountNumber': {
    ...RentersCheckoutFieldsMeta.accountNumber,
    required: `{"@static.checkout.renters.paymentMethod": {"$eq": "EFT"}}`,
  },
  'static.checkout.renters.accountUse': {
    ...RentersCheckoutFieldsMeta.accountUse,
    required: metadata.requireAccountTrackingRenters,
  },
  'static.checkout.renters.accountType': {
    ...RentersCheckoutFieldsMeta.accountType,
    required: metadata.requireAccountTrackingRenters,
  },

  // Reccuring Payment static questions
  'static.checkout.renters.collectReccuringPay': {
    answerType: 'Boolean',
    businessType: ['renters'],
  },
  'static.checkout.renters.recurringPayment.routingNumber': {
    ...RentersCheckoutFieldsMeta.routingNumber,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.renters.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.renters.recurringPayment.accountNumber': {
    ...RentersCheckoutFieldsMeta.accountNumber,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.renters.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.renters.recurringPayment.accountUse': {
    ...RentersCheckoutFieldsMeta.accountUse,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.renters.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.renters.recurringPayment.accountType': {
    ...RentersCheckoutFieldsMeta.accountType,
    required: `{
      "$or": [
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "EFT"}},
            {"@static.checkout.renters.useInitialPayPaymentInd": {"$eq": false}}
          ]
        },
        {
          "$and": [
            {"@discount.renters.autoPay": {"$eq": true}},
            {"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}},
            {"@static.checkout.renters.paymentMethod": {"$eq": "CreditCard"}}
          ]
        }
      ]
    }`,
  },
  'static.checkout.renters.creditCardFullName': rentersCreditCardFieldsMeta.creditCardFullName,
  'static.checkout.renters.creditCardExpirationDate':
    rentersCreditCardFieldsMeta.creditCardExpirationDate,
  'static.checkout.renters.creditCardCardNumber': rentersCreditCardFieldsMeta.creditCardCardNumber,
  'static.checkout.renters.useInitialPayPaymentInd': {
    answerType: 'Boolean',
    businessType: ['renters'],
    criteria: [],
    defaultValue: 'false',
    required: `{"$and": [{"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}}, {"@static.checkout.renters.paymentMethod": {"$eq": "EFT"}}]}`,
  },
  'static.checkout.renters.recurringPaymentAmount': {
    answerType: 'String',
    businessType: ['renters'],
    description: 'recurringPaymentAmount',
    required: `{"@delta.coverageOption.amfam-adv.renters.autoPayType": {"$eq": "MY_ACCOUNT_BILLING"}}`,
    options: [
      {
        value: 'MINIMUM_DUE',
        label: 'Minimum Due',
      },
      {
        value: 'REMAINING_ACCOUNT_BALANCE',
        label: 'Account Balance',
      },
    ],
  },
  'static.checkout.renters.paymentPlan': {
    answerType: 'String',
    businessType: ['renters'],
    criteria: [],
    defaultValue: 'false',
    required: 'false',
  },
  'static.checkout.renters.customPaymentAmount': {
    answerType: 'Number',
    businessType: ['renters'],
    criteria: [
      {
        $gte: '@static.checkout.renters.minCustomPaymentAmount',
        error: 'Payment Amount must be between initial payment and the full pay amount.',
      },
      {
        $lte: '@static.checkout.renters.maxCustomPaymentAmount',
        error: 'Payment Amount must be between initial payment and the full pay amount.',
      },
    ],
    required: `{"@static.checkout.renters.paymentPlan": {"$eq": "Custom"}}`,
  },
  'static.checkout.renters.minCustomPaymentAmount': {
    answerType: 'Number',
    businessType: ['renters'],
    criteria: [],
  },
  'static.checkout.renters.maxCustomPaymentAmount': {
    answerType: 'Number',
    businessType: ['renters'],
    criteria: [],
  },
};

export const PhoneNumberMeta: Question = {
  answerType: 'String',
  required: `{"$and": [{"@static.checkout.home.paymentMethod": {"$eq": "Mortgage"}}, {"@static.checkout.home.mortgageKnowMortgageCompany": {"$eq": false}}]}`,
};
