import { useCallback, useEffect, useRef, useState } from 'react';

import { FormLabel, Grid } from '@mui/material';

import { setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';

import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { getPersonRefForDriver, useForm } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { goToErrorByQuestionKey } from '@ecp/features/sales/shared/utils/web';
import type { LineOfBusiness } from '@ecp/features/shared/product';

import { DRIVER_INTL, DRIVER_UNLICENSED } from '../../../constants';
import { DriverProfileFormQuestions } from '../../../formBody';
import {
  getDriverProfileFormConditions,
  useSetupDriverProfileForm,
  useValidateDriverLicenseNumberForOneDriver,
} from '../../../state';
import { useStyles } from './DriverProfileForm.styles';

interface Props {
  isPni: boolean;
  driverRef: string;
  onNext?: () => Promise<void>;
  isEdit: boolean;
  lineOfBusiness: LineOfBusiness;
}

export const DriverProfileForm: React.FC<Props> = (props) => {
  const { driverRef, isPni, onNext, isEdit } = props;
  const { classes } = useStyles();
  const personRef = useSelector(getPersonRefForDriver(driverRef)) as string;

  const {
    fields,
    fields: {
      driver: {
        firstName,
        driversLicense: { status: dlStatus, number: dlNumber, state: dlState },
      },
    },
  } = useSetupDriverProfileForm(driverRef);

  const {
    props: { value: name = '' },
  } = firstName;

  const possessiveName = name ? `${name}'s` : `the driver's`;
  const wasNameOrWereYou = name ? `was ${name}` : 'were you';
  const willNameOrYou = `will ${name ? name : 'you'}`;
  const ifNameOrYou = name ? `If ${name}` : 'If you';

  const validateDriverLicenseNumber = useValidateDriverLicenseNumberForOneDriver(dlNumber, dlState);
  const initValues = useRef({});

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    fields,
    initValues,
    formKey: driverRef,
    conditions: getDriverProfileFormConditions(fields),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isEdit) validateForm();
    setDimension(TrackingDimensions.DRIVER_REF, driverRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverRef]);

  // !TODO needs to be revisited DL status on change to unlicensed -> set to null DL related fields
  const handleSave = useCallback(async () => {
    if (validateForm().isValid) {
      // For unlicensed skip DL validation
      if (dlStatus.value === DRIVER_UNLICENSED) {
        setIsSubmitting(true);
        await patchFormValues();
        await onNext?.();
        setIsSubmitting(false);
      }
      setIsSubmitting(true);
      if (
        (dlStatus.value !== DRIVER_INTL && !(await validateDriverLicenseNumber())) ||
        dlNumber.props.error === 'Duplicated driver’s license. Please re-enter.'
      ) {
        goToErrorByQuestionKey(dlNumber.key);
        setIsSubmitting(false);

        return;
      }
      await patchFormValues();
      await onNext?.();
      setIsSubmitting(false);
    }
  }, [
    validateForm,
    dlStatus.value,
    patchFormValues,
    onNext,
    dlNumber.key,
    dlNumber.props,
    validateDriverLicenseNumber,
  ]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return (
    <div className={classes.root}>
      <Form>
        <FormLabel>About {firstName.value || 'the driver'}</FormLabel>
        <DriverProfileFormQuestions
          driverRef={driverRef}
          personRef={personRef}
          isPni={isPni}
          possessiveName={possessiveName}
          wasNameOrWereYou={wasNameOrWereYou}
          willNameOrYou={willNameOrYou}
          ifNameOrYou={ifNameOrYou}
        />
        <Grid item xs={12}>
          <NextPageInstructions divider>
            This finishes {possessiveName} profile. Let&apos;s return to your profile!
          </NextPageInstructions>
        </Grid>
        <Grid className={classes.buttonsPanel} container item xs={12}>
          <div className={classes.addButton}>
            <Button
              className={classes.next}
              variant='primary'
              onClick={handleSave}
              onMouseDown={handleMouseDown}
              isProcessing={isPatchFormInProgress || isSubmitting}
              analyticsElement='choice.driverDetailsPage.continueButton'
              data-testid='driverProfileFormContinue'
            >
              Save & continue
            </Button>
          </div>
        </Grid>
      </Form>
    </div>
  );
};
