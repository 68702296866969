import { useCallback, useState } from 'react';

import { Divider, Grid, Stack, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem, Snackbar } from '@ecp/components';
import { Button, Dialog } from '@ecp/features/sales/shared/components';
import { getPrimaryInsuredAddressInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUICheck, IconUIDelete, IconUIEdit } from '@ecp/themes/base';

import { tpiMockData } from '../mockData';
import { useStyles } from './HomeTPIPageForm.styles';

export const HomeTPIPageForm: React.FC = () => {
  const { classes, cx } = useStyles();
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);
  const [removeInterestedparty, setremoveInterestedparty] = useState(false);
  const [showResendMessage, setShowResendMessage] = useState(false);

  const interestedPartyDialogOpen = useEvent(() => {
    setremoveInterestedparty(true);
  });
  const handleInterestedPartyDialogClose = useCallback(() => {
    setremoveInterestedparty(false);
  }, []);

  const handleResendMessageClose = useCallback(() => setShowResendMessage(false), []);
  const handleDeleteHomeTpi = useCallback(() => {
    setShowResendMessage(true);
    setremoveInterestedparty(false);
  }, []);

  return (
    <>
      <Dialog
        actionButtonLabel='Remove Interested party'
        actionButtonVariant='danger'
        textButtonLabel='Cancel'
        titleText='Are you sure you want to remove this interested party?'
        open={removeInterestedparty}
        onClose={handleInterestedPartyDialogClose}
        actionButtonOnClick={handleDeleteHomeTpi}
      >
        <p>
          Removing an interested party will permanently delete its details. This action can't be
          undone.
        </p>
      </Dialog>
      {showResendMessage && (
        <Snackbar
          classes={{ root: classes.snackbar }}
          message={
            <Stack direction='row' spacing={1} alignItems='center'>
              <div className={classes.iconContainer}>
                <IconUICheck className={classes.icon} />
              </div>
              <span>Interested party removed</span>
            </Stack>
          }
          open={showResendMessage}
          autoHideDuration={3000}
          vertical='top'
          horizontal='center'
          onClose={handleResendMessageClose}
        />
      )}
      <Grid container className={classes.card}>
        <GridItem xs={12} md={6}>
          <>
            {addressInformation.line1 && (
              <>
                <p className={classes.contentTitle}>
                  {addressInformation.line1}
                  {addressInformation.line2 ? ` ${addressInformation.line2},` : ','}{' '}
                  {addressInformation.city} {addressInformation.state}{' '}
                  {` ${addressInformation.zipcode}`}
                </p>
                <p className={classes.contentSubTitle}>
                  Limit of 3 interested parties can be added
                </p>
              </>
            )}
          </>
        </GridItem>
        <GridItem xs={12} md={6} className={classes.columnRight}>
          {tpiMockData.length !== 3 ? (
            <Button
              className={classes.button}
              variant='outlinePrimary'
              trackingName='tpi_home_add_party'
              trackingLabel={GoogleAnalyticsLabels.CONTINUE}
            >
              {tpiMockData.length ? 'ADD ANOTHER INTERESTED PARTY' : 'ADD INTERESTED PARTY'}
            </Button>
          ) : null}
        </GridItem>
        {tpiMockData.map((item, index) => {
          return (
            <>
              <Divider className={classes.divider} />
              <Grid container key={index}>
                <Grid xs={12} md={6} className={classes.columnLeft}>
                  <GridItem>
                    <Button
                      variant='iconTextMedium'
                      className={cx(
                        classes.status,
                        item.pending === 'Yes' && classes.status && classes.pendingStatus,
                      )}
                    >
                      {item.order}
                    </Button>
                  </GridItem>
                  <GridItem>
                    <p className={classes.title}>{item.tpiTitle}</p>
                    <p>Loan no:{item.loanNumber}</p>
                    <Typography variant='body1'>Bill to escrow: {item.billToEscrow}</Typography>
                  </GridItem>
                </Grid>
                <Grid item xs={12} md={6} className={classes.columnRight}>
                  <GridItem>
                    <Button
                      variant='iconTextMedium'
                      icon={<IconUIEdit />}
                      className={classes.actionButton}
                    >
                      Edit
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      variant='iconTextMedium'
                      icon={<IconUIDelete />}
                      className={classes.actionButton}
                      onClick={interestedPartyDialogOpen}
                    >
                      Remove
                    </Button>
                  </GridItem>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};
