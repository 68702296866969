import { useEffect } from 'react';

import { env } from '@ecp/env';
import {
  getLineOfBusiness,
  getPartnerExperienceId,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { updateUserAttributesAndDecisionAnalytics } from '@ecp/features/sales/shared/utils/analytics';

/**
 * Keeps track of various variables in the app which it associates with the current user session.
 */
export const useFlags = (): void => {
  const partnerExpId =
    useSelector(getPartnerExperienceId) || (!env.static.isAgent && env.static.expId);
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  useEffect(() => {
    if (lineOfBusiness || partnerExpId || stateCode) {
      updateUserAttributesAndDecisionAnalytics({
        product: lineOfBusiness || null,
        expId: partnerExpId,
        stateCode,
      });
    }
  }, [lineOfBusiness, partnerExpId, stateCode]);
};
