import type { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { castToBoolean } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, RadioGroupWithOptions } from '@ecp/components';
import { useGetConditionValues, useGetFields, useInitValues } from '@ecp/features/sales/form';
import { useAddDriver } from '@ecp/features/sales/quotes/auto';
import { Button, Form, Link } from '@ecp/features/sales/shared/components';
import {
  createRef,
  deleteAnswers,
  deleteInquiryRef,
  getAnswers,
  getLineOfBusinessOrder,
  getSapiTarget,
  isAnyApiInProgress,
  navigateToDriver,
  useField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { useLoading } from '@ecp/features/sales/shared/utils/web';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconCardAuto, IconCardHome, IconCardRent } from '@ecp/themes/base';
import type { Field } from '@ecp/types';

import { useStyles } from './SecondaryNamedInsuredForm.styles';
import { SniFormQuestions } from './SniFormQuestions';

export interface SecondaryNamedInsuredFormProps {
  onNext: () => Promise<void>;
}
interface ContentProps {
  selectedProduct: string;
  idx: number;
  setPropertySni: Dispatch<SetStateAction<boolean>>;
}

export const SecondaryNamedInsuredForm: React.FC<SecondaryNamedInsuredFormProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const hasSecondaryNamedInsured: Field = useField('static.hasSecondaryNamedInsured');
  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();
  const dispatch = useDispatch();
  const propertySNI = useField('person.addSecondaryInsured');
  const [isPropertySni, setIsPropertySni] = useState(!!propertySNI.props.value);
  const sapiTarget = useSelector(getSapiTarget);
  const isSAPIV4 = sapiTarget === 'v4';
  const allAnswers = useSelector(getAnswers);
  const sniDriverQuestions = flagValues[FeatureFlags.SNI_DRIVER_QUESTIONS];

  useInitValues({ [hasSecondaryNamedInsured.key]: false });
  const { isPatchFormInProgress, patchFormValues, validateForm } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });

  // For v4, if propertySNI use auto SNI person, no need to patch form values, so no new person is created in SAPI
  const shouldPatchFormValues = useCallback(() => {
    const autoSNI = allAnswers['secondaryInsured.person.ref'];
    const homeSNI = allAnswers['secondaryInsuredHome.person.ref'];
    const rentersSNI = allAnswers['secondaryInsuredRenters.person.ref'];

    return (homeSNI && autoSNI !== homeSNI) || (rentersSNI && autoSNI !== rentersSNI);
  }, [allAnswers]);

  const handleSubmit = useCallback(async () => {
    try {
      if (isPropertySni) {
        propertySNI.props.actionOnComplete(true);
      }
      if (!isPropertySni && propertySNI.props.value) {
        propertySNI.props.actionOnComplete(false);
      }
    } finally {
      if (validateForm().isValid) {
        if (!isSAPIV4 || (isSAPIV4 && shouldPatchFormValues())) await patchFormValues();
        await onNext();
      }
    }
  }, [
    onNext,
    validateForm,
    patchFormValues,
    isPropertySni,
    propertySNI.props,
    isSAPIV4,
    shouldPatchFormValues,
  ]);

  const handleSkip = useCallback(async () => {
    // this prevents autoSNI from being removed when "skip this step" is clicked and the Auto SNI questions are rendered on the additional drivers page.
    const autoKeys = sniDriverQuestions ? '' : 'secondaryInsured.person.ref';

    const keysToDelete = [
      autoKeys,
      'secondaryInsuredHome.person.ref',
      'secondaryInsuredRenters.person.ref',
      'static.personPropertySNI.dob',
    ];
    const autoSNI = allAnswers[autoKeys];
    const homeSNI = allAnswers['secondaryInsuredHome.person.ref'];
    const rentersSNI = allAnswers['secondaryInsuredRenters.person.ref'];
    try {
      if (propertySNI.props.value) {
        propertySNI.props.actionOnComplete(false);
      }

      // This only delete secondary insured person for home, renters products, not auto, as the additional driver might still exists
      if (isSAPIV4) {
        if (homeSNI && autoSNI !== homeSNI) {
          await dispatch(deleteInquiryRef(homeSNI));
        }
        if (rentersSNI && autoSNI !== rentersSNI) {
          await dispatch(deleteInquiryRef(rentersSNI));
        }
      } else {
        await dispatch(deleteAnswers({ keys: keysToDelete }));
        await dispatch(deleteAnswers({ ref: 'personPropertySNI' }));
      }
    } finally {
      await validateForm();
      await onNext();
    }
  }, [onNext, dispatch, validateForm, propertySNI.props, isSAPIV4, allAnswers, sniDriverQuestions]);

  const handleContinue = useCallback(async () => {
    if (hasSecondaryNamedInsured.value) {
      handleSubmit();
    } else {
      handleSkip();
    }
  }, [handleSkip, handleSubmit, hasSecondaryNamedInsured.value]);

  const selectedProducts = useSelector(getLineOfBusinessOrder);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...hasSecondaryNamedInsured.props}
              label='Add SNI to your policy? (optional)'
              variant='yesNoButton'
              trackingName='PriorInsurance'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          {castToBoolean(hasSecondaryNamedInsured.value) && (
            <GridItem xs={12}>
              {selectedProducts
                .filter((selectedProduct) => {
                  const hideAuto = sniDriverQuestions && selectedProducts.length > 1;

                  return selectedProduct !== LineOfBusiness.AUTO || !hideAuto;
                })
                .map((selectedProduct, idx) => (
                  <Grid key={selectedProduct}>
                    <Content
                      selectedProduct={selectedProduct}
                      idx={idx}
                      setPropertySni={setIsPropertySni}
                    />
                  </Grid>
                ))}
            </GridItem>
          )}
        </Grid>
        <Divider className={classes.divider} />
        <GridItem topSpacing='lg' xs={12}>
          <Button
            className={classes.next}
            variant='primary'
            onClick={handleContinue}
            trackingName='sni_details_continue'
            trackingLabel={GoogleAnalyticsLabels.CONTINUE}
            analyticsElement='sniDetailsPage.continueButton'
          >
            Continue
          </Button>
        </GridItem>
      </Form>
    </div>
  );
};

const Content: React.FC<ContentProps> = (props) => {
  const { selectedProduct, idx, setPropertySni } = props;
  const { classes } = useStyles();
  const shouldWait = useSelector(isAnyApiInProgress);
  const { withLoading } = useLoading(shouldWait);
  const addDriver = useAddDriver();
  const selectedProducts = useSelector(getLineOfBusinessOrder);
  const dispatch = useDispatch();
  const [newPersonRef, setNewPersonRef] = useState<string>();

  const sapiTarget = useSelector(getSapiTarget);
  const isSAPIV4 = sapiTarget === 'v4';
  const personPrefix = isSAPIV4 ? 'person' : 'personPropertySNI';

  useEffect(() => {
    if (selectedProduct === LineOfBusiness.HOME || selectedProduct === LineOfBusiness.RENTERS)
      setNewPersonRef(dispatch(createRef(personPrefix)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToDriverPage: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLSpanElement> = withLoading(async () => {
    const newDriver = addDriver();
    const { driverRef } = newDriver;
    await newDriver.done;

    trackClick({ action: 'add_driver_link', label: driverRef });

    await dispatch(
      navigateToDriver({
        ref: driverRef,
        replace: false,
        referencePage: 'profileAddSni',
      }),
    );
  });

  const label = (icon: React.ReactElement, label: string): React.ReactElement => {
    return (
      <GridItem className={classes.questionLabel} topSpacing='lg' xs={12}>
        <Box>
          <div className={classes.labelIcon}>{icon}</div>
        </Box>
        <Typography variant='body4'>
          Who would you like to add to your {label} policy?
          <span className={classes.optional}>(optional)</span>
        </Typography>
      </GridItem>
    );
  };

  if (selectedProduct === LineOfBusiness.AUTO)
    return (
      <SniFormQuestions
        variant='select'
        title={
          <>
            {label(<IconCardAuto />, 'auto')}
            <GridItem xs={12} topSpacing={10}>
              <span className={classes.questionSubLabel}>
                The SNI must be listed as a driver on the policy.
              </span>
              <Link
                component='button'
                onClick={navigateToDriverPage}
                trackingName='add_new_driver_link'
                trackingLabel='add_new_driver'
              >
                Add a new driver
              </Link>
            </GridItem>
          </>
        }
        fieldPrefix='secondaryInsured'
      />
    );
  else {
    // home and renter
    return (
      <div>
        {newPersonRef && (
          <SniFormQuestions
            variant='textFields'
            title={
              selectedProduct === LineOfBusiness.HOME
                ? label(<IconCardHome />, 'home')
                : label(<IconCardRent />, 'renter')
            }
            textFieldUseAutoSni={selectedProducts.includes(LineOfBusiness.AUTO) && idx !== 0} // only show after auto
            fieldPrefix={
              selectedProduct === LineOfBusiness.HOME
                ? 'secondaryInsuredHome'
                : 'secondaryInsuredRenters'
            }
            trackingPrefix={selectedProduct === LineOfBusiness.HOME ? 'home' : 'renter'}
            newPersonRef={newPersonRef}
            isPropertySni={setPropertySni}
          />
        )}
      </div>
    );
  }
};
