import { memo, useCallback, useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import { trackErrorBoundary } from '@ecp/utils/analytics/tracking';
import { datadogLog } from '@ecp/utils/logger';
import { stringifySearchParams, useLocation } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import { Button, DevDiagnostic, Page } from '@ecp/features/sales/shared/components';
import { ErrorReason } from '@ecp/features/sales/shared/constants';
import { useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getCustomerId,
  getDalSessionId,
  getGlobalError,
  getInquiryId,
  getOfferSetId,
  getPreviousPage,
  getPrimaryInsuredStateCode,
  updateGlobalError,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { IconUIArrowLeft } from '@ecp/themes/base';

import { QuotesError } from '../../components';
import { getErrorPageMetadata } from './metadata';
import { useStyles } from './SessionErrorStopPage.styles';

interface Props {
  text: string;
  unsetError(): void;
  clearErrorHash(): void;
}

const QUOTE_ERROR_REASONS = new Set([ErrorReason.DNQ, ErrorReason.QNB, ErrorReason.CNQ]);

export const SessionErrorStopPage: React.FC<Props> = memo((props) => {
  const { text, unsetError, clearErrorHash } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const previousPage = useSelector(getPreviousPage);
  const error = useSelector(getGlobalError);
  const dalSessionId = useSelector(getDalSessionId);
  const inquiryId = useSelector(getInquiryId);
  const location = useLocation();
  const offerId = useSelector(getOfferSetId);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const customerId = useSelector(getCustomerId);

  const {
    title,
    subtitle,
    reason,
    customReason,
    guidance,
    navigationText,
    nextPage,
    returnButtonTrackingName,
    errorImage,
    errorDetails,
  } = getErrorPageMetadata(error.errorReason, stateCode);

  // replace: true, so that the page which caused the error
  // is not there is history, also don't save previousPage
  const navigateToPreviousPage = useNavigateToPage(nextPage || previousPage || '/quote/landing', {
    replace: true,
    removeQuery: false,
    replaceProfileAdd: false,
  });

  const handleSubmit = useCallback(() => {
    dispatch(updateGlobalError({ hasError: false, requestId: '', transactionId: '' }));
    unsetError();
    clearErrorHash();
    navigateToPreviousPage();
  }, [dispatch, navigateToPreviousPage, unsetError, clearErrorHash]);

  const errorCodeForUrl = `#error-${error.errorReason || ErrorReason.GLOBAL}`;
  // Get correct path for route
  const routePath = `${location.pathname}${stringifySearchParams(location.search)}`;
  const navigateToErrorUrl = useNavigateToPage(`${routePath}${errorCodeForUrl}`, {
    replace: true,
    removeQuery: true,
    replaceProfileAdd: false,
    addErrorHash: true,
  });
  const shouldShowDiagnosticsDetails =
    (env.static.isAgent || env.static.nodeEnv !== 'production') && reason;
  const pageTitle = `${title} ${subtitle || ''}`;
  const renderPageTitle = <h1 className={classes.pageTitle}>{pageTitle}</h1>;
  const renderReason = customReason || reason;
  const hasQuotesError = QUOTE_ERROR_REASONS.has(error.errorReason);

  useEffect(() => {
    navigateToErrorUrl();

    if (!hasQuotesError) {
      const analyticsEventDetail = {
        OopsPageCategory: error.errorReason || ErrorReason.GLOBAL,
        OopsPageCode: error.name,
        RequestUrl: error.requestUrl,
        ErrorText: error.text || error.errorReasonMessage || text,
        UserShownText: reason,
        UserNavigationText: navigationText,
        NextPage: nextPage || previousPage || '/quote/landing',
        XTraceID: error.requestId,
        DalSessionId: dalSessionId,
        InquiryId: inquiryId,
        OfferId: offerId,
        OopsPagePath: location.pathname,
        // TODO Replace with CustomerId after checking with John Sarantos
        ChoiceID: customerId,
      };
      trackErrorBoundary({ label: reason });
      trackSapiAnalyticsEvent({
        element: 'choice.oopsPage.page',
        event: 'render',
        eventDetail: JSON.stringify(analyticsEventDetail),
      });
      datadogLog({
        logType: 'error',
        message: `${error.name} - ${
          error.text || text || error.message || error.errorReasonMessage || error.errorReason
        }, ${reason}`,
        context: {
          logOrigin: 'Oops Page',
          functionOrigin: 'SessionErrorStopPage',
          contextType: 'Oops Page',
          oopsPageCategory: error.errorReason || ErrorReason.GLOBAL,
          errorReason: error.errorReason,
          errorMessage: error.errorReasonMessage || error.message,
          errorText: error.text || text,
          errorType: error.name,
          oopsPagePath: location.pathname,
          navigationText,
          requestUrl: error.requestUrl,
          requestId: error.requestId,
          transactionId: error.transactionId,
          nextPage: nextPage || previousPage || '/quote/landing',
          offerId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      {hasQuotesError ? (
        <div className={classes.quotesError}>
          <QuotesError
            dalSessionId={dalSessionId}
            inquiryId={inquiryId}
            xRequestId={error.requestId}
            transactionId={error.transactionId}
          />
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={11}>
                {renderPageTitle}
                <Divider aria-hidden='true' className={classes.divider} />
                {reason && <p className={classes.reason}>{renderReason}</p>}
                <p className={reason ? classes.guidance : classes.guidanceSubtitle1}>{guidance}</p>

                {navigationText && (
                  <Button
                    onClick={handleSubmit}
                    data-testid='navigationButton'
                    trackingName={returnButtonTrackingName}
                    trackingLabel={reason}
                    variant='iconTextMedium'
                    icon={<IconUIArrowLeft />}
                  >
                    {navigationText}
                  </Button>
                )}
              </Grid>
              {shouldShowDiagnosticsDetails && (
                <Grid item xs={12} className={classes.devDiagnostic}>
                  <DevDiagnostic errorReason={error.errorReason} details={text} />
                </Grid>
              )}
              {errorDetails && (
                <Grid container className={classes.errorContainer}>
                  <Grid item>{errorDetails}</Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={false} md={5} xl={4} className={classes.imageContainer}>
              <img src={errorImage} alt='Error' />
            </Grid>
          </Grid>
        </div>
      )}
    </Page>
  );
});
