import { difference } from '@ecp/utils/common';

import type { ProductName } from '@ecp/features/shared/product';
import { getProductNamesFromLineOfBusiness } from '@ecp/features/shared/product';

import { getSapiTarget } from '../../api';
import {
  getPrimaryInsuredAddressInfo,
  getPrimaryInsuredAddressLock,
  getPrimaryInsuredAddressRef,
  setFormErrorsChangedByField,
} from '../../form';
import { wrapThunkActionWithErrHandler } from '../../util';
import { postCheckPolicyType } from '../api';
import { getDalSessionId, getLineOfBusiness } from '../selectors';
import { getErrorMessageFromErrorReason } from '../util/getErrorMessageFromErrorReason';
import { getErrorReasonFromPolicyTypes } from '../util/getErrorReasonFromPolicyTypes';

export const checkPolicyType = wrapThunkActionWithErrHandler<void>(
  () => async (dispatch, getState) => {
    const state = getState();
    const sapiTarget = getSapiTarget(state);

    // This is a v4 only functionality and not to be called on v3 flow
    if (sapiTarget !== 'v4') return;

    const dalSessionId = getDalSessionId(state);
    const { state: addressState } = getPrimaryInsuredAddressInfo(state);
    const primaryInsuredAddressLock = getPrimaryInsuredAddressLock(state);
    const primaryInsuredAddressRef = getPrimaryInsuredAddressRef(state);
    const zipKey = `${primaryInsuredAddressRef}.zipcode`;
    const lineOfBusiness = getLineOfBusiness(state);

    if (!dalSessionId || !addressState || primaryInsuredAddressLock) {
      return;
    }
    const response = await postCheckPolicyType({
      dalSessionId,
      addressState,
    });

    if (response.payload) {
      const availablePolicyTypes = response.payload.data.policyTypes;
      let unavailableRequestedPolicyTypes: ProductName[] = [];

      if (lineOfBusiness) {
        const requestedPolicyType = getProductNamesFromLineOfBusiness(lineOfBusiness);
        unavailableRequestedPolicyTypes = difference(requestedPolicyType, availablePolicyTypes);

        if (!unavailableRequestedPolicyTypes.length) return;
      } else {
        if (availablePolicyTypes.length) return;
      }

      const errorReason = getErrorReasonFromPolicyTypes(
        availablePolicyTypes,
        unavailableRequestedPolicyTypes,
      );

      if (errorReason) {
        const errorMessage = getErrorMessageFromErrorReason(errorReason);

        // Set form error for zip code to show inline error
        dispatch(
          setFormErrorsChangedByField({
            key: zipKey,
            errors: errorMessage,
          }),
        );
      }
    }
  },
  'checkPolicyType',
);
