import baseMetadata from './metadata.js';
import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  // VariantA - Zillow
  isVariantACoverageStyle: true,
};

export default metadata;
