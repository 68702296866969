import type { Dictionary } from '@reduxjs/toolkit';

import { groupBy } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { ConfigType } from '@ecp/features/sales/shared/constants';
import { PagePath } from '@ecp/features/sales/shared/routing';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { wrapThunkActionWithErrHandler } from '../util';
import { getConfigs } from './configUtil';
import {
  setDeltaApiGetSuccess,
  setFinalSummaryApiGetSuccess,
  setHomePrefillSummaryApiGetSuccess,
  setInitialSummaryApiGetSuccess,
  setIntroApiGetSuccess,
  setProductApiGetSuccess,
  setPurchaseApiGetSuccess,
  setSecondaryNamedInsuredApiGetSuccess,
  setThirdPartyInterestApiGetSuccess,
  setThirdPartyReportsApiGetSuccess,
} from './slice';
import type { ConfigPage, MenuItem, PageFlow, RequestedPageFlow, ResponsePageFlows } from './types';

const validateParamRequestKeys = (pageFlowRequest: RequestedPageFlow): boolean => {
  switch (pageFlowRequest.configType) {
    case ConfigType.DELTA_PAGE_FLOW:
    case ConfigType.PRODUCT_PAGE_FLOW:
      return (
        !!pageFlowRequest.params.baseExp &&
        !!pageFlowRequest.params.flow &&
        !!pageFlowRequest.params.product
      );
    default:
      return !!pageFlowRequest.params.baseExp;
  }
};

const referencePartialPaths: Record<string, string[]> = {
  // This is to account for special cases where sub-pages aren't in config, but used to track navbar status
  [PagePath.EDIT_AUTO_PROFILE]: [PagePath.ADD_DRIVER_PROFILE, PagePath.ADD_VEHICLE_PROFILE],
  [PagePath.THIRD_PARTY_INTEREST]: [PagePath.THIRD_PARTY_INTEREST],
};

const mapResponses = (
  requests: Dictionary<[RequestedPageFlow, ...RequestedPageFlow[]]>,
  response: PageFlow[],
  configType: ConfigType,
): PageFlow[] => {
  return response
    .filter((resPageFlow) =>
      requests[configType]?.map((requestPageFlow) => requestPageFlow.id).includes(resPageFlow.id),
    )
    .filter((resPageFlow) => resPageFlow.value);
};

const mapResponse = (
  requests: Dictionary<[RequestedPageFlow, ...RequestedPageFlow[]]>,
  response: PageFlow[],
  configType: ConfigType,
): PageFlow => {
  return mapResponses(requests, response, configType).find(() => true) as PageFlow;
};

const processNavigationOrder = (pageFlows: ConfigPage[], pageFlow: PageFlow): void => {
  if (pageFlow.value.navigationOrder?.length > 0) {
    pageFlow.value.navigationOrder.forEach((navigation) => {
      const configPage: ConfigPage = {
        name: navigation,
        path: pageFlow.value.byPageId[navigation]?.path,
        pageTitle: pageFlow.value.byPageId[navigation]?.pageTitle,
        referencePartialPaths: referencePartialPaths[pageFlow.value.byPageId[navigation]?.path],
      };
      pageFlows.push(configPage);
    });
  }
};

const hideRentersPropertyDetailsPageMenuItems = (menuItems: MenuItem[]): void => {
  const rentersMenuItems = menuItems.find((menuItem) => menuItem.text === 'Renters');
  const rentersSubMenuItems = rentersMenuItems?.subMenuItems.filter(
    (subMenuItem) => subMenuItem.pageId !== 'propertyDetails',
  );
  // Hiding the property-details page if the feature flag is off
  if (rentersSubMenuItems) {
    menuItems[0].subMenuItems = rentersSubMenuItems;
  }
};

const hideMenuItemsBasedOnFeatureFlag = (menuItems: MenuItem[]): void => {
  // TODO Remove once the new page is launched and tested with config api changes
  if (!flagValues[FeatureFlags.RENTERS_PROPERTY_DETAILS_PAGE]) {
    hideRentersPropertyDetailsPageMenuItems(menuItems);
  }
};

const processMenuItems = (pageFlow: PageFlow): void => {
  const { menuItems } = pageFlow.value;
  if (!menuItems) {
    return;
  }
  if (menuItems[0]?.pageId) {
    menuItems.forEach((menuItem) => {
      menuItems[0].path = pageFlow.value.byPageId[menuItem.pageId]?.path;
    });
  } else {
    hideMenuItemsBasedOnFeatureFlag(menuItems);

    menuItems[0]?.subMenuItems?.forEach((menuItem) => {
      menuItem.path = pageFlow.value.byPageId[menuItem.pageId]?.path;
    });
  }
};

const processPageFlowResponse = (pageFlow: PageFlow): void => {
  const pageFlows: ConfigPage[] = [];
  if (pageFlow.value && Object.keys(pageFlow.value).length > 0) {
    processNavigationOrder(pageFlows, pageFlow);
    if (pageFlow.value) pageFlow.value.pageFlows = pageFlows;
    processMenuItems(pageFlow);
  }
};

const validateRequest = (request: RequestedPageFlow[]): void => {
  request.forEach((x) => {
    if (!validateParamRequestKeys(x)) {
      datadogLog({
        logType: 'error',
        message: `Incorrect params for ${x.configType}`,
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/config/thunks.ts',
          functionOrigin: 'fetchPageFlowResponse',
        },
      });

      throw Error(`Incorrect params for ${x.configType}`);
    }
  });
};

const validateNoMatchingIds = (request: RequestedPageFlow[]): void => {
  if (Object.keys(groupBy(request, (x: RequestedPageFlow) => x.id)).length !== request.length) {
    datadogLog({
      logType: 'error',
      message: `Cannot have matching ids`,
      context: {
        logOrigin: 'libs/features/sales/shared/store/lib/src/config/thunks.ts',
        functionOrigin: 'fetchPageFlowResponse',
      },
    });

    throw Error('Cannot have matching ids');
  }
};

const validateResponseLength = (response: PageFlow[], request: RequestedPageFlow[]): void => {
  if (response.length !== request.length) {
    datadogLog({
      logType: 'error',
      message: `Config service did not return correct amount of configs.`,
      context: {
        logOrigin: 'libs/features/sales/shared/store/lib/src/config/thunks.ts',
        functionOrigin: 'fetchPageFlowResponse',
      },
    });

    throw new Error('Config service did not return correct amount of configs.');
  }
};

const storeResponsesInStore = (
  response: PageFlow[],
  request: RequestedPageFlow[],
): ThunkAction<void> => {
  return (dispatch, _getState) => {
    const requests = groupBy(
      request,
      (x: { configType: ConfigType }) => x.configType,
    ) as Dictionary<[RequestedPageFlow, ...RequestedPageFlow[]]>;

    if (requests[ConfigType.DELTA_PAGE_FLOW]) {
      dispatch(setDeltaApiGetSuccess(mapResponses(requests, response, ConfigType.DELTA_PAGE_FLOW)));
    }
    if (requests[ConfigType.FINAL_SUMMARY_PAGE_FLOW]) {
      dispatch(
        setFinalSummaryApiGetSuccess(
          mapResponse(requests, response, ConfigType.FINAL_SUMMARY_PAGE_FLOW),
        ),
      );
    }
    if (requests[ConfigType.INITIAL_SUMMARY_PAGE_FLOW]) {
      dispatch(
        setInitialSummaryApiGetSuccess(
          mapResponse(requests, response, ConfigType.INITIAL_SUMMARY_PAGE_FLOW),
        ),
      );
    }
    if (requests[ConfigType.SECONDARY_NAMED_INSURED_PAGE_FLOW]) {
      dispatch(
        setSecondaryNamedInsuredApiGetSuccess(
          mapResponse(requests, response, ConfigType.SECONDARY_NAMED_INSURED_PAGE_FLOW),
        ),
      );
    }
    if (requests[ConfigType.HOME_PREFILL_SUMMARY_PAGE_FLOW]) {
      dispatch(
        setHomePrefillSummaryApiGetSuccess(
          mapResponse(requests, response, ConfigType.HOME_PREFILL_SUMMARY_PAGE_FLOW),
        ),
      );
    }
    if (requests[ConfigType.THIRD_PARTY_INTEREST_FLOW]) {
      dispatch(
        setThirdPartyInterestApiGetSuccess(
          mapResponse(requests, response, ConfigType.THIRD_PARTY_INTEREST_FLOW),
        ),
      );
    }
    if (requests[ConfigType.THIRD_PARTY_REPORTS_FLOW]) {
      dispatch(
        setThirdPartyReportsApiGetSuccess(
          mapResponse(requests, response, ConfigType.THIRD_PARTY_REPORTS_FLOW),
        ),
      );
    }
    if (requests[ConfigType.INTRO_PAGE_FLOW]) {
      dispatch(setIntroApiGetSuccess(mapResponse(requests, response, ConfigType.INTRO_PAGE_FLOW)));
    }
    if (requests[ConfigType.PRODUCT_PAGE_FLOW]) {
      dispatch(
        setProductApiGetSuccess(mapResponses(requests, response, ConfigType.PRODUCT_PAGE_FLOW)),
      );
    }
    if (requests[ConfigType.PURCHASE_PAGE_FLOW]) {
      dispatch(
        setPurchaseApiGetSuccess(mapResponse(requests, response, ConfigType.PURCHASE_PAGE_FLOW)),
      );
    }
  };
};

export const fetchPageFlowResponse = wrapThunkActionWithErrHandler<RequestedPageFlow[], void>(
  (request) => async (dispatch) => {
    validateRequest(request);
    validateNoMatchingIds(request);
    const configReponse: ResponsePageFlows = await getConfigs(request);
    validateResponseLength(configReponse, request);
    configReponse.forEach(processPageFlowResponse);
    dispatch(storeResponsesInStore(configReponse, request));
  },
  'fetchPageFlowResponse',
);
