import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PageLayout' })(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '81vh',
    position: 'relative',
  },
  main: {
    backgroundColor: 'transparent',
    flexGrow: 1,
    minHeight: 250,
  },
}));
