import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import Iframe from 'react-iframe';

import { GridItem } from '@ecp/components';
import { Button, Dialog } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getFullPersonInfo } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { IconMaterialDesignActionOpenInNew } from '@ecp/themes/base';

import { setInPersonSignConfirm, signingCallBackURLResponse } from '../../../state';
import { useStyles } from './InPersonClick2SignPerson.styles';

export interface Props extends QuestionProps {
  selectedProduct: Product;
  personRef: string;
}

export const InPersonClick2SignPerson: React.FC<Props> = (props) => {
  const DOCUSIGN_SUCCESS = 'SUCCESSFUL';
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const pagePath = window.location.href;
  const baseURL = `${pagePath.slice(0, pagePath.indexOf('/checkout'))}/docusign-confirm`;
  const { selectedProduct, personRef } = props;
  const { firstName, lastName } = useSelector((state) => getFullPersonInfo(state, personRef));
  const [iframeURL, setIframeURL] = useState<string>(baseURL);
  const [openIframe, setOpenIframe] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDocuSignClick = useCallback(async () => {
    setIsProcessing(true);
    const callBackURL = await dispatch(
      signingCallBackURLResponse({
        personId: personRef,
        product: selectedProduct,
        callbackURL: baseURL,
      }),
    );

    if (callBackURL) {
      setIframeURL(() => {
        return callBackURL.toString();
      });
      setOpenIframe(true);
    }

    setIsProcessing(false);
  }, [dispatch, baseURL, personRef, selectedProduct]);

  useEffect(() => {
    const handleIframeMessage = (event: {
      data: { status: string; envelopeId: string; recipientId: string };
    }): void => {
      const { status, envelopeId, recipientId } = event.data;
      if (status === DOCUSIGN_SUCCESS) {
        dispatch(
          setInPersonSignConfirm({
            personRef,
            signatureEnvelopeId: `${envelopeId}`,
            signatureRecipientId: `${recipientId}`,
          }),
        );
      }
      setOpenIframe(false);
    };
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [dispatch, personRef, selectedProduct]);

  const handleClose = useCallback(() => {
    setOpenIframe(false);
  }, []);

  // try to keep our loading overlay up as long as possible before closing in favor of iframe
  const handleLoad = useCallback(() => {
    const timeout = setTimeout(() => 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Grid container item xs={12} className={classes.container} spacing={1}>
        <GridItem topSpacing='md' className={classes.items} xs={12}>
          <p className={classes.namedInsured}>{firstName + ' ' + lastName}</p>
        </GridItem>
        <GridItem topSpacing='md' className={classes.button} xl={6} md={3} xs={12}>
          <Button
            onClick={handleDocuSignClick}
            variant='outlinePrimary'
            color='primary'
            icon={<IconMaterialDesignActionOpenInNew />}
            iconLast
            trackingLabel='inPersonClick2Sign'
            trackingName='inPersonClick2Sign'
            isProcessing={isProcessing}
          >
            SIGN DOCUMENTS IN DOCUSIGN
          </Button>
        </GridItem>
      </Grid>
      {openIframe && (
        <Dialog
          className={classes.root}
          title='InPerson Click Signature'
          onClose={handleClose}
          open
          fullScreen
          fullWidth
          aria-labelledby='fullscreen-modal-title'
          aria-describedby='fullscreen-modal-body'
          showFullScreenActionButtons
          hideTitleCloseButton
          showAppBar={false}
        >
          <Iframe
            url={iframeURL}
            id='InPersonDocuSign'
            className={classes.iframe}
            name='Signature'
            title='InPerson DocuSign'
            onLoad={handleLoad}
          />
        </Dialog>
      )}
    </>
  );
};
