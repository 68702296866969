import { emptyObject } from '@ecp/utils/common';

import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { Product } from '@ecp/features/shared/product';

import type { PaymentOption, PaymentOptions } from '../../types';
import { getCombinedTotal } from '../../util';

export const getPaymentOptions = (state: RootStore): { [productKey: string]: PaymentOptions } =>
  state.paymentoptions.paymentOptions;

export const getPaymentOption = (state: RootStore, productKey: string): PaymentOptions =>
  getPaymentOptions(state)[productKey];

export const getPaymentOptionKeysForLob = (state: RootStore, product: Product): string => {
  const paymentOptions = getPaymentOptions(state);
  const paymentOptionKeysForProduct = Object.keys(paymentOptions).filter((k) =>
    k.includes(product),
  )[0];

  return paymentOptionKeysForProduct;
};

export const getAvailablePaymentTypesForProduct = (
  state: RootStore,
  productKey: Product,
): string[] | undefined => {
  const paymentOption = getPaymentOption(state, productKey);
  if (!paymentOption) return undefined;

  return paymentOption.availablePaymentTypes;
};

export const getPaymentTypeForProduct = (
  state: RootStore,
  productKey: Product,
): PaymentOption['paymentType'] | undefined => {
  const paymentOption = getPaymentOption(state, productKey);
  if (!paymentOption) return undefined;

  return paymentOption.paymentType;
};

export const getPaymentPlanForProduct = (
  state: RootStore,
  productKey: Product,
): PaymentOption['paymentPlan'] | undefined =>
  getPaymentOption(state, productKey) ? getPaymentOption(state, productKey).paymentPlan : undefined;

export const getCreditCardUrl = (state: RootStore, productKey: string): string | null => {
  const paymentOptions = getPaymentOption(state, productKey);
  const option = paymentOptions?.carrierPaymentOptions.find((e) => e.paymentType === 'CreditCard');
  if (option) {
    return option.creditCardUrl;
  }

  return null;
};

export const getSelectedPaymentOptionPremium = (
  state: RootStore,
  productKey: Product,
): Partial<Pick<PaymentOption, 'monthlyAmount' | 'totalPremium'>> => {
  const paymentType =
    getPaymentTypeForProduct(state, productKey) === 'CostcoVisa'
      ? 'CreditCard'
      : getPaymentTypeForProduct(state, productKey);
  const paymentPlan = getPaymentPlanForProduct(state, productKey);
  const paymentOptions = getPaymentOption(state, productKey);

  // ADVECP-1496 AMFAM ADV has requirement to create an extra paymentPlan option "Custom"
  // Note:- SAPI is not sending as part of paymentOptions
  // If the payment option is 'Twelve Months' or 'Six Months' and the option has the eligibleForCustomDownPayment value true
  // push Custom as a payment option for CreditCard and EFT payments
  const revisedCarrierPaymentOptions: PaymentOption[] = paymentOptions?.carrierPaymentOptions.some(
    (option) =>
      (option.paymentPlan === 'Twelve Months' || option.paymentPlan === 'Six Months') &&
      option.eligibleForCustomDownPayment,
  )
    ? paymentOptions?.carrierPaymentOptions.reduce<PaymentOption[]>((acc, paymentOption) => {
        if (
          (paymentOption.paymentPlan === 'Twelve Months' ||
            paymentOption.paymentPlan === 'Six Months') &&
          paymentOption.eligibleForCustomDownPayment
        ) {
          acc.push({ ...paymentOption, paymentPlan: 'Custom' });
        }
        acc.push(paymentOption);

        return acc;
      }, [])
    : paymentOptions?.carrierPaymentOptions;

  let option;
  if (revisedCarrierPaymentOptions) {
    option = revisedCarrierPaymentOptions?.find(
      (paymentOption) =>
        paymentOption.paymentType === paymentType && paymentOption.paymentPlan === paymentPlan,
    );
  }

  if (option) {
    return {
      monthlyAmount: option.monthlyAmount,
      totalPremium: getCombinedTotal(productKey, option),
    };
  }

  return emptyObject;
};

export const getSelectedPaymentOptionForProduct = (
  state: RootStore,
  productKey: Product | undefined,
): PaymentOption | undefined => {
  if (!productKey) return undefined;

  const paymentOptions = getPaymentOptions(state);
  const paymentType = getPaymentTypeForProduct(state, productKey);
  const paymentPlan = getPaymentPlanForProduct(state, productKey);
  const selectedPayment = paymentOptions[productKey]?.carrierPaymentOptions.find(
    (paymentOption) =>
      paymentOption.paymentType === paymentType && paymentOption.paymentPlan === paymentPlan,
  );

  return selectedPayment;
};
