import type { LobStatus, TaskStatus } from '@ecp/features/sales/shared/constants';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { ProductName } from '@ecp/features/shared/product';
import type { Questions } from '@ecp/types';

import type { OffersResponseOnPostInquiry } from '../offers/types';

// See Question.types.ts for types regarding Inquiry's
// question data.

/**
 * @example
 * {
 *  domain: 'AmFamValidatorService',
 *  location: 'driver.7fw2js89.insurance.current',
 *  message: 'Bad Request',
 *  reason: 'Associated question not found'
 * }
 */
export interface Error {
  domain: string;
  location: string;
  message: string;
  reason: string;
  locationType: string;
}

export interface QuestionsState {
  sapi?: Questions;
  static: Questions;
}

interface ErrorState {
  [key: string]: {
    reason: string;
    message: string;
    locationType: string;
    domain: string;
  };
}

/**
 * Advance specific ADVECP-4010 && EDSP-13672
 * This is to improve performance between PLPC and UI
 * PRE_QUOTE --> triggers SAPI to call Save operation to policyAPI to first save coverables before post/put offers
 * CUSTOMIZED_COVERAGES--> triggers SAPI to call Save operation to policyAPI to first save updated coverages before rating
 **/
export const enum ProofDraftSection {
  PRE_QUOTE = 'PRE_QUOTE',
  CUSTOMIZED_COVERAGES = 'CUSTOMIZED_COVERAGES',
}

/**
 * EDSP-11130 - Resume flow for experiences coming with url and quoteid
 * PRE_PRODUCT --> No Pre-fill Called (Go to landing page)
 * PRODUCT--> Pre-fill called and OfferId doesn't exists (Go to first page of product)
 * QUOTE --> OfferId exists and bindable === false (Go to quote page)
 * POST_QUOTE --> OfferId exists and bindable === true (Go to delta page)
 * RESUME_FAILED --> DNQ, 500 Error, something went wrong (Go to landing page for now)
 * POST_BIND --> Add later (Go to page unknown)
 **/
export const enum PrimaryFlowStep {
  PRE_PRODUCT = 'PRE_PRODUCT',
  PRODUCT = 'PRODUCT',
  QUOTE = 'QUOTE',
  POST_QUOTE = 'POST_QUOTE',
  RESUME_FAILED = 'RESUME_FAILED',
}

// EDSP-11130 - Resume flow for experiences coming with url and quoteid
type FlowStep = {
  primaryFlowStep: PrimaryFlowStep;
  // subFlowStep?: unknown --> add later
};

export interface InquiryState {
  answers: Answers;
  dalSessionId?: string;
  inquiryId?: string;
  questions: QuestionsState;
  errors?: ErrorState | null;
  creatingInquiry: boolean;
  flowStep?: FlowStep;
}

type InquiryLobStatus = {
  [product in ProductName]+?: LobStatus;
};

type InquiryQuestionStatus = {
  timestamp: string;
};

type InquiryTaskStatusResponse =
  | {
      status: TaskStatus.WORKING;
      estimatedCompletionTime: string;
    }
  | {
      status: TaskStatus.COMPLETE;
    }
  | Record<string, never>;

interface InquiryTaskStatus {
  id: string;
  taskName: string;
  status: TaskStatus;
  estimatedCompletion: string;
  actualCompletion: string;
  timeOut: string;
  submissionTime: string;
}

/**
 * @see https://gitlab.com/amfament/homesite/sapi/sapi-inquiry/-/blob/dev/src/main/java/com/homesite/sapi/inquiry/service/InquiriesStatusService.java
 * @see https://gitlab.com/amfament/homesite/sapi/sapi-core/-/blob/dev/src/main/java/com/homesite/sapi/core/model/inquiry/InquiryStatus.java
 */
export interface InquiryStatus {
  currentTime: string;
  lob: InquiryLobStatus;
  questions: InquiryQuestionStatus;
  request: InquiryTaskStatusResponse;
  tasks?: Record<string, InquiryTaskStatus>;
}

export interface AnswersResponse {
  dalSessionId: string;
  data: {
    id: string;
    answers: Record<string, string | null>;
    errors: Error[];
    inquiryStatus: InquiryStatus;
  };
}

export interface InquiryResponse {
  dalSessionId: string;
  data: {
    id: string;
    questions: Questions;
    answers: Answers;
    errors?: Error[];
    inquiryStatus: InquiryStatus;
    flowStep?: FlowStep;
    offers?: OffersResponseOnPostInquiry;
  };
}

export interface SyncPeopleResponse {
  dalSessionId: string;
}
export interface CheckPolicyTypeResponse {
  dalSessionId: string;
  data: {
    policyTypes: ProductName[];
    propertyState: string;
  };
}

export interface SubmitPolicyTypeResponse {
  dalSessionId: string;
  data: {
    id: string;
    questions: Questions;
    answers: Answers;
    errors?: Error[];
    inquiryStatus: InquiryStatus;
  };
  errorCode?: string;
  errorData?: {
    availablePolicyTypes?: ProductName[];
    unavailableRequestedPolicyTypes?: ProductName[];
  };
}
