import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { getSapiTarget } from '../../api';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setInquiryApiGetSuccess } from '../actions';
import { postProofDraft } from '../api';
import { getDalSessionId } from '../selectors';
import { ProofDraftSection } from '../types';

export const submitProofDraft = wrapThunkActionWithErrHandler<{
  policyTypes?: string[];
  section?: string;
}>(
  ({ policyTypes, section = ProofDraftSection.PRE_QUOTE }) =>
    async (dispatch, getState) => {
      const state = getState();
      const dalSessionId = getDalSessionId(state);
      const sapiTarget = getSapiTarget(state);
      const shouldSubmitProofDraft = flagValues[FeatureFlags.PROOF_DRAFT_ENABLED];

      if (!dalSessionId || sapiTarget !== 'v4' || !shouldSubmitProofDraft) {
        return;
      }

      const response = await postProofDraft({
        dalSessionId,
        section,
        policyTypes,
      });
      dispatch(setInquiryApiGetSuccess(response.payload));
    },
  'submitProofDraft',
);
