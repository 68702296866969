import { emptyArray, uuid } from '@ecp/utils/common';

import { getAnswer } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { getPaymentPlanForProduct } from '../paymentoptions/selectors';
import type {
  BankAccount,
  CreditCard,
  CreditCardType,
  EFTPayParams,
  Mortgage,
  PaymentMethod,
  PaymentNumber,
  PurchasePaymentOption,
  PurchaseRequestOnSubmit,
  PurchaseResponse,
  TokenForParams,
} from './types';

export const getPaymentMethod = (state: RootStore, product: Product): PaymentMethod =>
  state.purchase.purchaseRequest[product]?.paymentMethod;

export const getPurchasePaymentOption = (
  state: RootStore,
  product: Product,
): PurchasePaymentOption => state.purchase.purchaseRequest[product]?.paymentOption;

export const getBankAccount = (state: RootStore, product: Product): BankAccount =>
  state.purchase.purchaseRequest[product].paymentOption.bankAccount;

export const getRecurringPayBankAccount = (state: RootStore, product: Product): BankAccount =>
  state.purchase.purchaseRequest[product].paymentOption.banckAccountForRecurringPay;

export const getCreditCard = (state: RootStore, product: Product): CreditCard =>
  state.purchase.purchaseRequest[product].paymentOption.creditCard;

export const getCreditCardType = (
  state: RootStore,
  product: Product,
  type: CreditCardType,
): string => getPurchasePaymentOption(state, product)?.[type].creditCardType;

export const getCostcoVisa = (state: RootStore, product: Product): CreditCard =>
  state.purchase.purchaseRequest[product].paymentOption.costcoVisa;

export const getMortgage = (state: RootStore, product: Product): Mortgage =>
  state.purchase.purchaseRequest[product].paymentOption.mortgage;

export const getCreditCardToken = (state: RootStore, product: Product): string | null =>
  state.purchase.purchaseRequest[product].paymentOption.creditCard.token;

export const getPurchaseCardToken = (
  state: RootStore,
  product: Product,
  type: CreditCardType,
): string | null => getPurchasePaymentOption(state, product)?.[type].token;

export const getPurchaseCardTokenFetching = (
  state: RootStore,
  product: Product,
  type: CreditCardType,
): boolean | undefined => getPurchasePaymentOption(state, product)?.[type].tokenFetching;

export const getGettingTokenFor = (
  state: RootStore,
  product: Product,
  type: string,
): TokenForParams | undefined =>
  state.purchase.gettingTokenFor[product]
    ? state.purchase.gettingTokenFor[product][type]
    : undefined;

export const getTokenForEFTPay = (state: RootStore, product: Product): EFTPayParams | undefined =>
  state.purchase.gettingTokenForEFTPay[product] || undefined;

export const getTokenForReccuringPayment = (
  state: RootStore,
  product: Product,
): EFTPayParams | undefined =>
  state.purchase.gettingEFTTokenForReccuringPayment[product] || undefined;

export const getPciToken = (state: RootStore): string | undefined =>
  state.purchase.pciToken || undefined;

export const getPurchaseResponse = (state: RootStore): PurchaseResponse =>
  state.purchase.purchaseResponse;

export const getHomeInspectionStatus = (state: RootStore): string =>
  state.purchase.homeInspectionStatus || '';

export const getProductPurchaseError = (state: RootStore, product: Product): string | void => {
  return getPurchaseResponse(state).policies?.[product].errorReason;
};

export const getPaymentNumber = (state: RootStore, products: Product[]): PaymentNumber => {
  const payment: PaymentNumber = {};
  products.forEach((product) => {
    const paymentMethod = getPaymentMethod(state, product);
    const paymentNumber = '';
    payment[product] = { paymentNumber };

    if (
      paymentMethod === 'bankAccount' &&
      state.purchase.purchaseRequest[product].paymentOption.bankAccount.accountNumber !== ''
    ) {
      const bankAccountInfo = getBankAccount(state, product);
      payment[product].paymentNumber = bankAccountInfo.accountNumber;
    } else if (
      paymentMethod === 'costcoVisa' &&
      state.purchase.purchaseRequest[product].cardNumber
    ) {
      payment[product].paymentNumber = state.purchase.purchaseRequest[product].cardNumber;
    } else if (
      paymentMethod === 'creditCard' &&
      state.purchase.purchaseRequest[product].cardNumber
    ) {
      payment[product].paymentNumber = state.purchase.purchaseRequest[product].cardNumber;
    }
  });

  return payment;
};

export const getPurchaseRequest = (
  state: RootStore,
  products: Product[],
): PurchaseRequestOnSubmit => {
  const purchaseRequest: PurchaseRequestOnSubmit = {};
  products.forEach((product) => {
    const paymentOption = { paymentOption: {} };
    purchaseRequest[product] = paymentOption;
    const paymentMethod = getPaymentMethod(state, product);
    const paymentPlan = getPaymentPlanForProduct(state, product);
    const productName = getProductNameFromProduct(product);

    const autoWithdrawAgree = getAnswer(
      state,
      `static.checkout.${productName}.autoWithdrawAgree`,
    ) as boolean | undefined;
    const knowMortgageCompany = getAnswer(
      state,
      `static.checkout.${productName}.mortgageKnowMortgageCompany`,
    ) as boolean | undefined;
    const pciToken = getPciToken(state);
    const samePayMethodAsDownPay = Boolean(
      getAnswer(state, `static.checkout.${productName}.useInitialPayPaymentInd`),
    );
    const customPaymentAmount = getAnswer(
      state,
      `static.checkout.${productName}.customPaymentAmount`,
    );
    const autoPayAmountOption = String(
      getAnswer(state, `static.checkout.${productName}.recurringPaymentAmount`),
    );
    const collectReccuringPay =
      String(getAnswer(state, `discount.${productName}.autoPay`)) === 'true' &&
      String(getAnswer(state, `delta.coverageOption.${product}.autoPayType`)) ===
        'MY_ACCOUNT_BILLING';

    // Advance request
    purchaseRequest[product].pciToken = pciToken;
    if (customPaymentAmount) {
      purchaseRequest[product].customDownPayment = customPaymentAmount?.toString();
    }

    if (
      paymentMethod === 'bankAccount' ||
      (paymentMethod && paymentMethod.toLowerCase() === 'eft')
    ) {
      purchaseRequest[product].paymentOption.bankAccount = {
        ...getBankAccount(state, product),
        ...(autoWithdrawAgree && paymentPlan !== 'Full' && { autoWithdrawAgree }),
      };
    } else if (
      paymentMethod === 'creditCard' &&
      state.purchase.purchaseRequest[product].paymentOption.creditCard.firstName !== ''
    ) {
      purchaseRequest[product].paymentOption.creditCard = {
        ...getCreditCard(state, product),
        ...(autoWithdrawAgree && paymentPlan !== 'Full' && { autoWithdrawAgree }),
      };
    } else if (
      paymentMethod === 'costcoVisa' &&
      state.purchase.purchaseRequest[product].paymentOption.costcoVisa.firstName !== ''
    ) {
      purchaseRequest[product].paymentOption.creditCard = {
        ...getCostcoVisa(state, product),
        ...(autoWithdrawAgree && paymentPlan !== 'Full' && { autoWithdrawAgree }),
      };
      // Sending a new field to SAPI for connect auto CC iFrame purchase request (v4 support)
    } else if (
      (paymentMethod === 'creditCard' || paymentMethod === 'costcoVisa') &&
      product.includes('connect')
    ) {
      purchaseRequest[product].proceedToBind = true;
    } else if (paymentMethod === 'mortgage') {
      purchaseRequest[product].paymentOption.mortgage = knowMortgageCompany
        ? getMortgage(state, product)
        : {};
    }
    // Starts --- Specific to recurring payment setup usage
    if (collectReccuringPay && !samePayMethodAsDownPay) {
      purchaseRequest[product].sameAccountAutoPayInd = samePayMethodAsDownPay;
      purchaseRequest[product].recurringPaymentAmountOption = autoPayAmountOption;
      purchaseRequest[product].paymentOption.banckAccountForRecurringPay = {
        ...getRecurringPayBankAccount(state, product),
      };
    } else if (collectReccuringPay && samePayMethodAsDownPay) {
      purchaseRequest[product].sameAccountAutoPayInd = samePayMethodAsDownPay;
      purchaseRequest[product].recurringPaymentAmountOption = autoPayAmountOption;
      purchaseRequest[product].paymentOption.banckAccountForRecurringPay = {
        ...getBankAccount(state, product),
        ...{
          paymentId: uuid(),
          paymentPurpose: 'RECURRING_PAYMENT',
        },
      };
    }
    // Ends --- Specific to recurring payment setup usage
  });

  return purchaseRequest;
};

export const getPolicyNumbers = (state: RootStore): Readonly<string[]> => {
  const { policies } = getPurchaseResponse(state);
  const policyNumbers: Readonly<string[]> = policies
    ? Object.values(policies).map(({ policyNumber }) => policyNumber)
    : emptyArray;

  return policyNumbers;
};

export const getPaymentOptionsAndAcksRefetchRequired = (state: RootStore): boolean =>
  state.purchase.isPaymentOptionsAndAcksRefetchRequired;
