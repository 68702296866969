import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { StateFeesSection } from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { getOfferDetailsForProduct } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';

import { getMonthlyPaymentsText } from '../../util';
import { EstimatedTooltip } from '../EstimatedTooltip';
import { Recalc } from '../Recalc';
import metadata from './metadata';
import { useStyles } from './MobileMonthlyPaymentsCheckoutCard.styles';

interface Props {
  offerProduct: Product;
  recalculate?: boolean;
  showEstimated?: boolean;
  showProcess: boolean;
  premiumPlan: PaymentPlan;
  showError?: boolean;
  showStateFees?: boolean;
  isPricingDetailsHidden?: boolean;
}

export const MobileMonthlyPaymentsMonolineCoverageView: React.FC<Props> = (props) => {
  const {
    isPricingDetailsHidden,
    offerProduct,
    recalculate,
    showEstimated,
    showProcess,
    premiumPlan,
    showError,
    showStateFees,
  } = props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  // VariantA - Zillow
  const applyMobileVariantAStyle =
    isMobile && metadata.isVariantACoverageStyle && !env.static.isAgent;

  const offerDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, offerProduct),
  );

  if (showError || !offerDetails) return null;

  const { policyDuration, monthlyPremium, fullPremium } = offerDetails;
  const productName = getProductDisplayNameFromProduct(offerProduct);

  return (
    <Grid
      xs={12}
      sm={applyMobileVariantAStyle ? 8 : 10}
      md={4}
      container
      justifyContent='center'
      alignItems='center'
      className={cx(
        classes.bottomDetailsWrapper,
        applyMobileVariantAStyle && classes.mobileVariantAStyleBottomDetailsWrapper,
      )}
    >
      <Grid item xs={12} className={classes.policyDurationHeader}>
        <p className={classes.mobileHeading}>
          <span className={classes.policyDurationHeaderProduct}>{productName}</span>{' '}
          {`${policyDuration}-mo policy`}
          {showEstimated && <EstimatedTooltip inline />}
        </p>
      </Grid>
      {premiumPlan === PaymentPlan.MONTHLY_PREMIUM ? (
        <>
          <Grid item container xs={12}>
            <Grid item xs={7} className={classes.policyCheckoutHeaders}>
              {getMonthlyPaymentsText(
                monthlyPremium?.paymentPlan,
                monthlyPremium?.downPayment,
                offerProduct,
              )}
            </Grid>

            <Grid item xs={5} className={classes.premiumBold}>
              <Recalc
                amount={parseDollar(monthlyPremium?.installmentPayment)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
            </Grid>
          </Grid>
          {(metadata.showDueTodayAmt || !!monthlyPremium?.downPayment) && (
            <Grid item container xs={12}>
              <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                Due today
              </Grid>
              <Grid xs={5} className={classes.premiums}>
                <Recalc
                  amount={parseDollar(monthlyPremium?.downPayment)}
                  recalculate={recalculate}
                  showProcess={showProcess}
                />
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12}>
            <Grid item xs={7} className={classes.policyCheckoutHeaders}>
              Policy total
            </Grid>
            <Grid item xs={5} className={classes.premiums}>
              <Recalc
                amount={parseDollar(monthlyPremium?.totalPremium)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
            </Grid>
          </Grid>
          {applyMobileVariantAStyle && (
            <Grid item xs={12}>
              <p className={classes.variantAInstallmentStatement}>
                Premium does not include installment fees
              </p>
            </Grid>
          )}
        </>
      ) : (
        <Grid item container xs={12}>
          <Grid item xs={7}>
            <p className={classes.policyCheckoutHeaders}>One-time payment of</p>
          </Grid>
          <Grid item xs={5} className={classes.premiumBold}>
            <Recalc
              amount={parseDollar(fullPremium?.totalPremium)}
              recalculate={recalculate}
              showProcess={showProcess}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container xs={12}>
        {!isPricingDetailsHidden && (
          <StateFeesSection
            premiumPlan={premiumPlan}
            offerDetails={offerDetails}
            showStateFees={showStateFees}
          />
        )}
      </Grid>
    </Grid>
  );
};
