import { useCallback } from 'react';

import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

import { PRIMARY_HEATING_TYPE } from '../../../../constants/questions';

export const PrimaryHeatingTypeQuestion: React.FC<QuestionProps> = (props) => {
  const primaryHeatingType = useField(PRIMARY_HEATING_TYPE);
  const {
    groupLabel = primaryHeatingType.question?.title,
    trackingName = 'primary_heating_type_dropdown',
    hideError = false,
  } = props;
  const error = hideError ? undefined : primaryHeatingType.props.error;
  const fuelType = useField('property.heating.primaryHeatSourceFuelType');

  const primaryHeatingTypeOnChange: (value: AnswerValue) => void = useCallback(
    async (value: AnswerValue) => {
      if (value === primaryHeatingType.value) return;
      if (fuelType && fuelType.value) {
        fuelType.props.actionOnComplete(null);
      }
      primaryHeatingType.props.actionOnChange(value);
    },
    [fuelType, primaryHeatingType],
  );

  useAddFields({ primaryHeatingType });

  if (!primaryHeatingType.exists) return null;

  return (
    <Select
      {...primaryHeatingType.props}
      error={error}
      actionOnChange={primaryHeatingTypeOnChange}
      id='primaryHeatingType'
      groupLabel={groupLabel}
      data-testid='primaryHeatingType'
      trackingName={trackingName}
      inputButtonAriaLabel='primaryHeatingType'
    />
  );
};
