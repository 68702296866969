import FileSaver from 'file-saver';

import { noop, waitForCondition } from '@ecp/utils/common';

import {
  getDalSessionId,
  getOfferSetId,
  wrapThunkActionWithErrHandler,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';

import { fetchDocuments, fetchDocumentsURL } from './api';
import type { DocumentURLResponse } from './types';

const DEFAULT_TASK_LIMIT_IN_SECONDS = 30;
const DEFAULT_ATTEMPT_DELAY_IN_SECONDS = 1;
const DEFAULT_NUMBER_OF_ATTEMPTS = 5;
const DEFAULT_INTERVAL_IN_MILLISECONDS = 300;

const downloadDocuments = async ({
  documentTypeName,
  dalSessionId,
  product,
}: {
  documentTypeName: string;
  dalSessionId: string;
  product: AnswerValue;
}): Promise<Awaited<ReturnType<typeof fetchDocuments>> | undefined> => {
  let result: Awaited<ReturnType<typeof fetchDocuments>> | undefined;
  let isRequestPending = true;
  let attemptsLeft = DEFAULT_NUMBER_OF_ATTEMPTS;

  const fetchDocumentsRequest = (): Promise<void> =>
    fetchDocuments({
      documentTypeName,
      dalSessionId,
      product,
    })
      .then((response) => {
        result = response;
      })
      .catch(noop)
      .finally(() => {
        attemptsLeft -= 1;
        isRequestPending = false;
      });

  let pendingTimer = setTimeout(fetchDocumentsRequest);

  await waitForCondition({
    condition: (): boolean => {
      if (result) return true;
      if (attemptsLeft <= 0) return true;
      if (isRequestPending) return false;

      isRequestPending = true;
      pendingTimer = setTimeout(fetchDocumentsRequest, DEFAULT_ATTEMPT_DELAY_IN_SECONDS * 1000);

      return false;
    },
    limit: DEFAULT_TASK_LIMIT_IN_SECONDS * 1000,
    interval: DEFAULT_INTERVAL_IN_MILLISECONDS,
  });

  clearTimeout(pendingTimer);

  return result;
};

export const downloadAndSaveDocuments =
  ({
    documentDisplayName,
    documentTypeName,
    product,
  }: {
    documentDisplayName: string;
    documentTypeName: string;
    product: AnswerValue;
  }): ThunkAction<Promise<boolean>> =>
  async (...[, getState]) => {
    const dalSessionId = getDalSessionId(getState());
    const offerSetId = getOfferSetId(getState());

    if (!dalSessionId || !offerSetId) {
      return false;
    }

    const response = await downloadDocuments({ documentTypeName, dalSessionId, product });

    if (!response || response.status !== 200 || !response.payload) return false;

    const pdfBlob = new Blob([response.payload], { type: 'application/pdf' });
    FileSaver.saveAs(pdfBlob, `${documentDisplayName}.pdf`);

    return true;
  };

type DocumentURLs = {
  dalSessionId: string;
  product: Product[];
};
export const retrieveDocumentURL = wrapThunkActionWithErrHandler<
  DocumentURLs,
  DocumentURLResponse['data'] | undefined
>(
  ({ dalSessionId, product }) =>
    async () => {
      const response = await fetchDocumentsURL({ dalSessionId, product }).then(
        (result) => result.payload.data,
      );

      return response;
    },
  'retrieveDocumentURL',
);
