import { ensureZipCode } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { statusBadRequest, statusOk } from '@ecp/utils/network';

import { getDalSessionId, wrapThunkActionWithErrHandler } from '@ecp/features/sales/shared/store';
import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';

interface CityStateResponse {
  state: string | null;
  city: string | null;
  bypass: boolean;
}

export const fetchCityAndStateSuggestionsThunkAction = wrapThunkActionWithErrHandler(
  (zip?: string | undefined) => async (dispatch, getState) => {
    const defaultReturn = { city: null, state: null, bypass: false };
    const zipcode = ensureZipCode(zip);

    if (!zipcode) return defaultReturn;
    const state = getState();
    const dalSessionId = getDalSessionId(state);

    try {
      const response = (await salesRequest({
        endpoint: `session/${dalSessionId}/geo/zipcode-lookup/${zipcode}`,
        options: {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      })) as SalesResponse<CityStateResponse>;

      if (!statusOk(response.status)) {
        if (statusBadRequest(response.status)) {
          return { ...defaultReturn, bypass: true };
        }

        return defaultReturn;
      }

      return { ...response.payload, bypass: false };
    } catch (error) {
      const e = error as Error;

      datadogLog({
        logType: 'warn',
        message: `Error fetching Smarty Streets City and State Suggestions - ${e?.message}`,
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/geo/thunks.ts',
          functionOrigin: 'fetchCityAndStateSuggestionsThunkAction',
          contextType: 'Smarty Streets Error',
          severity: 'low',
          responseError: JSON.stringify(e),
        },
        error: e,
      });

      return defaultReturn;
    }
  },
  'fetchCityAndStateSuggestionsThunkAction',
);
