import { useMemo } from 'react';

import { upperFirst } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getCurrentPage, getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import metadata from '../../CoveragesForm/metadata';

const LIFTOFF_VARIANT_A_TITLE = 'Customized Coverage';
const LIFTOFF_DEFAULT_TITLE = 'Customize your coverage';

const getTitle = (products: Product[]): string => {
  if (!env.static.isAgent) return LIFTOFF_DEFAULT_TITLE;

  const productNames = products.map(getProductNameFromProduct);

  if (productNames.length === 0) return 'Your policy';
  if (productNames.length === 1) return `Your ${productNames[0]} policy`;

  return `${upperFirst(productNames.join(' and '))} policies`;
};

export const useTitle = (): string => {
  const offerProductsSelected = useSelector(getOfferProductsSelected);

  const showLiftoffVariantATitle = metadata.isVariantACoverageStyle && !env.static.isAgent;
  const title = showLiftoffVariantATitle
    ? LIFTOFF_VARIANT_A_TITLE
    : getTitle(offerProductsSelected);

  const currentPage = useSelector(getCurrentPage);

  // write-once (as soon as it's determined, it does NOT change)
  const customizeCoveragesEnabled = useMemo(() => {
    return env.static.isAgent && currentPage === PagePath.COVERAGES;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return customizeCoveragesEnabled ? 'Verified policy summary' : title;
};
