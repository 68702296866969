import type { AnswerValue } from '@ecp/types';

import { setApiComplete, setApiStart } from '../../api/actions';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setAnswersApiGetSuccess } from '../actions';
import { deleteInquiry } from '../api';
import { getDalSessionId } from '../selectors';

type DeleteInquiryRef = AnswerValue;

/**
 * This will bulk delete any ref anywhere it is used.
 * Basically you can pass into it 'driver' 'abc123'
 * and anywhere that ref is used it will be removed.
 *
 * This works with any ref (i.e. vehicle, address, person, etc.)
 *
 * @param {AnswerValue} ref - This is reference e.g. person.123
 */
export const deleteInquiryRef = wrapThunkActionWithErrHandler<DeleteInquiryRef>(
  (ref) => async (dispatch, getState) => {
    // null, undefined, etc (NOTE: stringify can be weird if ref is really weird)
    if (typeof ref !== 'string') throw new Error(`ref was not a string ${JSON.stringify(ref)}`);

    // NOTE: this takes something like person.123 (or person.123.firstName)
    // and returns ['person', '123']
    const [refType, refId] = ref.split('.', 2);

    if (!refType || !refId)
      throw new Error(
        `"${ref}" does not seem to be in reference format, got "${refType}"."${refId}"`,
      );

    const dalSessionId = getDalSessionId(getState());

    if (!dalSessionId) {
      return;
    }

    dispatch(setApiStart(refId));
    const response = await deleteInquiry({ dalSessionId, refType, refId });
    dispatch(setAnswersApiGetSuccess(response.payload));

    dispatch(setApiComplete(refId));
  },
  'deleteInquiryRef',
);
