import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoTPIPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 0,
  },
  next: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  button: {
    ...theme.typography.buttonSmall,
    borderRadius: 25,
    padding: '7px 20px',
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
  },

  content: {
    margin: 0,
    width: '100%',
  },
  card: {
    padding: 20,
    width: '100%',
    border: `1px solid ${theme.palette.other.divider}`,
    borderRadius: '4px',
    marginTop: 12,
  },
  contentSubTitle: {
    ...theme.typography.body2,
    color: theme.palette.text.tertiary,
    [theme.breakpoints.down('xl')]: {
      marginBottom: 10,
    },
  },
  title: theme.typography.body1Bold,
  columnRight: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    gap: 30,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
      alignItems: 'center',
    },
  },
  divider: {
    margin: '20px 0px',
    width: '100%',
  },

  subTitle: {
    marginTop: 5,
  },
  actionButton: {
    padding: 10,
  },
  snackbar: {
    '.MuiSnackbarContent-message': {
      ...theme.typography.body1,
      padding: 0,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.common.white),
  },
}));
