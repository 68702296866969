import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';

import {
  castAnswerType,
  castToBoolean,
  castToNumberOrNull,
  emptyArray,
  ensureStringArray,
  ensureStringOrNull,
  isTruthy,
  parseDollar,
  unique,
} from '@ecp/utils/common';
// import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { getVehicleDescription, VEHICLE_ADDRESS_REF } from '@ecp/features/sales/quotes/auto';
import {
  BUSINESS_ON_PREMISES_REF,
  HEATING_SOURCE_REF,
  LIVING_AREA,
  NUMBER_OF_MORTGAGES,
  SECONDARY_HEATING_SOURCE_REF,
  SINGLE_OR_MULTI_FAMILY,
  YEAR_BUILT,
} from '@ecp/features/sales/quotes/property/home';
import type { PaymentPlan, PrefillFlow } from '@ecp/features/sales/shared/constants';
import {
  AUTO_POLICY_START_DATE,
  AUTO_POLICY_TERM,
  COSTCO_MEMBERSHIP_TIER,
  CURRENT_CONFIG_PAGE,
  CURRENT_PAGE,
  DRIVERS_REF,
  HAS_USER_STARTED_IN_EM,
  HOME_POLICY_START_DATE,
  HOME_POLICY_TERM,
  LEXIS_NEXIS_CALL,
  LEXIS_NEXIS_TRIGGER,
  LINE_OF_BUSINESS,
  LOB_AUTO_NOT_ELIGIBLE_MSG,
  LOB_BUNDLE_NOT_ELIGIBLE_MSG,
  LOB_HOME_NOT_ELIGIBLE_MSG,
  LOB_ORDER,
  LOB_RENTERS_NOT_ELIGIBLE_MSG,
  PARTNER_CHANNEL,
  PARTNER_EXPERIENCE_ID,
  PERSON_REF,
  POLICY_EFFECTIVE_DATE_PARAM,
  POLICY_VEHICLE_REF,
  PRIMARY_INSURED_PERSON_REF,
  PRODUCT_LOB_USER_SELECTION,
  RENTERS_POLICY_START_DATE,
  RENTERS_POLICY_TERM,
  ROOF_INSTALL_OR_REPLACE,
  SELECTED_PAYMENT_PLAN,
  STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE,
  STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE,
  THIRD_PARTY_INTEREST_REF,
  USER_SELECTION,
} from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  Address,
  Answers,
  AnswerValue,
  CostcoMembershipTier,
  DriverBasicInfo,
  Person,
  QuestionsMetadata,
  Vehicle,
} from '@ecp/features/sales/shared/types';
import type { DriversLicense } from '@ecp/features/servicing/shared/types';
import type {
  AutoProduct,
  HomeProduct,
  LineOfBusinessUnbundled,
  Product,
  ProductName,
  PropertyProduct,
  ReducedProductName,
  RentersProduct,
} from '@ecp/features/shared/product';
import {
  getProductNamesFromLineOfBusiness,
  isLineOfBusinessBundle,
  isProductAuto,
  isProductHome,
  isProductRenters,
  LineOfBusiness,
  productMatchesLOB,
} from '@ecp/features/shared/product';
import type {
  Channel,
  ExperienceId,
  OpCoName,
  OverrideException,
  PartnerName,
} from '@ecp/partners';
import { mapping, opcoMapping, overrideExceptionMapping } from '@ecp/partners';
import type { BaseStateMetadataCollection, Field, Option, Question } from '@ecp/types';

import { getSapiTarget } from '../api';
import { getProductOrder } from '../config';
import { getEligibleProductsFromSapi, getOfferProductsSelectedByType } from '../offers/selectors';
import type { QuestionsState } from './types';
import { collectRefProperties } from './util/collectRefProperties';
import { getLabelForAnswersValue } from './util/getLabelForAnswersValue';
import { getQuestionNoDefault, getQuestionWithDefault } from './util/getQuestion';
import { productsToLobs } from './util/productToLobs';

export const getDalSessionId = (state: RootStore): string | undefined => state.inquiry.dalSessionId;

export const getInquiryId = (state: RootStore): string | undefined => state.inquiry.inquiryId;

// EDSP-11130 - Resume flow for experiences coming with url and quoteid
export const getPrimaryFlowStep = (state: RootStore): string | undefined =>
  state.inquiry.flowStep?.primaryFlowStep;

// answers
export const getAnswers = (state: RootStore): Answers => state.inquiry.answers;

export const getPartnerExperienceId = (state: RootStore): ExperienceId =>
  state.inquiry.answers[PARTNER_EXPERIENCE_ID] as ExperienceId;

/** Used for determining currently selected partner name, which is dependent on current inquiry, rather than environment variable */
export const getPartnerName = (state: RootStore): PartnerName | undefined => {
  const partnerExpId = getPartnerExperienceId(state);

  return partnerExpId ? mapping[partnerExpId] : undefined;
};

export const getOpCoName = (state: RootStore): OpCoName | undefined => {
  const partnerExpId = getPartnerExperienceId(state);

  return partnerExpId ? opcoMapping[partnerExpId] : undefined;
};

export const getOverrideException = (state: RootStore): OverrideException | undefined => {
  const partnerExpId = getPartnerExperienceId(state);

  return partnerExpId ? overrideExceptionMapping[partnerExpId] : undefined;
};

export const getAnswer = createCachedSelector<RootStore, string, Answers, string, AnswerValue>(
  getAnswers,
  (...[, key]) => key,
  (answers, key) => answers[key],
)((...[, key]) => key);

export const getVariants = (state: RootStore): string[] => {
  const answers = getAnswer(state, 'analytics.variants');
  const variantsArray = answers ? answers.toString().split(',').filter(isTruthy) : null;

  return ensureStringArray(variantsArray);
};

// questions
export const getQuestions = (state: RootStore): QuestionsState => state.inquiry.questions;

export const getQuestion =
  (key: string, questionKey?: string): ((state: RootStore) => Question) =>
  (state: RootStore): Question =>
    getQuestionWithDefault(key, getQuestions(state), questionKey, getAnswers(state));

export const getLineOfBusiness = (state: RootStore): LineOfBusiness =>
  getAnswer(state, LINE_OF_BUSINESS) as LineOfBusiness;

export const getLineOfBusinessUserSelection = (state: RootStore): LineOfBusiness | undefined =>
  getAnswer(state, PRODUCT_LOB_USER_SELECTION) as LineOfBusiness | undefined;

export const getAvailablePolicyTypes = (state: RootStore): ProductName[] => {
  const products = getAnswer(state, 'static.dal.availablePolicyTypes') as string;
  const result = (castAnswerType(products, 'List') ?? emptyArray) as unknown as ProductName[];

  return result;
};

export const getProducts = (state: RootStore): Product[] => {
  const products = getAnswer(state, 'product') as string;
  const result = (castAnswerType(products, 'List') ?? emptyArray) as unknown as Product[];

  return result;
};

export const getAutoProduct = (state: RootStore): AutoProduct | undefined => {
  const products = getProducts(state);

  return products.find(isProductAuto);
};

export const getHomeProduct = (state: RootStore): HomeProduct | undefined => {
  const products = getProducts(state);

  return products.find(isProductHome);
};

export const getRentersProduct = (state: RootStore): RentersProduct | undefined => {
  const products = getProducts(state);

  return products.find(isProductRenters);
};

export const getSelectedPropertyProduct = (state: RootStore): PropertyProduct | undefined => {
  const lob = getLineOfBusiness(state);

  const homeProduct = getHomeProduct(state);
  if (homeProduct && productMatchesLOB(lob, homeProduct)) return homeProduct;

  const rentersProduct = getRentersProduct(state);
  if (rentersProduct && productMatchesLOB(lob, rentersProduct)) return rentersProduct;

  return undefined;
};

export const questionExists =
  (key: string, questionKey?: string): ((state: RootStore) => boolean) =>
  (state: RootStore): boolean =>
    !!getQuestionNoDefault(key, getQuestions(state), questionKey);

export const getInquiryLoaded = (state: RootStore): boolean => !!getQuestions(state).sapi;

export const getVehicleRefs = (state: RootStore): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[POLICY_VEHICLE_REF]);
};

export const getDriverRefs = (state: RootStore): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[DRIVERS_REF]);
};

export const getTPIRefs = (state: RootStore): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[THIRD_PARTY_INTEREST_REF]);
};

export const getMilitaryDeploymentRef = (state: RootStore, driverRef: string): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[`${driverRef}.militaryDeployments.ref`]);
};

export const getPriorInsuranceRef = (state: RootStore, driverRef: string): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[`${driverRef}.priorInsurance.ref`]);
};

export const getInitialPrimaryInsuredAddressRefFromRelate = (state: RootStore): string => {
  const answers = getAnswers(state);

  return String(answers[STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE]);
};
export const getInitialPrimaryInsuredMailingAddressRefFromRelate = (state: RootStore): string => {
  const answers = getAnswers(state);

  return String(answers[STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE]);
};

export const getVehicleAddressRef = (state: RootStore): string => {
  const answers = getAnswers(state);

  return String(answers[VEHICLE_ADDRESS_REF]);
};

type VehicleInfo = Pick<
  Vehicle,
  'year' | 'make' | 'model' | 'ref' | 'description' | 'vin' | 'primaryUse' | 'annualMiles'
>;

export const getVehicleInfo = (state: RootStore): VehicleInfo[] => {
  const vehicleRefs = getVehicleRefs(state);
  const answers = getAnswers(state);
  const questions = getQuestions(state);
  const vehicleInfo = vehicleRefs.map((ref) =>
    collectRefProperties(answers, questions, ref, {
      year: '',
      make: '',
      model: '',
      vin: '',
      primaryUse: '',
      annualMiles: '',
      ref,
    }),
  );

  return vehicleInfo
    .filter((v) => v.year !== '' || v.make !== '' || v.model !== '')
    .map((infoObj) => ({
      ...infoObj,
      description: getVehicleDescription(infoObj),
    }));
};

/**
 * selector to get vehicle info object keyed by ref
 * @param state
 * @returns {Record<string, VehicleInfo>}
 */
export const getVehiclesInfoByRef = (state: RootStore): Record<string, VehicleInfo> =>
  Object.fromEntries(getVehicleInfo(state).map((vehicle) => [vehicle.ref, vehicle]));

export const getPersonInfo = (
  state: RootStore,
  personRef: string,
): Pick<
  Person,
  'firstName' | 'middleName' | 'lastName' | 'suffix' | 'dateOfBirth' | 'maritalStatus'
> => {
  const answers = getAnswers(state);
  const questions = getQuestions(state);

  return collectRefProperties(answers, questions, personRef, {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    dateOfBirth: '',
    maritalStatus: '',
  });
};

export const getFullPersonInfo = (
  state: RootStore,
  personRef: string,
): Pick<
  Person,
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'suffix'
  | 'dateOfBirth'
  | 'email'
  | 'phone'
  | 'maritalStatus'
  | 'relationshipToApplicant'
  | 'gender'
> => {
  const answers = getAnswers(state);
  const questions = getQuestions(state);

  return collectRefProperties(answers, questions, personRef, {
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '',
    dateOfBirth: '',
    email: '',
    phone: '',
    maritalStatus: '',
    relationshipToApplicant: '',
    gender: '',
  });
};

export const getAddressInfo = (
  state: RootStore,
  addressRef: string,
): Pick<Address, 'city' | 'line1' | 'line2' | 'state' | 'zipcode' | 'isLocked'> => {
  const answers = getAnswers(state);
  const questions = getQuestions(state);

  return collectRefProperties(answers, questions, addressRef, {
    city: '',
    zipcode: '',
    state: '',
    line1: '',
    line2: '',
    isLocked: false,
  });
};

export const getFullAddressInfo = (
  state: RootStore,
  addressRef: string,
): Pick<Address, 'city' | 'line1' | 'line2' | 'state' | 'zipcode' | 'latitude' | 'longitude'> => {
  const answers = getAnswers(state);
  const questions = getQuestions(state);

  return collectRefProperties(answers, questions, addressRef, {
    city: '',
    zipcode: '',
    state: '',
    line1: '',
    line2: '',
    latitude: 0,
    longitude: 0,
  });
};

/**
 * selector to get driver info, it returns
 * firstName, middleName, lastName, suffix, gender, dateOfBirth, relationshipToApplicant, driverStatus, personRef, ref, driversLicense
 * @param state
 * @returns {DriverBasicInfo[]}
 */
export const getDriverInfo = (state: RootStore): DriverBasicInfo[] => {
  const driverRefs = getDriverRefs(state);
  const answers = getAnswers(state);
  const questions = getQuestions(state);

  return driverRefs.reduce((acc, d) => {
    const driverInfoProps = collectRefProperties(answers, questions, d, {
      driverStatus: '',
    });
    const personRef = String(answers[`${d}.${PERSON_REF}`]);
    const licenseNumber = answers[`${d}.license.number`];
    const licenseState = answers[`${d}.license.state`];
    const driversLicense: DriversLicense = {
      number: licenseNumber ? String(licenseNumber) : '',
      state: licenseState ? String(licenseState) : '',
    };
    if (personRef) {
      const driver = {
        ...getFullPersonInfo(state, personRef),
        personRef,
        ref: d,
        driversLicense: driversLicense,
        ...driverInfoProps,
      };

      if (driver.firstName !== '' && driver.lastName !== '' && driver.dateOfBirth !== '') {
        acc.push(driver);
      }
    }

    return acc;
  }, [] as DriverBasicInfo[]);
};

/**
 * selector to get driver info, it returns
 * firstName, middleName, lastName, suffix, gender, dateOfBirth, relationshipToApplicant, driverStatus, personRef, ref, driversLicense
 * @param state
 * @returns {Record<string, DriverBasicInfo>}
 */
export const getDriversInfoByRef = (state: RootStore): Record<string, DriverBasicInfo> =>
  Object.fromEntries(getDriverInfo(state).map((driver) => [driver.ref, driver]));

export const getVehicleAddressInfo = (
  state: RootStore,
): Pick<Address, 'city' | 'line1' | 'line2' | 'state' | 'zipcode'> => {
  return getAddressInfo(state, getVehicleAddressRef(state));
};

export const getYearBuilt = (state: RootStore): string => {
  return String(getAnswer(state, YEAR_BUILT));
};

export const getSingleOrMultiFamily = (state: RootStore): string => {
  return String(getAnswer(state, SINGLE_OR_MULTI_FAMILY));
};

export const getStateSpecificSignatureAcknowledgementMetadata = (
  metadata: BaseStateMetadataCollection,
  stateCode: string,
): BaseStateMetadataCollection => {
  const data = metadata;
  Object.keys(metadata).forEach((key) => {
    if (metadata[key].stateOptions) {
      const { stateOptions } = metadata[key];
      data[key] = { ...metadata[key], ...stateOptions?.[stateCode] };
      delete data[key].stateOptions;
    }
  });

  return data;
};

/**
 * The only field on Driver/Vehicle object we care about in the context of this function
 * if the ref string, so we can make a generic that extends the field we need
 *
 * This function will return a list of questions along with the ref for the object that question
 * was for. This way, in the component we can associate the question's value with that object
 *
 * TODO: This needs to be revisited when we enable the discounts modal. We need to make sure
 * we are using the correct discount questions/answers for editing. In SAPI v4, we are no
 * longer receiving carrier specific discount questions.
 */
export const getAvailableObjectForDriverVehicleDiscount = <T extends { ref: string }>(
  state: RootStore,
  discountKey: string,
  list: T[],
  entityKey?: string,
): { question: Question; ref: string }[] => {
  const { auto: autoOfferProduct } = getOfferProductsSelectedByType(state);
  let carrierQuestionExists = false;

  return list
    .filter((obj) => {
      carrierQuestionExists = questionExists(
        `${autoOfferProduct}.discount.${obj.ref}.${discountKey}`,
      )(state);
      if (carrierQuestionExists) return true;
      // For SAPI v4, we are removing carrier specific discount questions,
      // instead we will use the template questions on each entity

      return entityKey && questionExists(`${obj.ref.split('.')[0]}.<id>.${entityKey}`)(state);
    })
    .map((obj) => {
      const carrierQuestion = getQuestion(`${autoOfferProduct}.discount.${obj.ref}.${discountKey}`)(
        state,
      );
      const entityQuestion = getQuestion(`${obj.ref.split('.')[0]}.<id>.${entityKey}`)(state);
      let entityAnswer =
        getAnswers(state)[`${autoOfferProduct}.discount.${obj.ref}.${discountKey}`];

      // Handle airbags as a special case since this discount doesn't map to a single
      // answer value on the driver entity. Grabbing the carrier specific answer instead
      // TODO: We need to refactor discounts in UI/DAL since we are no longer receiving
      // carrier specific questions and answers from SAPI in v4. Right now, UI is still
      // dependent on receiving both carrier specific and entity based answers
      if (entityKey === 'features.safety.airbag')
        entityAnswer = getAnswers(state)[`${autoOfferProduct}.discount.${obj.ref}.${discountKey}`];
      const question = carrierQuestionExists ? carrierQuestion : entityQuestion;
      const value = carrierQuestionExists ? carrierQuestion.value : entityAnswer?.toString();

      return {
        question: {
          value,
          ...question,
        },
        ref: obj.ref,
      };
    });
};

export const getLivingArea = (state: RootStore): number | null => {
  const answers = getAnswers(state);

  return castToNumberOrNull(answers[LIVING_AREA]);
};

export const getRoofInstallOrReplace = (state: RootStore): number | null => {
  const answers = getAnswers(state);

  return castToNumberOrNull(answers[ROOF_INSTALL_OR_REPLACE]);
};

export const getCoveragePremiumAmount = (state: RootStore, coverageKey: string): string | null => {
  return ensureStringOrNull(getAnswer(state, coverageKey));
};

const getCoverageDeductibleLabelForAnswer = (answer: AnswerValue): string => {
  const answerString = ensureStringOrNull(answer) || '';
  const answerNumber = castToNumberOrNull(answer) || Number.NaN;
  if (Number.isNaN(answerNumber) && Number.isNaN(Number(answerString))) {
    return answer != null ? String(answer) : ''; // it's not a number, so convert whatever it is to string for display
  }

  return parseDollar(answerString || answerNumber, false);
};

export const getCoverageDeductibleLabel = (
  state: RootStore,
  keysProp: string | string[],
): Option['label'] => {
  const keys = Array.isArray(keysProp) ? keysProp : [keysProp];
  const key = keys.find((aKey) => getAnswer(state, aKey) != null) || keys[0];
  const question = getQuestion(key)(state);

  const answer = getAnswer(state, key);

  // If the question has a label for the answer value use it
  if (answer) {
    const questionLabel = getLabelForAnswersValue(question, answer);
    if (questionLabel) {
      return questionLabel;
    }
  }

  // no question label, so try to format as number
  if (typeof answer === 'string') {
    return answer.split('/').map(getCoverageDeductibleLabelForAnswer).join('/');
  }

  return getCoverageDeductibleLabelForAnswer(answer);
};

export const getAutoPolicyStartDate = (state: RootStore): string => {
  return String(getAnswer(state, AUTO_POLICY_START_DATE));
};

export const getAutoPolicyTerm = (state: RootStore): string => {
  return String(getAnswer(state, AUTO_POLICY_TERM));
};

const getPropertyPolicyStartDate = (state: RootStore): string => {
  const products = getEligibleProductsFromSapi(state);
  const isHome = products.find(isProductHome);
  if (isHome) {
    return getHomePolicyStartDate(state);
  } else {
    return getRentersPolicyStartDate(state);
  }
};

const getPropertyPolicyTerm = (state: RootStore): string => {
  const products = getEligibleProductsFromSapi(state);
  const isHome = products.find(isProductHome);
  if (isHome) {
    return getHomePolicyTerm(state);
  } else {
    return getRentersPolicyTerm(state);
  }
};

export const getHomePolicyTerm = (state: RootStore): string => {
  return String(getAnswer(state, HOME_POLICY_TERM));
};
export const getRentersPolicyTerm = (state: RootStore): string => {
  return String(getAnswer(state, RENTERS_POLICY_TERM));
};

export const getHomePolicyStartDate = (state: RootStore): string => {
  return String(getAnswer(state, HOME_POLICY_START_DATE));
};
export const getRentersPolicyStartDate = (state: RootStore): string => {
  return String(getAnswer(state, RENTERS_POLICY_START_DATE));
};

export const getPolicyStartDates = (state: RootStore): Record<ReducedProductName, string> => {
  return {
    auto: getAutoPolicyStartDate(state),
    property: getPropertyPolicyStartDate(state),
  };
};

export const getPolicyEfectiveDateParam = (state: RootStore): string => {
  return String(getAnswer(state, POLICY_EFFECTIVE_DATE_PARAM));
};

export const getPolicyTerm = (state: RootStore): Record<ReducedProductName, string> => {
  return {
    auto: getAutoPolicyTerm(state),
    property: getPropertyPolicyTerm(state),
  };
};

export const getProductPolicyStartDate = (state: RootStore, productName: ProductName): string => {
  return String(getAnswer(state, `policy.${productName}.effectiveDate`));
};

export const getHeatingSourceRefs = (state: RootStore): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[HEATING_SOURCE_REF]);
};

export const getSecondaryHeatingSourceRefs = (state: RootStore): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[SECONDARY_HEATING_SOURCE_REF]);
};

export const getAllHeatingSourceRefs = (state: RootStore): string[] => {
  const secondaryHeatingSourceRefs = getSecondaryHeatingSourceRefs(state);

  const hasPrimaryHeatingSystem = castToBoolean(getAnswer(state, 'property.heatingSystem'));
  const allRefs = hasPrimaryHeatingSystem
    ? ['property.heating', ...secondaryHeatingSourceRefs]
    : secondaryHeatingSourceRefs;

  return allRefs;
};

export const getBusinessOnPremisesRefs = (state: RootStore): string[] => {
  const answers = getAnswers(state);

  return ensureStringArray(answers[BUSINESS_ON_PREMISES_REF]);
};

export const getLastVisitedPage = (state: RootStore): string => {
  return String(getAnswer(state, CURRENT_PAGE));
};

export const getLastConfigVisitedPage = (state: RootStore): string => {
  return String(getAnswer(state, CURRENT_CONFIG_PAGE));
};

export const getLineOfBusinessOrder = (state: RootStore): LineOfBusinessUnbundled[] => {
  return (getAnswer(state, LOB_ORDER)?.toString().split(',') as LineOfBusinessUnbundled[]) ?? [];
};

export const getAutoPrefillFlow = (state: RootStore): PrefillFlow | undefined => {
  return getAnswer(state, 'auto.prefill.flow') as PrefillFlow | undefined;
};

export const getHomePrefillFlow = (state: RootStore): PrefillFlow | undefined => {
  return getAnswer(state, 'home.prefill.flow') as PrefillFlow | undefined;
};

// TODO Use prefill indicator from DAL workflow after it is implemented
export const getIsPrefillCompleted = (state: RootStore): boolean => {
  const lineOfBusiness = getLineOfBusiness(state);

  if (!lineOfBusiness) {
    return false;
  }

  const selectedProducts = getProductNamesFromLineOfBusiness(lineOfBusiness);

  if (selectedProducts.includes('auto') && selectedProducts.includes('home')) {
    return !!getAutoPrefillFlow(state) && !!getHomePrefillFlow(state);
  } else if (selectedProducts.includes('auto')) {
    return !!getAutoPrefillFlow(state);
  } else if (selectedProducts.includes('home')) {
    return !!getHomePrefillFlow(state);
  } else {
    return !!getAutoPrefillFlow(state) || !!getHomePrefillFlow(state);
  }
};

export const getPrefillFlows = (state: RootStore): { [key: string]: PrefillFlow | undefined } => {
  return {
    auto: getAutoPrefillFlow(state),
    home: getHomePrefillFlow(state),
  };
};

/**
 * Returns the current application flows (`long` or `short`) per each product (`auto`, `home`, etc.)
 * accounting for all external variables like Feature Flags which might override the default flow values.
 */
export const getCurrentFlows = (state: RootStore): { [key: string]: PrefillFlow | undefined } => {
  // const allowPrefillShortFlowAuto = flagValues[FeatureFlags.ALLOW_PREFILL_SHORT_FLOW_AUTO];
  // const forceLongFlowHome = getFeatureFlagValue(FeatureFlags.FORCE_LONG_FLOW_HOME) === 'on';

  return {
    // auto: allowPrefillShortFlowAuto ? getAutoPrefillFlow(state) : PrefillFlow.LONG,
    // home: forceLongFlowHome ? PrefillFlow.LONG : getHomePrefillFlow(state),
    auto: getAutoPrefillFlow(state),
    home: getHomePrefillFlow(state),
  };
};

export const getCustomerId = (state: RootStore): string | undefined => {
  return getAnswer(state, 'customerId') as string | undefined;
};

export const getAgentId = (state: RootStore): string | undefined => {
  return getAnswer(state, 'agent.agentId') as string | undefined;
};

export const getAgentPartnerProducerId = (state: RootStore): string | undefined => {
  return getAnswer(state, 'agent.partnerProducerId') as string | undefined;
};

// Call this to generate a delta field name
// you must provide the intended product, as delta flows can be for both auto, home, renters etc.
// (FOR NOW only 'auto' works, and definitely only works when in v3)
// ref: should be 'person.123' or if a coverageOption field, it should be `coverageOption.${product}`
// (NOTE that when on V3 this product will be stripped)
// If you are making a v4-only component, you may pass in 'v4-only' as the product.
/*
 * @param product pass 'v4-only' if writing components which will only target v4
 * @param ref pass 'root' to access fields directly attached to the delta namespace
 */
export const getDeltaField = (state: RootStore, ref: string, fieldName?: string): string => {
  const sapiTarget = getSapiTarget(state);
  switch (sapiTarget) {
    case 'v3': {
      // in v3 we must prefix with the auto product, always (no other policy types are supported).
      const { auto: autoOfferProduct } = getOfferProductsSelectedByType(state);

      if (!autoOfferProduct) {
        datadogLog({
          logType: 'error',
          message: 'no auto product available',
          context: {
            logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/selectors.ts',
            functionOrigin: 'getDeltaField',
          },
        });

        throw new Error('no auto product available');
      }

      if (ref === 'root') return `${autoOfferProduct}.delta.${fieldName}`;

      // v3 naming:
      // "amfam-adv.renters.delta.coverageOption.paperlessemail"
      // "amfam-adv.renters.delta.vehicle.1234.vin"
      // i.e. {autoOfferProduct}.delta.{ref}.{fieldName}
      // with the exception that "coverageOption" doesn't restate the product
      // that is, when a coverageOption field, the ref should be "coverageOption.amfam-adv.auto"
      const fixedRef = ref.startsWith('coverageOption.') ? 'coverageOption' : ref;

      return `${autoOfferProduct}.delta.${fixedRef}.${fieldName}`;
    }
    case 'v4': {
      // v4 naming:
      // "delta.coverageOption.amfam-adv.auto.paperlessemail"
      // "delta.vehicle.1234.vin"
      // i.e. delta.{ref}.{fieldName}
      // where coverageOption is passed as if it were a ref, "coverageOption.amfam-adv.auto"

      if (ref === 'root') return `delta.${fieldName}`;

      return `delta.${ref}.${fieldName}`;
    }
    default: {
      datadogLog({
        logType: 'error',
        message: 'unknown sapi target',
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/selectors.ts',
          functionOrigin: 'getDeltaField',
        },
      });

      throw new Error('unknown sapi target');
    }
  }
};

export const getAvailableVehicleFieldKeys = (
  state: RootStore,
  vehicleRef: string,
  metadata: QuestionsMetadata,
): string[] => {
  return Object.keys(metadata).filter((key) =>
    questionExists(getDeltaField(state, vehicleRef, key))(state),
  );
};

// CSUI-1068
export const getAutoDeltaQuestionExists = createSelector(getQuestions, (sapiQuestions): boolean => {
  if (sapiQuestions) {
    const allQuestionKeys = Object.keys(sapiQuestions);
    // filter only questions with delta keys in them
    // this supports V3 or V4 naming
    // (\b means start of string or previous character is not \w)
    const excludedQuestions = allQuestionKeys.filter((key) => /\bdelta\./.test(key));

    return excludedQuestions.length > 0;
  }

  return false;
});

export const getPartnerAccountNumber = (state: RootStore): string => {
  return String(getAnswer(state, 'partner.amfAccountNumber'));
};

export const getPartnerChannel = (state: RootStore): Channel => {
  return getAnswer(state, PARTNER_CHANNEL) as Channel;
};

/** @deprecated Don't pull information from answers but from the form instead */
export const getPrimaryInsuredPersonInfo = (
  state: RootStore,
): Pick<Person, 'firstName' | 'lastName' | 'middleName' | 'suffix' | 'dateOfBirth'> => {
  const answers = getAnswers(state);
  const pniPersonRef = answers[`${PRIMARY_INSURED_PERSON_REF}`];
  const personInfo = pniPersonRef
    ? getPersonInfo(state, pniPersonRef.toString())
    : { firstName: '', lastName: '', middleName: '', suffix: '', dateOfBirth: '' };

  return personInfo;
};

export const getUserSelection = (state: RootStore): string | null => {
  return ensureStringOrNull(getAnswer(state, USER_SELECTION));
};

export const getPageFlowParams = createSelector(
  getLineOfBusiness,
  getAutoPrefillFlow,
  getHomePrefillFlow,
  getAnswers,
  questionExists('property.marketValue'),
  (
    lineOfBusiness: LineOfBusiness,
    autoPrefillFlow: PrefillFlow | undefined,
    homePrefillFlow: PrefillFlow | undefined,
    answers: Answers,
    marketValueQuestionExists,
  ) => {
    const { recentLifeEvent, residencyType } = answers;
    const hasMarketValueQuestion = marketValueQuestionExists;

    return {
      lineOfBusiness,
      hasRecentLifeEvent: recentLifeEvent as boolean,
      hasResidencyOwnership: residencyType === 'RESIDENCY_TYPE.OWN',
      autoPrefillFlow,
      homePrefillFlow,
      hasMarketValueQuestion,
    };
  },
);

export const getProductFromQueryString = (state: RootStore): string | null => {
  return ensureStringOrNull(getAnswer(state, 'analytics.product'));
};

export const getZipFromQueryString = (state: RootStore): string | null => {
  return ensureStringOrNull(getAnswer(state, 'analytics.zip'));
};

export const getLexisNexisOptInAnswer = (state: RootStore): boolean => {
  return castToBoolean(getAnswer(state, LEXIS_NEXIS_CALL));
};

export const getLexisNexisTriggerAnswer = (state: RootStore): boolean => {
  return castToBoolean(getAnswer(state, LEXIS_NEXIS_TRIGGER));
};

export const getBridgeType = (state: RootStore): string => {
  return String(getAnswer(state, 'static.bridgeType'));
};

export const getHeatingSource = (state: RootStore): boolean => {
  return Boolean(getAnswer(state, 'static.hasHeatingSource'));
};

export const getHasAnyQuoteExpired =
  (businessTypes: ProductName[]) =>
  (state: RootStore): boolean =>
    businessTypes.some((x) => state.inquiry.answers[`policy.${x}.expiredQuoteIndicator`]);

export const getIsCreatingInquiry = (state: RootStore): boolean => {
  return state.inquiry.creatingInquiry;
};

export const getMembershipTier = (state: RootStore): CostcoMembershipTier | undefined => {
  return getAnswer(state, COSTCO_MEMBERSHIP_TIER) as CostcoMembershipTier | undefined;
};

export const getSelectedPaymentPlan = (state: RootStore): PaymentPlan => {
  return getAnswer(state, SELECTED_PAYMENT_PLAN) as PaymentPlan;
};

export const getLobAvailability =
  (zipCodeFieldErrors: Field['errors']) =>
  (
    state: RootStore,
  ): {
    availableLob: LineOfBusinessUnbundled | null;
    unavailableLob: LineOfBusinessUnbundled[] | null;
  } => {
    const result: ReturnType<ReturnType<typeof getLobAvailability>> = {
      availableLob: null,
      unavailableLob: null,
    };
    const firstError = zipCodeFieldErrors[0];
    if (!firstError) return result;

    const lobOrder = getLineOfBusinessOrder(state);

    switch (firstError) {
      case LOB_AUTO_NOT_ELIGIBLE_MSG:
        result.unavailableLob = [LineOfBusiness.AUTO];
        break;
      case LOB_HOME_NOT_ELIGIBLE_MSG:
        result.unavailableLob = [LineOfBusiness.HOME];
        break;
      case LOB_RENTERS_NOT_ELIGIBLE_MSG:
        result.unavailableLob = [LineOfBusiness.RENTERS];
        break;
      case LOB_BUNDLE_NOT_ELIGIBLE_MSG:
        result.unavailableLob = lobOrder;
        break;
      default:
        break;
    }

    if (!result.unavailableLob) return result;

    const availableLobs = lobOrder.filter((product) => !result.unavailableLob?.includes(product));
    result.availableLob = availableLobs[0] || null;

    return result;
  };

export const getNoOfMortgages = (state: RootStore): number => {
  return Number(getAnswer(state, NUMBER_OF_MORTGAGES));
};

export const getHasLossesInThePastFiveYears = (state: RootStore, key: string): boolean => {
  return getAnswer(state, key) as boolean;
};

export const getHasUserStartedInEmbeddedModule = (state: RootStore): boolean => {
  const userStartedInEM = getAnswer(state, HAS_USER_STARTED_IN_EM);

  return userStartedInEM as boolean;
};

export const getLobOrder = (
  state: RootStore,
  lob: LineOfBusiness,
  mergeWithExistingLobOrder?: boolean,
): LineOfBusiness[] => {
  let lobOrder = [lob];

  if (isLineOfBusinessBundle(lob)) {
    const productNames = getProductNamesFromLineOfBusiness(lob);
    const configOrder = productsToLobs(
      getProductOrder().filter((product) => productNames.includes(product)),
    );

    const existingOrder = mergeWithExistingLobOrder ? getLineOfBusinessOrder(state) : [];
    lobOrder = unique([...existingOrder, ...configOrder]);
  }

  return lobOrder;
};

export const useQuoteNumber = (lob: ProductName): AnswerValue => {
  const allAnswers = useSelector(getAnswers);

  return allAnswers[`policy.${lob}.quoteNumber`];
};
