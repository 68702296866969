import { env } from '@ecp/env';
import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<{ forceSwipeable: boolean }>({ name: 'Page' })(
  (...[theme, { forceSwipeable }]) => ({
    root: {
      ...theme.mixins.pageRoot,
      position: 'relative',
      minHeight: '81vh',
      display: 'flex',
      flexDirection: 'column',
    },
    gradient: {
      backgroundImage: `linear-gradient(${theme.palette.primary.main} -250%, transparent 50%)`,
    },
    bottom: {
      display: 'flex',
      flex: '1 0 auto',
      '&:focus': {
        outline: 'none',
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: theme.appVars.maxPageWidth,
      },
      [theme.breakpoints.only('lg')]: {
        maxWidth: theme.breakpoints.values.lg,
      },
      [theme.breakpoints.down('xl')]: {
        marginBottom: 0,
      },
    },
    bottomWithSidebar: {
      flexDirection: forceSwipeable ? 'column' : undefined,

      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
      ...(env.static.isAgent && {
        maxWidth: theme.appVars.maxPageWidth,
        marginLeft: 0,
      }),
    },
    main: {
      position: 'relative',
      width: '100%',
      margin: '0 auto',
      [theme.breakpoints.only('md')]: {
        maxWidth: theme.breakpoints.values.md,
      },
    },
    mainWithSidebar: {
      ...(env.static.isAgent && {
        [theme.breakpoints.up('lg')]: {
          marginLeft: 0,
          width: 'unset',
          maxWidth: theme.appVars.maxPageWidth,
        },
      }),
    },
    mainWithoutSidebar: {
      [theme.breakpoints.up('xl')]: {
        maxWidth: 810,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        maxWidth: 768,
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        maxWidth: 700,
      },
    },
    navbarContainer: {
      ...(env.static.isAgent
        ? { height: 'auto', backgroundColor: theme.palette.primary.background }
        : {
            alignSelf: 'flex-start',
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('lg')]: {
              margin: '40px 0px 0px 15px',
              width: '37%',
            },
          }),
      ...(forceSwipeable && {
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          margin: 0,
          width: '100%',
        },
      }),
    },
    spacer: {
      height: 40,
      [theme.breakpoints.down('lg')]: {
        height: 24,
      },
    },
    wrapperContainer: {
      position: 'relative',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);
