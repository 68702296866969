import {
  castAnswerType,
  emptyArray,
  emptyObject,
  isEqual,
  isTruthy,
  parseDollar,
} from '@ecp/utils/common';
import { isDateExpired } from '@ecp/utils/date';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import type { CheckoutData, CheckoutOffers } from '@ecp/features/sales/checkout';
import {
  DECLINATION_NOTICE_DISPLAYED_PAGE_PATH,
  DEFAULT_AUTO_POLICY_DURATION,
  DEFAULT_HOME_POLICY_DURATION,
  OFFER_PRODUCTS_SELECTED,
  OfferStatusCode,
  PrefillFlow,
  PREMIUM_TYPE_BUNDLED,
  PREMIUM_TYPE_UNBUNDLED,
  SAPI_ELIGIBLE_PRODUCTS,
} from '@ecp/features/sales/shared/constants';
import type { PagePath } from '@ecp/features/sales/shared/routing';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { createDeepSelector } from '@ecp/features/sales/shared/store/utils';
import type { CarrierMessagingType } from '@ecp/features/sales/shared/types';
import type {
  AutoProduct,
  Product,
  ProductName,
  PropertyProduct,
} from '@ecp/features/shared/product';
import {
  getCarrierDisplayName,
  getCarrierNameFromProduct,
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
  getReducedProductNameFromProduct,
  getReducedProductNamesFromLob,
  isLineOfBusinessBundle,
  isProductAuto,
  isProductAvailable,
  isProductHome,
  isProductProperty,
  isProductRenters,
  LineOfBusiness,
  productMatchesLOB,
} from '@ecp/features/shared/product';
import type { BaseMetadataCollection } from '@ecp/types';

import { isAnyApiInProgress } from '../api/selectors';
import {
  getAnswer,
  getAnswers,
  getCoverageDeductibleLabel,
  getLineOfBusiness,
  getPartnerExperienceId,
  getPolicyStartDates,
  getPrefillFlows,
  getProducts,
  getSelectedPaymentPlan,
  getUserSelection,
} from '../inquiry/selectors';
import metadata from './metadata';
import type {
  CnqDnqProductData,
  Dependencies,
  Offer,
  OfferDetails,
  OfferErrorDetails,
  OfferInfo,
  Offers,
  Premium,
  PremiumType,
  QuoteSummaryOffers,
} from './types';
import {
  createQuoteSummaryOffers,
  filterAnswersDependenciesForNewOffers,
  isFullPayOnly,
  isOfferBindable,
  isOfferLocked,
  isOfferOk,
  isOfferPurchased,
  isOfferRVP,
  isOfferValid,
} from './util';

export const CREATE_OFFERS_ID_PREFIX = 'offers-create';
export const UPDATE_OFFERS_ID_PREFIX = 'offers-update';
export const FETCH_OFFERS_ID_PREFIX = 'offers-fetch';

export const getOfferSetId = (state: RootStore): string | undefined => state.offers.offerSetId;

export const getOffersInquiryId = (state: RootStore): string | undefined => state.offers.inquiryId;

export const getOfferQuoteNumber = (state: RootStore, product?: Product): string | undefined =>
  product && state.offers?.offers[product]?.details?.quoteNumber;

export const getOffersDalSessionId = (state: RootStore): string | undefined =>
  state.offers.dalSessionId;

export const getOffers = (state: RootStore): Offers => state.offers.offers;
export const getOffersExist = (state: RootStore): boolean =>
  !!(state.offers.offers && Object.keys(state.offers.offers).length);

export const getRecalculateValue = (state: RootStore): boolean => state.offers.recalculate;

// Will hold the value of recalc in process so coverages page can display it's loading state.
export const getRecalcProcessingValue = (state: RootStore): boolean =>
  state.offers.recalcProcessing;

export const getOffer = (state: RootStore, productKey: Product): Offer | undefined =>
  getOffers(state)[productKey];

export const getCreateOffersInProgress = (state: RootStore): boolean =>
  isAnyApiInProgress(state, CREATE_OFFERS_ID_PREFIX);

export const getUpdateOffersInProgress = (state: RootStore): boolean =>
  isAnyApiInProgress(state, UPDATE_OFFERS_ID_PREFIX);

export const getQuoteNumber = (state: RootStore, productKey: Product): string =>
  getOffer(state, productKey)?.details.quoteNumber || '';

export const getCarrierMessagingKeys = (state: RootStore, productKey: Product): string[] =>
  getOffer(state, productKey)?.details?.disclosures?.filter((disclosure) => disclosure) || [];

export const getRecalled = (state: RootStore): boolean => state.offers.recalled;

export const getRecallErrorMessage = (state: RootStore): string | undefined =>
  state.offers.recallError;

export const getBlobRetrieveFailed = (state: RootStore): boolean => state.offers.blobRetrieveFailed;

const filterMetadata = (
  metadata: BaseMetadataCollection,
  state: RootStore,
  productKey: Product,
): BaseMetadataCollection => {
  return Object.keys(metadata)
    .filter((covKey) => {
      const keysProp = `${productKey}.${covKey}`;
      const keys = Array.isArray(keysProp) ? keysProp : [keysProp];
      const key = keys.find((aKey) => getAnswer(state, aKey) != null) || keys[0];
      const answer = getAnswer(state, key);

      return typeof answer !== 'undefined';
    })
    .reduce((obj, key) => {
      obj[key] = metadata[key];

      return obj;
    }, {} as BaseMetadataCollection);
};

const getCoveragesDeductiblesForQuoteSummary = (
  state: RootStore,
  productKey: Product,
): { [key: string]: string } => {
  const quoteSummaryMetadata = metadata.QuoteSummaryMetadata[productKey];
  // We only want to show the metadata for which we have answers for
  const filteredMetadata = filterMetadata(quoteSummaryMetadata, state, productKey);

  return Object.assign(
    {},
    ...Object.entries(filteredMetadata).map(([covKey, covMeta]) => ({
      [covMeta.title]: getCoverageDeductibleLabel(state, `${productKey}.${covKey}`),
    })),
  );
};

const getTopCoverages = (state: RootStore, productKey: Product): { [key: string]: string } => {
  const topCoveragesMetadata = metadata.TopCoveragesMetadata[productKey];
  // We only want to show the metadata for which we have answers for
  const filteredMetadata = filterMetadata(topCoveragesMetadata, state, productKey);

  return Object.assign(
    {},
    ...Object.entries(filteredMetadata).map(([covKey, covMeta]) => ({
      [covMeta.title]: getCoverageDeductibleLabel(state, `${productKey}.${covKey}`),
    })),
  );
};

export const getCheckoutPolicySummaryKeys = (
  state: RootStore,
  productKey: Product,
): { [key: string]: string } => {
  const policySummaryMetadata = metadata.PolicySummaryMetadata[productKey];

  if (policySummaryMetadata) {
    const filteredMetadata = filterMetadata(policySummaryMetadata, state, productKey);

    if (filteredMetadata) {
      return Object.assign(
        {},
        ...Object.entries(filteredMetadata).map(([covKey, covMeta]) => ({
          [covMeta.title]: getCoverageDeductibleLabel(state, `${productKey}.${covKey}`),
        })),
      );
    }
  }

  return emptyObject;
};

export const getCoverageSummaryMetadata = (
  state: RootStore,
  productKey: Product,
): { [key: string]: string } => {
  const policySummaryMetadata = metadata.PolicySummaryMetadata[productKey];

  if (policySummaryMetadata) {
    const filteredMetadata = filterMetadata(policySummaryMetadata, state, productKey);

    if (filteredMetadata) {
      return Object.assign(
        {},
        ...Object.entries(filteredMetadata).map(([covKey, covMeta]) => ({
          [covMeta.title]: getCoverageDeductibleLabel(state, `${productKey}.${covKey}`),
        })),
      );
    }
  }

  return emptyObject;
};

const MVR_REJECTION_REASONS = new Set([
  'PL400048',
  'PL400049',
  'INCORRECT_DL_INFO',
  'DMV_UNAVAILABLE',
]);

/**
 * Applies only to auto product if auto monoline or bundle with auto is selected.
 * If auto is Long Flow:
 * - check if auto offer messages array has a particular message id enum.
 *
 * If auto is Short Flow:
 * - check if eligibility.statusCode is QNB
 * - if eligibility.reasons array has a particular message id enum.
 *
 * NOTE: eligibility.reasons is used over eligibility.reason as reason is a string
 * and can possibly be concatenated with other values.
 */
export const getIsMVRActive = (state: RootStore): boolean => {
  const { auto: autoOfferProduct } = getOfferProductsSelectedByType(state);
  if (!autoOfferProduct) return true;
  const offer = getOffer(state, autoOfferProduct);
  const userSelection = getUserSelection(state);
  if (!offer || !isOfferValid(offer, !!userSelection)) return true;
  const { auto: autoPrefillFlow } = getPrefillFlows(state);
  if (autoPrefillFlow === PrefillFlow.LONG)
    return !offer.messages?.some((message) => MVR_REJECTION_REASONS.has(message.id));

  return !(
    offer.eligibility.statusCode === OfferStatusCode.QNB &&
    offer.eligibility.reasons?.some((reason) => MVR_REJECTION_REASONS.has(reason.code))
  );
};

/** Returns bundled/unbundled premium based on bundle array */
const getPremiumRaw = (
  _state: RootStore,
  premiums: Premium[],
  _productKey: Product,
  displayPremiumType: PremiumType,
): Premium | null => {
  const realTimeCreditCardPayment = flagValues[FeatureFlags.REAL_TIME_CREDIT_CARD_PAYMENT];
  const autoProduct = isProductAuto(_productKey);
  switch (displayPremiumType) {
    case PREMIUM_TYPE_BUNDLED:
      if (realTimeCreditCardPayment && autoProduct) {
        return (
          premiums.find((p) => p.paymentType === 'CreditCard' && (p.bundle?.length ?? 0) > 0) ||
          null
        );
      } else {
        return premiums.find((p) => (p.bundle?.length ?? 0) > 0) || null;
      }
    case PREMIUM_TYPE_UNBUNDLED:
      if (realTimeCreditCardPayment && autoProduct) {
        return (
          premiums.find(
            (p) => p.paymentType === 'CreditCard' && (!p.bundle || p.bundle.length === 0),
          ) || null
        );
      } else {
        return premiums.find((p) => !p.bundle || p.bundle.length === 0) || null;
      }

    default:
      return null;
  }
};

const getPremium = (
  state: RootStore,
  premiums: Premium[],
  productKey: Product,
  displayPremiumType: PremiumType,
): Premium | null => {
  return getPremiumRaw(state, premiums, productKey, displayPremiumType);
};

/** Returns offer details for the product */
export const getOfferDetailsForProduct = (
  state: RootStore,
  product?: Product,
  isBundle?: boolean,
): OfferInfo | null => {
  if (!product || !isProductAvailable(product)) {
    return null;
  }
  const userSelection = getUserSelection(state);
  const offer = getOffer(state, product);
  if (!offer || !isOfferValid(offer, !!userSelection)) {
    return null;
  }
  const isBundleForOfferProductsSelected = getIsBundleForOfferProductsSelected(state);
  const isBundledPremium = isBundle ?? isBundleForOfferProductsSelected;
  const premiumType = isBundledPremium ? PREMIUM_TYPE_BUNDLED : PREMIUM_TYPE_UNBUNDLED;
  const fullPremiums = offer.premiums.filter((p) => p.paymentPlan === 'Full');
  const monthlyPremiums = offer.premiums.filter((p) => p.paymentPlan !== 'Full');
  const fullPremium = getPremium(state, fullPremiums, product, premiumType);
  const monthlyPremium = getPremium(state, monthlyPremiums, product, premiumType);

  // policyDuration is dependent on lob, use duration sent by carrier if available
  const defaultPolicyDuration = isProductAuto(product)
    ? DEFAULT_AUTO_POLICY_DURATION
    : DEFAULT_HOME_POLICY_DURATION;
  const policyDuration = offer.details.offerKeys?.policyDurationMonths ?? defaultPolicyDuration;

  const retrieveLinks = offer.links
    ? offer.links.filter((link) => link.rel.toLowerCase() === 'retrieve')
    : [];

  const isMVRActive = getIsMVRActive(state);

  const isRVP = isOfferRVP(offer);

  const isEnrolledTelematics = offer.messages?.some(
    (message) =>
      message.id === 'TELEMATICS_SCORE_SUCCESS' || message.id === 'TELEMATICS_SCORE_INSUFFICIENT',
  );

  return {
    offerId: offer.offerId,
    fullPremium: fullPremium as Premium,
    monthlyPremium,
    policyDuration,
    // If not bindable or MVR/CBR is not available, the offer has been estimated
    isEstimated: !offer.eligibility.bindable || !isMVRActive,
    isQuoteNoBind: offer.eligibility.statusCode === OfferStatusCode.QNB,
    isPurchased: offer.eligibility.statusCode === OfferStatusCode.PURCHASED,
    isLocked: offer.eligibility.statusCode === OfferStatusCode.LOCKED,
    isMVRActive,
    isEnrolledTelematics,
    isRVP,
    product: offer.product,
    retrieveLink: retrieveLinks.length > 0 ? retrieveLinks[0].href : '',
    coverages: getCoveragesDeductiblesForQuoteSummary(state, product),
    checkoutPolicyKeys: getCheckoutPolicySummaryKeys(state, product),
    coveragePolicyKeys: getCoverageSummaryMetadata(state, product),
    details: offer.details,
    topCoverages: getTopCoverages(state, product),
  };
};

export const getOfferSummaryForProduct = (
  state: RootStore,
  product: Product | undefined,
  isBundle: boolean,
): OfferDetails | null => {
  if (!product) return null;

  const offerDetails = getOfferDetailsForProduct(state, product, isBundle);
  if (!offerDetails) return null;

  return { [product]: offerDetails };
};

// Get offer details for selected line of business to be shown on quote summary page
export const getOffersForSelectedLob = (state: RootStore): QuoteSummaryOffers | null => {
  const offers = getOffers(state);
  const eligibleProductsFromSapi = getEligibleProductsFromSapi(state);
  const userSelection = getUserSelection(state);
  const offerProducts = Object.keys(offers).filter(
    (product) =>
      isOfferValid(offers[product], !!userSelection) && eligibleProductsFromSapi.includes(product),
  );

  // For monoline, SAPI would return a single offer (applicable for auto, home, renters etc.)
  if (offerProducts.length === 1) {
    return createQuoteSummaryOffers({
      [getProductNameFromProduct(offerProducts[0])]: getOfferSummaryForProduct(
        state,
        offerProducts[0],
        false,
      ),
    });
  } else if (offerProducts.length === 2) {
    // For bundle, the offer summary should have a monoline as well as bundle summary for each product
    return offerProducts.reduce((accumulator, currentProduct) => {
      return {
        ...accumulator,
        // Monoline version
        [getProductNameFromProduct(currentProduct)]: getOfferSummaryForProduct(
          state,
          currentProduct,
          false,
        ),
        bundle: {
          ...accumulator.bundle,
          // bundled version
          ...getOfferSummaryForProduct(state, currentProduct, true),
        },
      };
      // Start with a default quote summary offers object
    }, createQuoteSummaryOffers({}));
  }

  return null;
};

export const getEligibleProductsFromSapi = (state: RootStore): Product[] => {
  const eligibleProducts = getAnswer(state, SAPI_ELIGIBLE_PRODUCTS);
  const result = castAnswerType(eligibleProducts, 'List') as Product[] | null | undefined;
  if (result?.length) return result;

  const expId = getPartnerExperienceId(state);

  return (
    env.static.eligibleProducts ||
    (env.static.isAgent && env.eligibleProducts?.[expId]) ||
    (emptyArray as unknown as Product[])
  );
};

export const getDeclinationNoticeDisplayedPagePath = (state: RootStore): PagePath | null => {
  const answerValue = getAnswer(state, DECLINATION_NOTICE_DISPLAYED_PAGE_PATH);

  return answerValue as PagePath | null;
};

export const getOfferProductsSelected = createDeepSelector(
  (state: RootStore) => getAnswer(state, OFFER_PRODUCTS_SELECTED),
  getEligibleProductsFromSapi,
  (offerProductsSelected, eligibleProductsFromSapi) => {
    const result = castAnswerType(offerProductsSelected, 'List') || eligibleProductsFromSapi;

    return result as unknown as Product[];
  },
);

export const getOfferProductsSelectedByType = (
  state: RootStore,
): { auto?: AutoProduct; property?: PropertyProduct } => {
  // TODO We should be using getOfferProductsSelected or whatever was a replacement for setOfferProductsSelected (product.userSelection answer)
  const offerProductsSelected = getOfferProductsSelected(state);
  const eligibleProductsFromSapi = getEligibleProductsFromSapi(state);
  const products = offerProductsSelected.length ? offerProductsSelected : eligibleProductsFromSapi;

  const auto = products.find(isProductAuto);
  const property = products.find(isProductProperty);

  return {
    ...(auto && { auto }),
    ...(property && { property }),
  };
};

export const getLobForOfferProductsSelected = (state: RootStore): LineOfBusiness => {
  const offerProductsSelected = getOfferProductsSelected(state);
  if (offerProductsSelected.length > 1) {
    if (offerProductsSelected.some(isProductHome)) return LineOfBusiness.BUNDLE;
    if (offerProductsSelected.some(isProductRenters)) return LineOfBusiness.BUNDLE_AUTO_RENTERS;
  }
  if (offerProductsSelected.some(isProductHome)) return LineOfBusiness.HOME;
  if (offerProductsSelected.some(isProductRenters)) return LineOfBusiness.RENTERS;

  return LineOfBusiness.AUTO;
};

export const getIsBundleForOfferProductsSelected = (state: RootStore): boolean => {
  const offerProductsSelected = getOfferProductsSelected(state);

  return offerProductsSelected.length > 1;
};

export const getSapiAnalyticsSelectedOfferEventDetail = (state: RootStore): string => {
  const offerProductsSelected = getOfferProductsSelected(state);
  const premiumPlan = getSelectedPaymentPlan(state);

  return offerProductsSelected
    .map((product) => {
      const offerDetails = getOfferDetailsForProduct(state, product);

      return offerDetails
        ? `product:${product}, offerId:${offerDetails.offerId}, premiumType:${offerDetails[premiumPlan]?.premiumType}, premiumAmount:${offerDetails[premiumPlan]?.totalPremium}, quoteNumber:${offerDetails.details.quoteNumber}`
        : '';
    })
    .filter(isTruthy)
    .join(', ');
};

export const getProductsForLOB = (state: RootStore, selectedLob?: LineOfBusiness): Product[] => {
  const lineOfBusiness = selectedLob ?? getLineOfBusiness(state);
  const allProducts = getProducts(state);

  return allProducts.filter((product) => productMatchesLOB(lineOfBusiness, product));
};

export const getErrorDetailsForOffers = (state: RootStore): OfferErrorDetails[] => {
  const userSelection = getUserSelection(state);
  const eligibleProductsFromSapi = getEligibleProductsFromSapi(state);
  const offerProductsSelected = getOfferProductsSelected(state);
  const offerProducts =
    userSelection &&
    !isLineOfBusinessBundle(getLineOfBusiness(state)) && // ECP-3848
    offerProductsSelected.length > 0
      ? offerProductsSelected
      : eligibleProductsFromSapi;

  const errorDetails = offerProducts.reduce((acc, product) => {
    const offer = getOffer(state, product);
    // QNB is considered errored offer after user selection but valid offer before it
    const isErroredOffer = userSelection
      ? !isOfferOk(offer) && !isOfferLocked(offer)
      : !isOfferValid(offer, !!userSelection);
    if (offer && isErroredOffer) {
      const errorReasons = offer.eligibility.reasons?.map(({ message }) => message) || [];
      acc.push({
        product: offer.product,
        reasons: errorReasons,
      });
    }

    return acc;
  }, [] as OfferErrorDetails[]);

  return errorDetails;
};

export const getErroredProductsFromOffers = (state: RootStore): Product[] => {
  const erroredOfferDetails = getErrorDetailsForOffers(state);
  const erroredProducts = erroredOfferDetails.map((offer) => offer.product);

  return erroredProducts;
};

export const getCarrierMessagingMetadata = (
  state: RootStore,
  stateCode: string,
  carrierMessagingMetadata:
    | Partial<Record<Product, Record<string, CarrierMessagingType>>>
    | undefined,
): Record<ProductName, CarrierMessagingType[]> => {
  const offerProductsSelected = getOfferProductsSelected(state);

  return offerProductsSelected.reduce((acc, product) => {
    const productName = getProductNameFromProduct(product);

    acc[productName] = getCarrierMessagingKeys(state, product)
      .map((messagingKey) => {
        const message = carrierMessagingMetadata?.[product]?.[messagingKey];
        if (!message) return null;
        if (stateCode && message.stateOptions?.[stateCode]) {
          return { ...message, ...message.stateOptions[stateCode] };
        }

        return message;
      })
      .filter(isTruthy);

    return acc;
  }, {} as Record<ProductName, CarrierMessagingType[]>);
};

export const getCnqDndProducts = (state: RootStore): CnqDnqProductData[] => {
  const allOffers = getOffers(state);
  const productDataList = [];
  const offerCodeDisplayNames = {
    [OfferStatusCode.DNQ]: 'Do Not Quote',
    [OfferStatusCode.CNQ]: 'Can Not Quote',
  };

  for (const offer of Object.values(allOffers)) {
    if (offer) {
      const { eligibility, product } = offer;
      const { reasons = [], statusCode: offerStatusCode } = eligibility || {};
      if (offerStatusCode === OfferStatusCode.CNQ || offerStatusCode === OfferStatusCode.DNQ) {
        const carrierName = getCarrierNameFromProduct(product);
        const carrierDisplayName = getCarrierDisplayName(carrierName);
        const productName = getProductNameFromProduct(product);
        const productDisplayName = getProductDisplayNameFromProduct(product);

        productDataList.push({
          isBundle: false,
          name: productName,
          displayName: productDisplayName,
          offerStatusCode,
          quoteDetails: [
            {
              description: `${productDisplayName} quote ${offerStatusCode} `,
              value: `(${offerCodeDisplayNames[offerStatusCode]})`,
            },
            {
              description: '',
              value: '',
            },
            ...reasons.map((reason) => ({
              description: `${carrierDisplayName} ${productDisplayName}:`,
              value: `${
                reason.code in metadata.RetrieveQuoteErrorMetadata
                  ? `${reason.code} - ${metadata.RetrieveQuoteErrorMetadata[reason.code]}`
                  : reason.message
              }`,
            })),
            {
              description: '',
              value: '',
            },
            {
              description: 'Inquiry-id: ',
              value: getOffersInquiryId(state) as string,
            },
            {
              description: 'Offer-Set-id: ',
              value: getOfferSetId(state) as string,
            },
          ],
        });
      }
    }
  }

  return productDataList;
};

export const getIsProductOfferPurchased = (state: RootStore, product: Product): boolean => {
  const offers = getOffers(state);

  return Object.keys(offers).some((item) => item === product && isOfferPurchased(offers[product]));
};

export const getIsProductOfferLocked = (state: RootStore, product: Product): boolean => {
  const offers = getOffers(state);

  return Object.keys(offers).some((item) => item === product && isOfferLocked(offers[product]));
};

export const getIsProductOfferBindable = (state: RootStore, product: Product): boolean => {
  const offers = getOffers(state);

  return Object.keys(offers).some((item) => item === product && isOfferBindable(offers[product]));
};

export const getAreSomeSelectedProductsIndicative = (state: RootStore): boolean => {
  const offerProductsSelected = getOfferProductsSelected(state);

  return offerProductsSelected.some((product) => !getIsProductOfferBindable(state, product));
};

// TODO: Add to Checkout Page to replace the offers const
export const getCheckoutPageDataFromOffers = (state: RootStore): CheckoutOffers => {
  const { auto: autoOfferProduct, property: propertyOfferProduct } =
    getOfferProductsSelectedByType(state);

  const autoOfferDetails = getOfferDetailsForProduct(state, autoOfferProduct);

  const { auto: autoPolicyStartDate, property: propertyPolicyStartDate } =
    getPolicyStartDates(state);

  const propertyOfferDetails = getOfferDetailsForProduct(state, propertyOfferProduct);

  return {
    ...(autoOfferProduct &&
      autoOfferDetails && {
        auto: {
          selectedProduct: autoOfferProduct,
          offer: autoOfferDetails,
          policyStartDate: autoPolicyStartDate,
        },
      }),
    ...(propertyOfferProduct &&
      propertyOfferDetails && {
        property: {
          selectedProduct: propertyOfferProduct,
          offer: propertyOfferDetails,
          policyStartDate: propertyPolicyStartDate,
        },
      }),
  };
};

export const getCheckoutDataFromOffers = (state: RootStore): CheckoutData => {
  const premiumPlan = getSelectedPaymentPlan(state);
  const offerProductsSelected = getOfferProductsSelected(state);

  return offerProductsSelected.reduce((acc, product) => {
    const productName = getProductNameFromProduct(product);
    const reducedProductName = getReducedProductNameFromProduct(product);
    const offerDetails = getOfferDetailsForProduct(state, product);

    if (offerDetails)
      acc[reducedProductName] = {
        premiumAmount: parseDollar(offerDetails[premiumPlan]?.totalPremium),
        productName,
        policyDuration: offerDetails.policyDuration,
        stateFeeItems: offerDetails[premiumPlan]?.stateFeeItems,
        surcharges: offerDetails[premiumPlan]?.surcharges,
        stateFeesDisclosure: offerDetails[premiumPlan]?.stateFeesDisclosure,
        statePremium: parseDollar(offerDetails[premiumPlan]?.stateNotifications?.[0]?.value), // TODO: use more dynamic state notifications
      };

    // TODO Commented out code below is to be removed
    // TODO it is a copy from libs/features/sales/checkout/src/forms/CheckoutForm/CheckoutForm.tsx
    // TODO to replace checkoutData custom stuff in CheckoutForm with the above code
    // paymentType: autoPaymentType,
    // paymentPlan: autoPaymentPlan,
    // paymentPremium: autoPaymentPremiumAmount,
    // const autoPaymentType = useGetPaymentType(autoOfferProduct);
    // const autoPaymentPlan = useGetPaymentPlan(autoOfferProduct);
    // const autoPaymentPremiumAmount = useGetPaymentPremiumAmount(autoOfferProduct);
    // const useGetPaymentType = (product?: Product): string | undefined => {
    //   return useSelector((state: RootStore) => {
    //     if (product) return getPaymentTypeForProduct(state, product);

    //     return undefined;
    //   });
    // };

    // const useGetPaymentPlan = (product?: Product): string | undefined => {
    //   return useSelector((state: RootStore) => {
    //     if (product) return getPaymentPlanForProduct(state, product);

    //     return undefined;
    //   });
    // };

    // const useGetPaymentPremiumAmount = (product?: Product): string | undefined => {
    //   return useSelector((state: RootStore) => {
    //     if (product) return getSelectedPaymentOptionPremium(state, product).totalPremium;

    //     return undefined;
    //   });
    // };

    return acc;
  }, {} as CheckoutData);
};

export const getHidePremiumToggleForOffers = (
  state: RootStore,
  lineOfBusiness?: LineOfBusiness,
): boolean => {
  const offers = getOffersForSelectedLob(state);
  const lob = lineOfBusiness ?? getLobForOfferProductsSelected(state);
  const isFullPay = isFullPayOnly(offers, lob);

  return isFullPay;
};

/**
 * Returns true if any of the policies based on the selected line of business have expired.
 * Returns false otherwise and if no line of business has been selected.
 */
export const getIsAnyPolicyExpired = (state: RootStore): boolean => {
  const lineOfBusiness = getLineOfBusiness(state);
  const reducedProductNames = getReducedProductNamesFromLob(lineOfBusiness);
  const policyStartDates = getPolicyStartDates(state);

  return reducedProductNames.some((reducedProductName) =>
    isDateExpired(policyStartDates[reducedProductName]),
  );
};

const getDependenciesSnapshot = (state: RootStore): Dependencies =>
  state.offers.dependenciesSnapshot;

export const getShouldFetchNewOffers = (state: RootStore, products: Product[]): boolean => {
  const dependencies = getDependenciesSnapshot(state);
  const answers = getAnswers(state);
  const filteredAnswers = filterAnswersDependenciesForNewOffers(answers);
  const newDependencies = { answers: filteredAnswers, products };
  const result = !isEqual(dependencies, newDependencies);

  return result;
};

export const getRetrieveLinkForPropertyProduct = (state: RootStore): string => {
  const { property: propertyOfferProduct } = getOfferProductsSelectedByType(state);
  const propertyOfferDetails = getOfferDetailsForProduct(state, propertyOfferProduct);

  return propertyOfferDetails?.retrieveLink || '';
};

// Advance specific selector to pull offer, as existing is incompatible to pull premium for bundle products
export const getOfferDetailsForAdvanceProduct = (
  state: RootStore,
  product?: Product,
): OfferInfo | null => {
  if (!product || !isProductAvailable(product)) {
    return null;
  }

  const offer = getOffer(state, product);
  if (!offer || !isOfferValid(offer, !!getUserSelection(state))) {
    return null;
  }

  const retrieveLinks = offer.links ? offer.links.filter((link) => link.rel === 'retrieve') : [];

  return {
    offerId: offer.offerId,
    fullPremium: (offer.premiums.find((p) => p.paymentPlan === 'Full') || null) as Premium,
    monthlyPremium: offer.premiums.find((p) => p.paymentPlan !== 'Full') || null,
    policyDuration: offer.details.offerKeys?.policyDurationMonths ?? '',
    // If not bindable or MVR/CBR is not available, the offer has been estimated
    isEstimated: !offer.eligibility.bindable,
    isQuoteNoBind: offer.eligibility.statusCode === OfferStatusCode.QNB,
    isPurchased: offer.eligibility.statusCode === OfferStatusCode.PURCHASED,
    isLocked: offer.eligibility.statusCode === OfferStatusCode.LOCKED,
    product: offer.product,
    retrieveLink: retrieveLinks.length > 0 ? retrieveLinks[0].href : '',
    details: offer.details,
    // These attr are Not used by every experience
    isMVRActive: false,
    isEnrolledTelematics: false,
    isRVP: false,
    coverages: {},
    checkoutPolicyKeys: {},
    coveragePolicyKeys: {},
  };
};
