import type { PropertyProduct } from '@ecp/features/shared/product';

export interface Metadata {
  // VariantA - Zillow
  isVariantACoverageStyle?: boolean;
  hideDisclaimers?: boolean;
  getSocialProofDisclaimerText?: (
    propertyOfferProduct?: PropertyProduct,
    stateCode?: string,
  ) => string | undefined;
}

const metadata: Metadata = {
  // VariantA - Zillow
  isVariantACoverageStyle: false,
};

export default metadata;
