import { TokenUtil } from '@ecp/utils/auth';
import { waitForCondition } from '@ecp/utils/common';
import { getTime } from '@ecp/utils/date';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';
import { customFetch } from '@ecp/utils/network';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';

import { SAPI_AUTH_TOKEN } from './constants';
import type { Props, TokenResponse, TokenStorage } from './types';

const handleErrors = async (response: Response): Promise<TokenResponse> => {
  if (!response.ok) {
    const text = response.body ? await response.text() : '';
    sessionStorage.removeItem(SAPI_AUTH_TOKEN);
    datadogLog({
      logType: 'warn',
      message: 'Could not get global token',
      context: {
        logOrigin: 'libs/features/sales/shared/utils/network/src/sapiAuthToken/sapiAuthToken.ts',
        functionOrigin: 'getSapiAuthToken',
        responseStatus: response.status,
        statusText: text,
      },
    });
  }

  return response.json();
};

const fetchToken = async (
  audience: string,
  client_id: string,
  client_secret: string,
): Promise<TokenResponse> => {
  const shouldUseCustomFetch = flagValues[FeatureFlags.CUSTOM_FETCH_RETRY_TIMEOUT];
  const init: RequestInit = {
    body: JSON.stringify({
      audience,
      client_id,
      client_secret,
      grant_type: 'client_credentials',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    method: 'POST',
  };

  const response = shouldUseCustomFetch
    ? await customFetch({
        url: env.tokenApiUrl,
        init: {
          ...init,
          retry: { maxAttempts: 1 },
          timeout: { value: 900, factor: 2 },
        },
      }).then(handleErrors)
    : await fetch(env.tokenApiUrl, init).then(handleErrors);

  return response;
};

const defaultProps: Props = {
  audience: env.audience,
  sapiClientId: env.sapiClientId,
  sapiClientSecret: env.sapiClientSecret,
  getToken: (): TokenStorage => sessionStorage.getItem(SAPI_AUTH_TOKEN) as TokenStorage,
  setToken: (token: TokenStorage): void => sessionStorage.setItem(SAPI_AUTH_TOKEN, token),
};

/**
 * It is recommended to import this function after namespace for sessionStorage has been set.
 * So initialization phase can read the token from the correct sessionStorage namespace.
 * This is helpful on the page refresh as we can reuse saved in sessionStorage token instead of fetching a new one.
 * Initialization is lazy, so you most likely will be fine even if you won't be able to do the above recommendation.
 * If you don't use namespaces for sessionStorage, no need to bother at all.
 *
 * The function is wrapped with iife which runs only once regardless of how many times the module get imported.
 * This assumes webpack configured to emit a singe runtime chunk for the entire application `runtimeChunk: 'single'`.
 * We could have lifted initialization to the global scope of the module and removed iife, but this would be a side-effect.
 */
export const getSapiAuthToken = (props: Props = defaultProps): (() => Promise<string>) => {
  const { getToken, setToken, audience, sapiClientId, sapiClientSecret } = props;
  const token = new TokenUtil(getTime);
  let isRequestPending = true;
  // Lazy initialization part
  setTimeout(() => {
    if (token.isExpired) {
      const sapiToken = getToken();
      if (sapiToken) {
        const { value, expAt } = sapiToken as { value?: string; expAt?: number };
        token.set(value, expAt);
      }
    }
    isRequestPending = false;
  }, 0);

  // Actual implementation
  return async (): Promise<string> => {
    // Avoid double-fetching
    if (isRequestPending) {
      await waitForCondition({ condition: () => !isRequestPending });
    }

    // This would probably be true if a partner is launched only in V4 and doesn't have any v3 presence.
    if (!sapiClientId || !sapiClientSecret) {
      return '';
    }

    if (token.isExpired) {
      isRequestPending = true;
      const tokenResponse = await fetchToken(audience, sapiClientId, sapiClientSecret);
      const value = tokenResponse.access_token;
      const expAt = tokenResponse.expires_in + getTime();
      token.set(value, expAt);
      isRequestPending = false;
      setToken({ value, expAt });
    }

    // At this point the token should have an up-to-date value since token.isExpired checks for null value as well
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return token.value!;
  };
};
