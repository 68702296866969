import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'InPersonClick2SignPerson' })((theme) => ({
  container: {
    alignItems: 'center',
  },
  items: {
    display: 'inline-block',
    flexBasis: 'auto',
    marginRight: '40px',
  },
  button: {
    paddingLeft: '0 !important',
  },
  namedInsured: {
    display: 'inline-block',
  },
  root: {
    marginTop: '0 !important',
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
  spinner: { display: 'flex', justifyContent: 'center' },
}));
