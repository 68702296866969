import { Queue } from '@ecp/utils/queue';

import { env } from '@ecp/env';
import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';
import type { Product } from '@ecp/features/shared/product';

import type { AnswersResponse } from '../inquiry/types';
import type {
  OffersGetResponse,
  OffersPostResponse,
  OffersPutResponse,
  OffersRecallResponse,
} from './types';

export const postOffers = ({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<OffersPostResponse>> => {
  return salesRequest({
    endpoint: `session/${dalSessionId}/offers`,
    // Adding it to a new Queue to make sure that post on offers doesn't stop the user from making the answer updates
    queue: new Queue(),
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

export const putOffers = ({
  dalSessionId,
  products,
}: {
  dalSessionId: string;
  products: Product[];
}): Promise<SalesResponse<OffersPutResponse>> => {
  const body = JSON.stringify({
    products,
  });

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers`,
    options: {
      body,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

export const getOffers = ({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<OffersGetResponse>> => {
  return salesRequest({
    endpoint: `session/${dalSessionId}/offers`,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

export const postRecallOffers = ({
  person,
  products,
  zipcode,
  experienceId,
}: {
  person: { dob: string; lastname: string; email: string };
  products: Product[];
  zipcode: string;
  experienceId?: string;
}): Promise<SalesResponse<OffersRecallResponse>> => {
  const body = JSON.stringify({
    'person.dob': person.dob,
    'person.lastName': person.lastname,
    'person.email': person.email,
    'address.zipCode': zipcode,
    'partner.experienceId': experienceId || env.static.expId,
    products,
  });

  return salesRequest({
    endpoint: 'session/retrieve',
    options: {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      retry: {
        maxAttempts: 2,
      },
      timeout: {
        value: 12000,
        factor: 2,
      },
    },
  });
};

export const postBlobRetrieve = ({
  encryptedData,
  source,
}: {
  encryptedData: string;
  source: string;
}): Promise<SalesResponse<OffersRecallResponse>> => {
  const body = JSON.stringify({
    keyName: 'retrieve',
    encryptedData,
    'partner.experienceId': env.static.expId,
    source,
  });

  return salesRequest({
    endpoint: `session/retrieve`,
    options: {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      retry: {
        maxAttempts: 2,
      },
      timeout: {
        value: 12000,
        factor: 2,
      },
    },
  });
};

export const postDelta = ({
  dalSessionId,
  policyTypes,
}: {
  dalSessionId: string;
  policyTypes?: string[];
}): Promise<SalesResponse<AnswersResponse>> => {
  const body = JSON.stringify({ policyTypes });

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/delta`,
    options: {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};
