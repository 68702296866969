import type { ReactElement } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { useGetAddressFields } from '@ecp/features/sales/shared/store';
import type { ProductName } from '@ecp/features/shared/product';

import type { AgentScripts } from '../metadata/types';
import metadata from './metadata';
import type { DataType, Language, NachaProductName } from './metadata/metadata';

export const FormattedAddress: React.FC = (): ReactElement => {
  const {
    address: { line1, line2, city, state, zipcode },
  } = useGetAddressFields();
  const stateCode = String(state.value);
  const addressState = stateCode.slice(-2);

  return (
    <>
      {line1.value}
      <br />
      {line2.value && (
        <>
          {line2.value}
          <br />
        </>
      )}
      {city.value} {addressState}, {zipcode.value}
      <br />
    </>
  );
};

export const delimitWithAnd = (strArray: string[], language: Language = 'English'): string => {
  let delimiterAnd = 'and';
  if (language === 'Spanish') {
    delimiterAnd = 'y';
  }
  if (strArray.length === 0) return '';
  if (strArray.length === 1) return strArray[0];
  const lastStr = strArray.pop();
  const lastAnd = strArray.length >= 2 ? `, ${delimiterAnd} ` : ` ${delimiterAnd} `;

  return strArray.join(', ') + lastAnd + lastStr;
};

export const addPeriodToEndOfQuote = (str: string, lastChar?: boolean): string => {
  const len = str.length;
  const periodChar = lastChar ? 0 : 1;

  return str.substring(0, len - periodChar) + '.' + str.substring(len - periodChar);
};

export const getSpanishProductName = (product: ProductName): string => {
  switch (product) {
    case 'auto':
      return 'auto';
    case 'home':
      return 'hogar';
    case 'renters':
      return 'de inquilinos';
    // coming soon.
    // case 'condo':
    //   return 'condominio';
    default:
      return '';
  }
};

export const getRealTimePaymentContent = (language: Language = 'English'): JSX.Element | null => {
  const realTimeCreditCardPayment = flagValues[FeatureFlags.REAL_TIME_CREDIT_CARD_PAYMENT];

  const content =
    language === 'English'
      ? 'Payments are processed in real-time. Your payment will be withdrawn immediately.'
      : 'Los pagos se procesan en tiempo real. Su pago se retirará inmediatamente.';

  return realTimeCreditCardPayment ? <strong>{content}</strong> : null;
};

export const getNachaScriptContent = (
  insuredName: string,
  nachaProducts: NachaProductName[],
): AgentScripts => {
  const isMultipleNachaProducts: boolean = nachaProducts.length > 1;
  const insuranceType = isMultipleNachaProducts ? 'multipleProductInsuranceType' : 'insuranceType';

  const getMetadataContent = (dataType: DataType, language: Language = 'English'): string => {
    const arrayOfUnderwritingGroupFullNames = nachaProducts.map(
      (product: NachaProductName) => metadata[product][language][dataType],
    );
    if (language === 'Spanish') {
      return delimitWithAnd(arrayOfUnderwritingGroupFullNames, 'Spanish');
    } else {
      return delimitWithAnd(arrayOfUnderwritingGroupFullNames);
    }
  };

  const LineBreak = (): React.ReactElement => (
    <>
      <br />
      <br />
    </>
  );

  const getAutoPayContent = (language: Language = 'English'): JSX.Element => {
    let autoPayContent = '';
    if (language === 'Spanish') {
      if (isMultipleNachaProducts) {
        autoPayContent =
          'En un periodo de 24 horas, recibirá un correo electrónico con un enlace para crear una cuenta en línea y acceder a todos los documentos de la póliza. Para su póliza de hogar, tendrá que iniciar sesión en nuestro sitio web y seleccionar la opción AutoPay ó Pago automatizado.';
      }
      if (nachaProducts.length === 1 && !nachaProducts.includes('auto')) {
        autoPayContent =
          'En un periodo de 24 horas, recibirá un correo electrónico con un enlace para crear una cuenta en línea y acceder a todos los documentos de la póliza. Tendrá que iniciar sesión en nuestro sitio web y seleccionar la opción AutoPay ó Pago automatizado.';
      }
    } else {
      if (isMultipleNachaProducts) {
        autoPayContent =
          'Within 24 hours, you will receive an email with a link to create an online account and view policy documents online.  For your home policy, you’ll need to log into our website and opt in to AutoPay';
      }
      if (nachaProducts.length === 1 && !nachaProducts.includes('auto')) {
        autoPayContent =
          "Within 24 hours, you will receive an email with a link to create an online account and view policy documents online. You'll need to log into our website and opt in to AutoPay.";
      }
    }

    if (autoPayContent !== '') {
      return (
        <>
          <LineBreak /> {autoPayContent}
        </>
      );
    }

    return <>{autoPayContent}</>;
  };

  const agentNachaContent = {
    title: `NACHA payment-related information`,
    text: (
      <p>
        To complete your transaction, as mentioned to you previously, this call is being recorded.
        Do I have your permission to continue?
        <LineBreak />
        (Wait for confirmation)
        <LineBreak />
        Please confirm I am speaking to {insuredName} regarding your property located at:
        <LineBreak />
        <FormattedAddress />
        <LineBreak />
        (Wait for confirmation)
        {getAutoPayContent()}
        <LineBreak />
        The charge{isMultipleNachaProducts ? 's' : ''} will appear on your bank statement as{' '}
        {addPeriodToEndOfQuote(getMetadataContent(insuranceType), isMultipleNachaProducts)} Refunds
        or credits, if any, may be made directly to your account. Changes to your policy or premium
        may change the amount debited. {getRealTimePaymentContent()}
        <LineBreak />
        Once enrolled in Auto Pay, you can cancel your auto-payment enrollment by calling Customer
        Solutions at the number included in your policy package. Note that{' '}
        {getMetadataContent(
          isMultipleNachaProducts ? 'underwriterOnStatement' : 'underwritingGroupFull',
        )}{' '}
        {isMultipleNachaProducts ? 'have' : 'has'} the right to discontinue your enrollment at any
        time. If you understand and agree to the terms that were just explained, please state your
        first and last name and today's date.
        <LineBreak />
        (Wait for confirmation, insured needs to state their first and last name and today's date).
      </p>
    ),
    spanishText: (
      <p>
        Para completar su transacción, como le he mencionado anteriormente, esta llamada está siendo
        grabada. ¿Me da permiso para continuar?
        <LineBreak />
        (Wait for confirmation)
        <LineBreak />
        Por favor, me puede confirmar que estoy hablando con {insuredName} respecto a su propiedad
        localizada en:
        <LineBreak />
        <FormattedAddress />
        <LineBreak />
        (Wait for confirmation)
        {getAutoPayContent('Spanish')}
        <LineBreak />
        {isMultipleNachaProducts ? 'Los cargos aparecerán' : 'El cargo aparecerá'} en su extracto
        bancario como{' '}
        {addPeriodToEndOfQuote(
          getMetadataContent(insuranceType, 'Spanish'),
          isMultipleNachaProducts,
        )}{' '}
        Si hubiese en algún momento algun reembolso ó credito, se efectuaria directamente a esa
        misma cuenta. Los cambios en su póliza pueden modificar el importe cargado.
        {getRealTimePaymentContent('Spanish')}
        <LineBreak />
        Una vez que se haya inscrito en el pago automático, puede cancelar su subscripcion llamando
        a Customer Solutions al número incluido en la correspondencia de su póliza. Tenga en cuenta
        que{' '}
        {getMetadataContent(
          isMultipleNachaProducts ? 'underwriterOnStatement' : 'underwritingGroupFull',
          'Spanish',
        )}{' '}
        tiene derecho a cancelar su subscripcion en cualquier momento. Si entiende y acepta las
        condiciones que acabamos de explicarle, por favor indique su nombre, apellidos y la fecha de
        hoy.
        <LineBreak />
        (Wait for confirmation, insured needs to state their first and last name and today's date)
      </p>
    ),
  };

  return agentNachaContent;
};
