import { useCallback } from 'react';

import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { castToNumberOrNull } from '@ecp/utils/common';

import { NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getDriverIsPrimaryInsured,
  setFormErrorsChangedByField,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import metadata from './metadata';

interface Props extends QuestionProps {
  driverRef: string;
  personRef: string;
}

export const DriverLicenseAgeFirstLicensedQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    personRef,
    id = 'AgeLicensed',
    label,
    trackingName = 'age_first_licensed',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    groupLabel,
  } = props;
  const dispatch = useDispatch();
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const dlAgeFirstLicensed = useDriverField('license.ageFirstLicensed');
  const licenseNumber = useDriverField('license.number');
  const licenseState = useDriverField('license.state');
  const staticPniDOB = useField('static.person.dob');
  const staticPersonRefDOB = useField(`static.${personRef}.dob`);
  const isPni = useSelector(getDriverIsPrimaryInsured(driverRef));
  const dob = isPni ? staticPniDOB : staticPersonRefDOB;

  useInitValues({
    [dlAgeFirstLicensed.key]: castToNumberOrNull(dlAgeFirstLicensed.question.defaultValue),
  });
  useAddFields({ dlAgeFirstLicensed });

  const handleDriverFirstLicensedComplete = useCallback(
    (value: AnswerValue): void => {
      dlAgeFirstLicensed.props.actionOnComplete(value);
      const todaysDate = dayjs(new Date());
      const birthdate = dayjs(dob.props.value);
      const ageYear = todaysDate.diff(birthdate, 'year');

      // TODO: cross field validations should be part of SAPI validator and not UI.
      if (dob.props.value && Number(value) > ageYear) {
        dispatch(
          setFormErrorsChangedByField({
            key: `${driverRef}.license.ageFirstLicensed`,
            errors: ['Driver’s age must be greater than age first licensed'],
          }),
        );
      }
    },
    [dlAgeFirstLicensed.props, dispatch, driverRef, dob.props.value],
  );

  if (!dlAgeFirstLicensed.exists) return null;

  const isDisabled =
    metadata.ageLicensedDisabled && !licenseNumber.props.value && !licenseState.props.value; // TODO Consider moving this the SAPI question hide property when we are able to get hide property working for mock questions

  return (
    <NumberFormat
      {...dlAgeFirstLicensed.props}
      actionOnComplete={handleDriverFirstLicensedComplete}
      format='##'
      fullWidth={false}
      id={id}
      label={label}
      ariaLabel={label}
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      disabled={isDisabled}
    />
  );
};
