import { useCallback, useEffect, useState } from 'react';

import { fetchSuggestions } from '@ecp/utils/geo';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { useGetAddressFields } from '@ecp/features/sales/shared/store';

import { PersonAddressForm } from './PersonAddressForm';
import type { PersonAddressSuggestionProps } from './PersonAddressSuggestion';
import { PersonAddressSuggestion } from './PersonAddressSuggestion';

interface Props {
  onNext?: () => Promise<void>;
}

export const PersonAddressPage: React.FC<Props> = (props) => {
  const { onNext } = props;

  // This code will be removed in EDSP-8506
  // line 33 will be `const handleNext = onNext || getNextPage;
  const getNextPage = useNavigateToNextPage();

  const nextPage = getNextPage;
  const handleNext = onNext || nextPage;

  const {
    address: { line1, line2, city, state, zipcode },
  } = useGetAddressFields();

  const [suggestedAddress, setSuggestedAddress] =
    useState<PersonAddressSuggestionProps['suggestedAddress']>();
  const [enteredAddress, setEnteredAddress] =
    useState<PersonAddressSuggestionProps['enteredAddress']>();
  const [pageSubtitle, setPageSubtitle] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [showManualEntry, setShowManualEntry] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const defaultPageSubtitle =
    'We could not find a match in the USPS database for your address. Please verify that the entered address is correct.';

  useEffect(() => {
    if (!hasMounted) {
      const { value: lineOneValue } = line1;
      const { value: lineTwoValue } = line2;
      const { value: cityValue } = city;
      const { value: zipcodeValue } = zipcode;
      const { value: stateValue } = state;
      const streetAddress = `${lineOneValue ?? ''}${
        line2 && lineTwoValue ? ` ${lineTwoValue}` : ''
      }`;
      const formattedState = stateValue ? stateValue.toString().split('.')[1] : ''; // remove enum prefix
      const enteredZipcode = zipcodeValue ? zipcodeValue.toString() : '';
      const assembledEnteredAddress = {
        enteredStreet: lineOneValue ? lineOneValue.toString() : '',
        enteredSecondary: lineTwoValue,
        enteredCity: cityValue,
        enteredState: formattedState,
        enteredZipcode,
      };
      const lookupQuery = {
        value: streetAddress,
        zipcode: enteredZipcode,
        state: '',
      };

      fetchSuggestions(lookupQuery).then((result) => {
        let foundSuggestedAddress: PersonAddressSuggestionProps['suggestedAddress'] | undefined;
        let hasApartmentEntries = false;
        const formattedEnteredAddress = `${[streetAddress, cityValue, formattedState].join(
          ', ',
        )} ${enteredZipcode}`;
        if (result && Array.isArray(result) && result.length > 0) {
          for (let i = 0; i < result.length; i += 1) {
            const {
              street_line: suggestedStreet,
              secondary: suggestedSecondary,
              city: suggestedCity,
              state: suggestedState,
              zipcode: suggestedZipcode,
              entries,
            } = result[i];
            const suggestion = `${[
              suggestedStreet,
              suggestedSecondary,
              suggestedCity,
              suggestedState,
            ]
              .filter((x) => x)
              .join(', ')} ${suggestedZipcode}`;
            if (formattedEnteredAddress !== suggestion) {
              // only display first unique suggestion
              foundSuggestedAddress = {
                suggestedStreet,
                suggestedSecondary,
                suggestedCity,
                suggestedState,
                suggestedZipcode,
              };
              hasApartmentEntries = entries > 0;
              break;
            }
          }
        }
        const defaultPageTitle = 'Verify your address';
        if (foundSuggestedAddress) {
          // suggestions found
          if (hasApartmentEntries) {
            // apartment number suggestion
            setShowManualEntry(true);
            setPageSubtitle(
              'For the most accurate quote, USPS suggests adding an apartment/unit number.',
            );
            setPageTitle('Enter an apartment/unit number');
          } else {
            // street address suggestion
            setSuggestedAddress(foundSuggestedAddress);
            setPageSubtitle(
              'For the most accurate quote, USPS suggests the changes highlighted below.',
            );
            setPageTitle(defaultPageTitle);
          }
        } else {
          // no suggestions found
          setPageSubtitle(defaultPageSubtitle);
          setPageTitle(defaultPageTitle);
          setShowManualEntry(true);
        }
        setEnteredAddress(assembledEnteredAddress);
      });
      setHasMounted(true); // only run this lookup once
    }
  }, [line1, line2, city, state, zipcode, hasMounted]);

  const handleManualEdit = useCallback((): void => {
    setPageSubtitle(defaultPageSubtitle);
    setShowManualEntry(true);
  }, []);

  return (
    <Page
      title={pageTitle}
      subTitle={pageSubtitle}
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.PERSON} />,
      }}
      analyticsElement='choice.addressPage.page'
    >
      {enteredAddress &&
        (showManualEntry ? (
          <PersonAddressForm onNext={handleNext} />
        ) : (
          suggestedAddress && (
            <PersonAddressSuggestion
              suggestedAddress={suggestedAddress}
              enteredAddress={enteredAddress}
              onEditClick={handleManualEdit}
              onNext={handleNext}
            />
          )
        ))}
    </Page>
  );
};
