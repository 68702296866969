import { useCallback, useState } from 'react';

import { Divider, Grid, Stack, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem, Snackbar } from '@ecp/components';
import { getVehicleDescription } from '@ecp/features/sales/quotes/auto';
import { Button, Dialog } from '@ecp/features/sales/shared/components';
import { getVehicles } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUICheck, IconUIDelete, IconUIEdit } from '@ecp/themes/base';

import { useStyles } from './AutoTPIPageForm.styles';

export const AutoTPIPageForm: React.FC = () => {
  const { classes } = useStyles();
  const [removeInterestedparty, setremoveInterestedparty] = useState(false);
  const [showResendMessage, setShowResendMessage] = useState(false);

  const interestedPartyDialogOpen = useEvent(() => {
    setremoveInterestedparty(true);
  });

  const handleInterestedPartyDialogClose = useCallback(() => {
    setremoveInterestedparty(false);
  }, []);

  const handleDeleteAutoTpi = useCallback(() => {
    setShowResendMessage(true);
    setremoveInterestedparty(false);
  }, []);

  const handleResendMessageClose = useCallback(() => setShowResendMessage(false), []);
  const vehicles = useSelector(getVehicles);
  // TODO this need updates when shared component is merged
  // const handleAddTPI = useCallback(
  //   async (vehicleRef: string) => {
  //     const newTPIRef = addTPI(vehicleRef);
  //     const { tpiRef } = newTPIRef;
  //     await newTPIRef.updateTPIVehicleAddressRef;
  //     await dispatch(
  //       navigateToAddTPI({ ref: tpiRef, replace: false, referencePage: 'AddVehicleTPI' }),
  //     );
  //   },
  //   [addTPI, dispatch],
  // );

  return (
    <>
      <Dialog
        actionButtonLabel='Remove Interested party'
        actionButtonVariant='danger'
        textButtonLabel='Cancel'
        titleText='Are you sure you want to remove this interested party?'
        open={removeInterestedparty}
        onClose={handleInterestedPartyDialogClose}
        actionButtonOnClick={handleDeleteAutoTpi}
      >
        <p>
          Removing an interested party will permanently delete its details. This action can't be
          undone.
        </p>
      </Dialog>
      {showResendMessage && (
        <Snackbar
          classes={{ root: classes.snackbar }}
          message={
            <Stack direction='row' spacing={1} alignItems='center'>
              <div className={classes.iconContainer}>
                <IconUICheck className={classes.icon} />
              </div>
              <span>Interested party removed</span>
            </Stack>
          }
          open={showResendMessage}
          autoHideDuration={3000}
          vertical='top'
          horizontal='center'
          onClose={handleResendMessageClose}
        />
      )}
      <GridItem>
        {vehicles.map((vehicle, index) => (
          <GridItem key={index} className={classes.card}>
            <Grid container key={vehicle.ref}>
              <GridItem xs={12} md={6}>
                <Typography variant='body3'>{getVehicleDescription(vehicle)}</Typography>
                {vehicle.vin && <p className={classes.contentSubTitle}>{`VIN: ${vehicle.vin}`}</p>}
              </GridItem>
              <GridItem xs={12} md={6} className={classes.columnRight}>
                <Button
                  className={classes.button}
                  variant='outlinePrimary'
                  // onClick={() => handleAddTPI(vehicle.ref)}
                  trackingName='tpi_auto_add_party'
                  trackingLabel={GoogleAnalyticsLabels.CONTINUE}
                >
                  ADD INTERESTED PARTY
                </Button>
              </GridItem>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container>
              <GridItem xs={12} md={6}>
                <p className={classes.title}>Tennesse Credit Union</p>
                <p>Loan no: 032145699789874645</p>
              </GridItem>
              <Grid item xs={12} md={6} className={classes.columnRight}>
                <GridItem>
                  <Button
                    variant='iconTextMedium'
                    icon={<IconUIEdit />}
                    className={classes.actionButton}
                  >
                    Edit
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    variant='iconTextMedium'
                    icon={<IconUIDelete />}
                    className={classes.actionButton}
                    onClick={interestedPartyDialogOpen}
                  >
                    Remove
                  </Button>
                </GridItem>
              </Grid>
            </Grid>
          </GridItem>
        ))}
      </GridItem>
    </>
  );
};
