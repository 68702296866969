import { useState } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { Tooltip } from '@ecp/components';
import { Button, Dialog } from '@ecp/features/sales/shared/components';
import { PRODUCT_LOB_USER_SELECTION } from '@ecp/features/sales/shared/constants';
import {
  getNextPageOnProductDeleteFromConfig,
  getUpdatedPageflowsFromConfig,
} from '@ecp/features/sales/shared/routing';
import {
  deleteAnswers,
  getLineOfBusiness,
  startOverFlow,
  submitPolicyType,
  updateLobOrder,
  useGetProductFields,
} from '@ecp/features/sales/shared/store';
import type { PageFlow } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  LineOfBusiness,
  LineOfBusinessUnbundled,
  ProductName,
} from '@ecp/features/shared/product';
import {
  getProductNamesFromLob,
  getUnbundledLobFromProductName,
  isLineOfBusinessBundle,
} from '@ecp/features/shared/product';
import { IconUIDelete } from '@ecp/themes/base';

import { useStyles } from '../NavbarDrawer';

const getRemainingLineOfBusiness = (
  productToDelete: ProductName,
  currentLineOfBusiness: LineOfBusiness,
): LineOfBusinessUnbundled => {
  const currentProductNames = getProductNamesFromLob(currentLineOfBusiness);
  const remainingProductNames = currentProductNames.filter(
    (productName) => productName !== productToDelete,
  );
  // TODO this makes an assumption there’s only two products ever selected (either monoline or two product bundle), WHICH IS INCORRECT
  const remainingLineOfBusiness = getUnbundledLobFromProductName(remainingProductNames[0]);

  return remainingLineOfBusiness;
};

interface Props {
  /** @deprecated THIS CAN'T BE JUST STRING. EITHER USE LineOfBusiness OR ProductName. PROBLEM IS THAT IT COMES FROM CONFIG SERVICE PAYLOAD. */
  product: string;
  productFlow: PageFlow;
  pagePath: string;
}

export const DeleteProductDialog: React.FC<Props> = (props) => {
  const { product, pagePath, productFlow } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { product: productField } = useGetProductFields();
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [startOverDialog, setStartOverDialog] = useState(false);
  const [deleteProcessing, setDeleteProcessing] = useState(false);

  const toggleDeleteProductDialog = useEvent(() => {
    if (!deleteProductDialog) {
      trackClick({ action: 'SideNavAddDeleteProductButton', label: `Delete: ${product}` });
    }
    setDeleteProductDialog(!deleteProductDialog);
  });

  const toggleStartOverDialog = useEvent(() => {
    if (!startOverDialog) {
      trackClick({ action: 'SideNavAddDeleteProductButton', label: `Delete: ${product}` });
    }
    setStartOverDialog(!startOverDialog);
  });

  const handleStartNewQuote = useEvent(() => {
    dispatch(startOverFlow());
  });

  const handleDeleteProduct = useEvent(async () => {
    setDeleteProcessing(true);
    const productName = product.toLowerCase() as ProductName;
    // TODO this makes an assumption there’s only two products ever selected (either monoline or two product bundle), WHICH IS INCORRECT
    const remainingLineOfBusiness = getRemainingLineOfBusiness(productName, lineOfBusiness);
    await productField.props.actionOnComplete(remainingLineOfBusiness);
    // Everytime product.lob is modified, execute submitPolicyType
    // In V4, this creates/ removes coverage options and offers (only applicable after POST Offers)
    // No impact on V3 flow.
    await dispatch(submitPolicyType());
    await dispatch(updateLobOrder(remainingLineOfBusiness, true));
    await dispatch(getUpdatedPageflowsFromConfig({ modifiedLOB: remainingLineOfBusiness }));
    await dispatch(
      deleteAnswers({
        keys: [PRODUCT_LOB_USER_SELECTION], // PRODUCT_LOB_USER_SELECTION answer is  deleted so useQuotesSelection's useDetermineLineOfBusinessUserSelection, the pitchedSelection would be set to default for quote summary page
      }),
    );
    await dispatch(
      getNextPageOnProductDeleteFromConfig({
        path: pagePath,
        productFlow,
      }),
    );
    setDeleteProcessing(false);
    toggleDeleteProductDialog();
  });

  return (
    <>
      <Tooltip
        data-testid='deleteProductTooltip'
        placement='top'
        title='Remove'
        disableTouchListener
      >
        <Button
          id={product}
          variant='iconText'
          className={classes.actionIcon}
          onClick={
            isLineOfBusinessBundle(lineOfBusiness)
              ? toggleDeleteProductDialog
              : toggleStartOverDialog
          }
        >
          <IconUIDelete />
        </Button>
      </Tooltip>
      {deleteProductDialog && (
        <Dialog
          actionButtonLabel='Yes, Continue'
          actionButtonVariant='danger'
          textButtonLabel='Cancel'
          titleText={`Confirm removal of ${product} policy`}
          actionButtonOnClick={handleDeleteProduct}
          textButtonOnClick={toggleDeleteProductDialog}
          open={deleteProductDialog}
          onClose={toggleDeleteProductDialog}
          isProcessing={deleteProcessing}
          trackingNameCloseIcon='SideNavDeleteProductCloseButton'
          trackingLabelCloseIcon='X'
          trackingNameButton='SideNavConfirmDeleteProductButton'
          trackingLabelButton={`ConfirmDelete: ${product}`}
          trackingNameText='SideNavConfirmDeleteProductCancelButton'
          trackingLabelText={`ConfirmDeleteCancel: ${product}`}
        >
          <p>{`Are you sure you want to remove ${product} policy?`}</p>
        </Dialog>
      )}
      {startOverDialog && (
        <Dialog
          actionButtonLabel='Yes, Continue'
          actionButtonVariant='danger'
          textButtonLabel='Cancel'
          titleText='Start over'
          buttonPlacement='right'
          actionButtonOnClick={handleStartNewQuote}
          textButtonOnClick={toggleStartOverDialog}
          open={startOverDialog}
          onClose={toggleStartOverDialog}
          trackingNameCloseIcon='SideNavStartOverModalCloseButton'
          trackingLabelCloseIcon='X'
          trackingNameButton='SideNavStartOverModalYesContinue'
          trackingLabelButton='StartOver'
          trackingNameText='SideNavStartOverModalCancel'
          trackingLabelText='Cancel'
        >
          <p>
            {`Are you sure you want to remove ${product} policy? If you click continue below, you
            will be starting a new quote`}
          </p>
        </Dialog>
      )}
    </>
  );
};
