import type { Store } from 'redux';

import { datadogLog } from '@ecp/utils/logger';

import { getInitializing, updateGlobalError } from '@ecp/features/sales/shared/store';

const INITIALIZATION_LIMIT = 60000;

export const throwOnExceedingInitializationLimit = (store: Store): void => {
  setTimeout(() => {
    const initializing = getInitializing(store.getState());
    if (!initializing) return;

    const message = `Initialization exceeded ${INITIALIZATION_LIMIT / 1000}s`;
    datadogLog({
      logType: 'error',
      message,
      context: {
        logOrigin: 'libs/features/sales/shared/misc/src/throwOnExceedingInitializationLimit.ts',
        functionOrigin: 'throwOnExceedingInitializationLimit',
      },
    });
    store.dispatch(
      updateGlobalError({
        hasError: true,
        requestId: '',
        transactionId: '',
        errorReason: 'GLOBAL',
        text: message,
      }),
    );
  }, INITIALIZATION_LIMIT);
};
