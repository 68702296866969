import { datadogLog } from '@ecp/utils/logger';

import { statusNetworkOrCorsError } from './errorCodes';
import { fetchWithTimeout } from './fetchWithTimeout';
import { shouldRetryForNetworkErrors, shouldRetryForTimeoutErrors } from './util';

export const MAX_RETRY_ATTEMPTS = 3;

/**
 * Extended RequestInit interface to include retry and timeout options.
 */
export interface CustomFetchRequestInitExtended extends RequestInit {
  retry?: {
    currentAttempt?: number; // The current attempt number.
    retryFunctions?: Array<(error: Error) => boolean>; // Functions to determine if a retry should be attempted.
    maxAttempts: number; // Maximum number of retry attempts.
  };
  timeout?: {
    value: number; // The timeout value in milliseconds.
    factor: number; // The factor by which to multiply the current timeout for each retry.
  };
}

interface CustomFetchParams {
  url: string;
  init: CustomFetchRequestInitExtended;
}

export interface RetryOptions {
  maxAttempts: number;
  retryFunctions?: Array<(error: Error) => boolean>;
}

/**
 * Performs an operation and retries it if it fails based on the provided retry options.
 *
 * @param {() => Promise<T>} operation - The operation to perform.
 * @param {RetryOptions} options - The retry options, including maximum attempts and retry conditions.
 * @param {number} [attempt=0] - The current attempt number.
 * @returns {Promise<T>} - The result of the operation.
 * @throws - Throws the error if all retry attempts fail.
 */
export async function retryOperation<T>(
  operation: () => Promise<T>,
  options: RetryOptions,
  attempt = 0,
): Promise<T> {
  const {
    maxAttempts = MAX_RETRY_ATTEMPTS,
    retryFunctions = [shouldRetryForNetworkErrors, shouldRetryForTimeoutErrors],
  } = options;

  try {
    return await operation();
  } catch (error) {
    attempt++;
    const shouldRetry = retryFunctions.some((fn) => fn(error as Error));
    datadogLog({
      logType: 'warn',
      message: `Attempt ${attempt} failed with error: ${error}. Should retry: ${shouldRetry}`,
      context: {
        logOrigin: 'libs/utils/network/src/retry.ts',
        functionOrigin: 'retryOperation',
      },
      error: error as Error,
    });

    if (attempt >= maxAttempts || !shouldRetry) {
      throw error;
    }

    return retryOperation<T>(operation, options, attempt);
  }
}

/**
 * Performs a custom fetch operation with optional retry and timeout logic.
 *
 * @param {CustomFetchParams} params - The parameters for the fetch operation, including URL and request options.
 * @returns {Promise<Response>} - The fetch response.
 * @throws - Throws the error if the fetch operation fails and all retry attempts are exhausted.
 */
export async function customFetch({ url, init }: CustomFetchParams): Promise<Response> {
  const { retry, timeout, ...initProps } = init;

  const fetchImplementation = timeout
    ? () => fetchWithTimeout({ url, init: initProps, timeout: timeout.value })
    : () => fetch(url, initProps);

  const currentAttempt = retry?.currentAttempt ?? 0;
  try {
    // Use fetchWithTimeout if a timeout is specified.
    const response = await fetchImplementation();

    if (response.ok) {
      if (currentAttempt > 0) {
        datadogLog({
          logType: 'info',
          message: `Fetch successful on ${url} ${
            timeout ? `with timeout: ${timeout.value} ms` : ''
          } on attempt ${currentAttempt}.`,
          context: {
            logOrigin: 'libs/utils/network/src/customFetch.ts',
            functionOrigin: 'customFetch',
          },
        });
      }
    } else if (statusNetworkOrCorsError(response.status)) {
      // Return 4xx client errors without retrying or throwing errors
      throw response;
    }

    return response;
  } catch (error) {
    if (retry && currentAttempt < retry?.maxAttempts) {
      return retryOperation<Response>(
        () =>
          customFetch({
            url,
            init: {
              ...init,
              retry: {
                ...retry,
                currentAttempt: currentAttempt + 1,
              },
              timeout: timeout && {
                ...timeout,
                value: timeout.value * (timeout.factor ?? 2),
              },
            },
          }),
        {
          maxAttempts: retry.maxAttempts,
          retryFunctions: retry.retryFunctions,
        },
      );
    } else {
      datadogLog({
        logType: 'error',
        message: `Custom Fetch failed on ${url} with error`,
        context: {
          logOrigin: 'libs/utils/network/src/customFetch.ts',
          functionOrigin: 'customFetch',
        },
        error: error as Error,
      });
      // Throw the error if no more retries are allowed or if the retry condition is not met.
      throw error;
    }
  }
}
