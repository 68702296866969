import { cloneDeep, isKeyOf, set } from '@ecp/utils/common';

import type {
  AllConfigs,
  ByPageId,
  Delta,
  MenuItem,
  PageFlow,
  RequestConfigParams,
  RequestedPageFlow,
} from './types';

export const getPageFlowPaths = (pageFlow: PageFlow): string[] =>
  pageFlow.value?.pageFlows.map((pageFlow) => pageFlow.path);

export const getPageFlowReferencePartialPaths = (pageFlow: PageFlow): string[] =>
  pageFlow.value?.pageFlows.flatMap((pageFlow) => pageFlow.referencePartialPaths ?? []);

export const getConfigs = async (request: RequestedPageFlow[]): Promise<PageFlow[]> => {
  const configs = await Promise.all(request.map((x) => getConfig(x)));

  return configs;
};
const getConfig = async (requestedConfig: RequestedPageFlow): Promise<PageFlow> => {
  const configs: AllConfigs = await import(`./metadata/${requestedConfig.configType}`)
    .then((_module) => _module.default)
    .catch(() =>
      Promise.reject(new Error(`${requestedConfig.configType} has not been mocked yet.`)),
    );

  return {
    id: requestedConfig.id,
    value: filterConfig(
      configs,
      requestedConfig.effectiveDateTime,
      requestedConfig.version,
      requestedConfig.params,
    ),
  } as unknown as Promise<PageFlow>;
};

export const filterConfig = (
  inputConfigs: AllConfigs,
  effectiveDate: Date = new Date(Date.now()),
  version?: number,
  params?: RequestConfigParams,
): AllConfigs[number]['config'] => {
  if (!inputConfigs) return undefined;
  const configs: AllConfigs = cloneDeep(inputConfigs)
    .filter((x: AllConfigs[number]) => {
      // Filter out configs that don't apply based on effective date
      if (effectiveDate < new Date(x.startEffectiveDate)) return false;

      // Filter out configs based on version
      if ('version' in x && x.version !== version) return false;

      return true;
    })
    // Sort by execution order
    .sort(
      (x: AllConfigs[number], y: AllConfigs[number]) => x.executionOrder - y.executionOrder,
      // filter is not typed correctly, issue progress tracking https://github.com/microsoft/TypeScript/issues/44373
    ) as AllConfigs;

  // Grab last config as our base.
  const responseConfig = [...configs]
    .reverse()
    .find(
      (x: AllConfigs[number]) =>
        isKeyOf('config', x) && configApplies(x, params as Record<string, string> | undefined),
    ) as (AllConfigs[number] & { config: NonNullable<AllConfigs[number]['config']> }) | undefined;
  if (!responseConfig) return undefined;

  // filter out other configs that aren't deltas or have a lower execution order than our base config
  const configsWithDeltas: Array<
    AllConfigs[number] & { deltas: Delta<ByPageId | MenuItem | string[] | string>[] }
  > = configs.filter(
    (x: AllConfigs[number]) => 'deltas' in x && x.executionOrder > responseConfig.executionOrder,
    // filter is not typed correctly, issue progress tracking https://github.com/microsoft/TypeScript/issues/44373
  ) as Array<AllConfigs[number] & { deltas: Delta<ByPageId | MenuItem | string[] | string>[] }>;

  // Check for any applicable delta operations
  configsWithDeltas.forEach((x) => {
    if (configApplies(x, params as Record<string, string> | undefined)) {
      x.deltas.forEach((delta: Delta<ByPageId | MenuItem | string[] | string>) => {
        // Apply delta operation
        if (delta.operation === 'remove' && isKeyOf(delta.target, responseConfig.config)) {
          delete responseConfig.config[delta.target];
        } else {
          if (delta.target) {
            set(responseConfig.config, delta.target, delta.value);
          } else {
            set(responseConfig, 'config', {
              ...(delta.value as Record<string, unknown>),
              ...responseConfig.config,
            });
          }
        }
      });
    }
  });

  return responseConfig.config;
};

const configApplies = (
  config: AllConfigs[number],
  filters: Record<string, string> | undefined,
): boolean => {
  if (!filters || !config.criteria) return true;
  for (const filterKey in filters) {
    // Config applies if either the filter key doesn't exist in the config,
    // or one of the value in the config equals the filter value
    const criteria = config.criteria;
    if (!isKeyOf(filterKey, criteria)) continue;
    const criteriaValue = criteria[filterKey];

    if (Array.isArray(criteriaValue)) {
      if (!criteriaValue.some((x) => x.toLowerCase() === filters[filterKey].toLowerCase())) {
        return false;
      }
    } else if (criteriaValue !== filters[filterKey]) return false;
  }

  return true;
};

export const getProductOrder = (): string[] => {
  return ['auto', 'home', 'renters'];
};
