import { datadogLog } from '@ecp/utils/logger';

import {
  getDalSessionId,
  getOfferProductsSelectedByType,
  getOfferSetId,
  updateOffers,
  wrapThunkActionWithErrHandler,
} from '@ecp/features/sales/shared/store';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';

import type { Acknowledgement } from '../../types';
import { getAcknowledgements, postAcknowledgement, singingCallBackURL } from './api';
import {
  getAcknowledgementsForCategory,
  getPNISignatureText,
  getSignatureEnvelopeId,
  getSignatureMethod,
  getSignatureMethodEmails,
  getSignatureRecipientId,
  getSNISignatureText,
} from './selectors';
import {
  setAcknowledgementsApiGetSuccess,
  setAcknowledgementsApiPostSuccess,
  setsigningUrlPostSuccess,
} from './slice';
import type { AcknowledgementCategory, AcknowledgementsRequest, SigningURLRequest } from './types';

export const fetchAcknowledgementResponse = wrapThunkActionWithErrHandler<
  AcknowledgementsRequest,
  void
>(
  ({ dalSessionId, products, category }) =>
    async (dispatch) => {
      let response: Awaited<ReturnType<typeof getAcknowledgements>>;

      // !TODO: Remove try-catch when cache issue is fixed for IAgree and Disclosures
      try {
        response = await getAcknowledgements({ dalSessionId, products, category });
      } catch (error) {
        // If 404 from QAPI, update offers and try getAcknowledgements again
        if (error instanceof SalesRequestError && error.response?.status === 404) {
          await dispatch(updateOffers());
          response = await getAcknowledgements({ dalSessionId, products, category }).catch(
            (error: SalesRequestError | Error) => {
              datadogLog({
                logType: 'error',
                message: `Error getting acknowledgements - ${error?.message}`,
                context: {
                  logOrigin: 'libs/features/sales/checkout/src/state/acknowledgements/thunks.ts',
                  functionOrigin: 'fetchAcknowledgementResponse',
                  message: error.message,
                  ...(error instanceof SalesRequestError && { errorData: error.errorData }),
                },
                error,
              });
              throw new Error('Error getting acknowledgements');
            },
          );
        } else {
          // If no 404 error just throw datadog error.
          datadogLog({
            logType: 'error',
            message: `Error getting acknowledgements - ${(error as Error).message}`,
            context: {
              logOrigin: 'libs/features/sales/checkout/src/state/acknowledgements/thunks.ts',
              functionOrigin: 'fetchAcknowledgementResponse',
              message: (error as Error).message,
            },
            error: error as Error,
          });
          throw new Error('Error getting acknowledgements');
        }
      }
      dispatch(setAcknowledgementsApiGetSuccess(response.payload));
    },
  'fetchAcknowledgementResponse',
);

export const postAcknowledgementsResponse = wrapThunkActionWithErrHandler<
  { categories: Exclude<AcknowledgementCategory, 'Reminder'>[] },
  void[]
>(
  ({ categories }) =>
    async (dispatch, getState) => {
      const state = getState();
      const dalSessionId = getDalSessionId(state);
      const offerSetId = getOfferSetId(state);
      const pniSignatureText = getPNISignatureText(state);
      const sniSignatureText = getSNISignatureText(state);
      const signatureMethod = getSignatureMethod(state);
      const signatureMethodEmails = getSignatureMethodEmails(state);
      const signatureEnvelopeId = getSignatureEnvelopeId(state);
      const signatureRecipientId = getSignatureRecipientId(state);
      const offerProducts = getOfferProductsSelectedByType(state);
      const acknowledgementsPromises = Object.values(offerProducts).map((product) => {
        // Structure to hold category-specific acknowledgements
        const categoryAcknowledgementsMap: Record<string, Acknowledgement[]> = {};
        // Organize acknowledgements by category
        categories.forEach((category) => {
          const acknowledgements = getAcknowledgementsForCategory(state, product, category);
          if (acknowledgements && acknowledgements.length > 0) {
            categoryAcknowledgementsMap[category] = acknowledgements;
          }
        });
        // Ensure there are acknowledgements to send
        if (dalSessionId && offerSetId && Object.keys(categoryAcknowledgementsMap).length) {
          return postAcknowledgement({
            dalSessionId,
            product,
            categoryAcknowledgementsMap, // Adjusted to send a map of categories to their acknowledgements
            pniSignatureText,
            sniSignatureText,
            signatureMethod,
            signatureMethodEmails,
            signatureEnvelopeId,
            signatureRecipientId,
          }).then(({ payload }) => {
            dispatch(setAcknowledgementsApiPostSuccess(payload));
          });
        } else {
          return Promise.resolve();
        }
      });

      return Promise.all(acknowledgementsPromises);
    },
  'postAcknowledgementsResponse',
);

export const signingCallBackURLResponse = wrapThunkActionWithErrHandler<
  SigningURLRequest,
  void | string
>(
  (signingRequest) => async (dispatch, getState) => {
    const state = getState();
    const dalSessionId = getDalSessionId(state);
    if (dalSessionId && signingRequest.callbackURL && signingRequest.personId) {
      const response = await singingCallBackURL({
        dalSessionId,
        signingRequest,
      }).catch((error: SalesRequestError | Error) => {
        datadogLog({
          logType: 'error',
          message: `Error getting callBackURL - ${error?.message}`,
          context: {
            logOrigin: 'libs/features/sales/checkout/src/state/acknowledgements/thunks.ts',
            functionOrigin: 'signingCallBackURLResponse',
            message: error.message,
            ...(error instanceof SalesRequestError && { errorData: error.errorData }),
          },
          error,
        });
        throw new Error('Error getting inPersonClick2Sign callBackURL');
      });
      if (response.status === 200) {
        dispatch(setsigningUrlPostSuccess(response.payload));
        const { signingURLResponses } = response.payload.data;

        return signingURLResponses.find((person) => person.personId === signingRequest.personId)
          ?.signingURL;
      }
    }

    return '';
  },
  'signingCallBackURLResponse',
);
