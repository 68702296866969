import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import {
  getGlobalError,
  updateGlobalError as updateGlobalErrorAction,
  updateInitializing as updateInitializingAction,
} from '@ecp/features/sales/shared/store';
import type { GlobalError, RootStore } from '@ecp/features/sales/shared/store/types';

interface StateProps {
  hasError: boolean;
  globalErrorText?: string;
}
interface DispatchProps {
  updateInitializing: (payload: boolean) => void;
  updateGlobalError: (payload: GlobalError) => void;
}

interface FallbackProps {
  text: string;
  unsetError(): void;
  clearErrorHash(): void;
}

interface OwnProps {
  FallbackComponent: React.ComponentType<FallbackProps>;
  children: React.ReactNode;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  hasError: boolean;
  stack: string | null;
}

// Error boundaries cannot be functional components, so using classic class-based components with '#componentDidCatch()'
// explicitly exportig ErrorBoundary as it is needed to test the non-connected component
export class ErrorBoundary extends PureComponent<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    children: null,
    error: false,
  };

  static getSessionReloadCount = (): number => sessionStorage.getItem('reloadCount') as number;

  static getNewState = (error: Error | null, info?: React.ErrorInfo): State => {
    const stack = `${error?.message ?? ''}${info?.componentStack ?? ''}`;
    // this error can be interpreted by the browser,
    // componentStack is pretty formatted but doesn't have line numbers.
    // eslint-disable-next-line no-console
    if (env.static.nodeEnv !== 'test') console.error(error);

    return {
      hasError: true,
      stack,
    };
  };

  static checkReloadCountExceeded = (): boolean => {
    let reloadCountExceeded = false;
    let sessionReloadCount = ErrorBoundary.getSessionReloadCount();

    if (sessionReloadCount && sessionReloadCount > 0) {
      ++sessionReloadCount;
      ErrorBoundary.setSessionReloadCount(sessionReloadCount);
      reloadCountExceeded = true;
    } else {
      sessionReloadCount = 1;
    }

    ErrorBoundary.setSessionReloadCount(sessionReloadCount);

    return reloadCountExceeded;
  };

  static setSessionReloadCount = (reloadCount: number): void =>
    sessionStorage.setItem('reloadCount', reloadCount);

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      stack: '',
    };
  }

  unsetError = (): void => {
    this.setState({ hasError: false, stack: null });
  };

  clearErrorHash = (): void => {
    window.history.replaceState(null, '', `${window.location.pathname}${window.location.search}`);
  };

  // !TODO revisit this one and Error boundary in general
  resetErrorOrClearLocationHash(): void {
    if (this.props?.hasError) {
      this.props.updateGlobalError({ hasError: false, requestId: '', transactionId: '' });
      this.unsetError();
    } else if (window.location.hash !== '') {
      this.clearErrorHash();
    }
  }

  override componentDidMount(): void {
    const { hasError, updateInitializing } = this.props;
    if (hasError) {
      updateInitializing(false);
      this.setState(ErrorBoundary.getNewState(null));
    } else if (window.location.hash !== '') {
      this.clearErrorHash();
    }
    window.addEventListener('popstate', this.resetErrorOrClearLocationHash);
  }

  override componentDidUpdate({ hasError: prevHasError }: Props): void {
    const { hasError, updateInitializing } = this.props;
    if (hasError && !prevHasError) {
      updateInitializing(false);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(ErrorBoundary.getNewState(null));
    }
  }

  override componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const { updateInitializing } = this.props;
    updateInitializing(false);
    // NOTE: react sometimes doesn't send an error object,
    // but the second parameter info would have the call stack
    // we already log this parameter elsewhere.
    // (it is likely not sending the error object because
    // it could not get it from the browser).
    const newState = ErrorBoundary.getNewState(error, info);
    this.setState(newState);
  }

  override componentWillUnmount(): void {
    window.removeEventListener('popstate', this.resetErrorOrClearLocationHash);
  }

  override render(): React.ReactNode {
    const { hasError, stack } = this.state;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { FallbackComponent, children = null, globalErrorText = '' } = this.props;

    if (!hasError) return children;

    return (
      <FallbackComponent
        text={stack || globalErrorText}
        unsetError={this.unsetError}
        clearErrorHash={this.clearErrorHash}
      />
    );
  }
}

const mapStateToProps = (state: RootStore): StateProps => ({
  hasError: getGlobalError(state).hasError,
  globalErrorText: getGlobalError(state).text,
});

const mapDispatchToProps: DispatchProps = {
  updateInitializing: (payload) => updateInitializingAction(payload),
  updateGlobalError: (payload) => updateGlobalErrorAction(payload),
};

export default connect<StateProps, DispatchProps, OwnProps, RootStore>(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary);
