import { useCallback } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { OfferType } from '@ecp/features/sales/shared/constants';
import {
  getOfferSetId,
  getRecalculateValue,
  isAnyApiInProgress,
  PATCH_API_PREFIX,
  ProofDraftSection,
  setShouldRecalc,
  submitProofDraft,
  updateOffers,
  updateOfferType,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

export const useRecalculateOffer = (): {
  isPatchingAnswers: boolean;
  recalculate: boolean;
  shouldRecalc: (value: boolean) => void;
  retrieveOffer: () => Promise<void>;
} => {
  const dispatch = useDispatch();
  const offerSetId = useSelector(getOfferSetId);
  const shouldProofDraft = flagValues[FeatureFlags.SHOULD_PROOF_DRAFT_CUSTOMIZE_COVERAGES];

  // Check if there are pending answer patches before recalc.
  const isPatchingAnswers = useSelector((state: RootStore) =>
    isAnyApiInProgress(state, PATCH_API_PREFIX),
  );
  const recalculate = useSelector(getRecalculateValue);

  const shouldRecalc = useCallback(
    (value: boolean): void => {
      dispatch(setShouldRecalc(value));
    },
    [dispatch],
  );

  const retrieveOffer = useCallback(async () => {
    if (!offerSetId) {
      datadogLog({
        logType: 'error',
        message: 'offerSetId missing',
        context: {
          logOrigin: 'libs/features/sales/shell/src/util/useRecalculateOffer.ts',
          functionOrigin: 'retrieveOffer/useRecalculateOffer',
        },
      });
      throw new Error('offerSetId missing');
    }

    // Advance specific begins ADVECP-4010
    // This is to call ProofDraft which triggers SAPI to call Save operation to policyAPI to first save updated coverages before rating
    // This is a temp solution to improve performance between PLPC and UI
    if (shouldProofDraft)
      await dispatch(
        submitProofDraft({
          section: ProofDraftSection.CUSTOMIZED_COVERAGES,
        }),
      );
    // Set offer type on answers so that SAPI knows when to rate vs when not to rate.
    // When OFFER_WITHOUT_PREMIUM SAPI would just return coverages but not premiums
    // Premiums are returned only when OFFER_WITH_PREMIUM
    await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITH_PREMIUM }));
    // Advance specific ends ADVECP-4010

    await dispatch(updateOffers());
  }, [offerSetId, shouldProofDraft, dispatch]);

  return {
    isPatchingAnswers,
    recalculate,
    retrieveOffer,
    shouldRecalc,
  };
};
