import type { TypographyProps } from '@mui/material';
import { Grid, Typography } from '@mui/material';

import { TooltipWithIcon } from '@ecp/components';

import { useStyles } from './PageHeader.styles';

export type PageHeaderProps = React.PropsWithChildren<
  Pick<TypographyProps, 'align'> & {
    className?: string;
    subTitle?: React.ReactNode;
    subTitleHelpText?: React.ReactNode;
    /** @default 'normal' */
    variant?: 'compact' | 'normal' | 'bannerHeading' | 'noheading';
    actionBarItems?: React.ReactNode;
  }
>;

export const PageHeader: React.FC<PageHeaderProps> = ({
  align,
  children,
  className,
  subTitle,
  subTitleHelpText,
  variant = 'normal',
  actionBarItems,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(
        classes.root,
        variant === 'compact' && classes.compact,
        variant === 'bannerHeading' && classes.banner,
      )}
      title={`pageHeader ${variant}`}
    >
      {variant === 'bannerHeading' || variant === 'noheading' ? (
        <Grid className={className}>{children}</Grid>
      ) : (
        <div className={actionBarItems ? classes.titleWithActionbar : ''}>
          <Typography
            align={align}
            className={actionBarItems ? cx(className, classes.titleTextWithActionBar) : className}
            variant='h1'
          >
            {children}
          </Typography>
          {actionBarItems}
        </div>
      )}
      {subTitle && (
        <Typography align={align} className={cx(className, classes.subTitle)} variant='subtitle'>
          {subTitle} {subTitleHelpText && <TooltipWithIcon title={subTitleHelpText} />}
        </Typography>
      )}
    </div>
  );
};
