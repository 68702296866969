import { trackClick } from '@ecp/utils/analytics/tracking';
import { castToNumberOrNull } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { Radio, RadioGroup } from '@ecp/components';
import { updateAnswers } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';
import type { Option } from '@ecp/types';

import {
  getPaymentOptions,
  getPaymentPlanForProduct,
  setPaymentPlan,
} from '../../../state/paymentoptions';
import { useSetDefaultPaymentPlanForProduct } from '../../../util';
import type { PaymentOptionProps } from './PaymentOption';
import { PaymentOption } from './PaymentOption';
import { useStyles } from './PaymentTerm.styles';

export interface PaymentTermProps {
  paymentOptions: PaymentFieldOption[];
  product: Product;
  coverageType?: ProductName;
}

export interface GoogleAnalytics {
  trackingNameButton: string;
  trackingLabelButton: string;
  trackingNamePaymentInfo: string;
  trackingLabelPaymentInfo: string;
  trackingNamePaymentInfoClose: string;
  trackingLabelPaymentInfoClose: string;
  trackingNamePaymentInfoX: string;
  trackingLabelPaymentInfoX: string;
}

export interface PaymentFieldOption {
  option: Option;
  content: Omit<PaymentOptionProps, 'option' | 'control'>;
  googleAnalytics: GoogleAnalytics;
}

export const PaymentTerm: React.FC<PaymentTermProps> = (props) => {
  const { paymentOptions, product, coverageType } = props;

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const paymentOptionsAvailable = useSelector(getPaymentOptions)[product];
  const eligibleForCustomPay = paymentOptionsAvailable.carrierPaymentOptions.some(
    (option) =>
      (option.paymentPlan === 'Twelve Months' || option.paymentPlan === 'Six Months') &&
      option.eligibleForCustomDownPayment,
  );
  const actionOnComplete = useEvent(async (value: string) => {
    await dispatch(setPaymentPlan({ product, paymentPlan: value }));

    // Advance specific - These values need to updated to statics so to use for max and min value validation for custom payment amount.
    if (value === 'Custom') {
      const monthlyPayAmountsForEFT = paymentOptionsAvailable.carrierPaymentOptions.find(
        (option) =>
          option.paymentType === 'EFT' &&
          (option.paymentPlan === 'Twelve Months' || option.paymentPlan === 'Six Months'),
      );
      const minDownPayAmount = castToNumberOrNull(monthlyPayAmountsForEFT?.downPayment);
      const maxDownPayAmount = castToNumberOrNull(monthlyPayAmountsForEFT?.totalPremium);

      await dispatch(
        updateAnswers({
          answers: {
            [`static.checkout.${coverageType}.paymentPlan`]: value,
            [`static.checkout.${coverageType}.customPaymentAmount`]: minDownPayAmount,
            [`static.checkout.${coverageType}.minCustomPaymentAmount`]: minDownPayAmount,
            [`static.checkout.${coverageType}.maxCustomPaymentAmount`]: maxDownPayAmount,
          },
        }),
      );
    }
    trackClick({
      action: paymentOptions.find((paymentOption) => paymentOption.option.value === value)
        ?.googleAnalytics.trackingNameButton,
      label: paymentOptions.find((paymentOption) => paymentOption.option.value === value)
        ?.googleAnalytics.trackingLabelButton,
    });
  });

  const paymentOptionValue = useSelector((state: RootStore) =>
    getPaymentPlanForProduct(state, product),
  );

  useSetDefaultPaymentPlanForProduct({ paymentOptions, product });
  const paymentTermLabel =
    paymentOptions?.length === 1
      ? ''
      : coverageType && coverageType.toLowerCase() === 'auto'
      ? 'Payment term'
      : 'Select a payment term';

  return (
    <RadioGroup
      variant='classic'
      label={paymentTermLabel}
      value={paymentOptionValue}
      name={`paymentTerm-${product}`}
      actionOnComplete={actionOnComplete}
      className={classes.root}
    >
      {paymentOptions.map((item, index) => {
        const id = `PaymentTerm-${product}-${item.option.value}`;

        return (
          <div key={id} className={index === 0 ? undefined : classes.paymentOptionContainer}>
            <PaymentOption
              key={item.option.value}
              paymentOptions={paymentOptions}
              control={
                <Radio
                  id={id}
                  className={paymentOptions?.length === 1 ? classes.hideRadioButton : undefined}
                />
              }
              htmlFor={id}
              option={item.option}
              googleAnalytics={item.googleAnalytics}
              checked={item.option.value === paymentOptionValue}
              isCustomPay={eligibleForCustomPay}
              coverageType={coverageType}
              {...item.content}
            />
          </div>
        );
      })}
    </RadioGroup>
  );
};
