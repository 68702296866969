import type { Configuration, SilentRequest } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

import { noop } from '@ecp/utils/common';
import { getTime } from '@ecp/utils/date';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';

import { azureAuth, initialize as initializeAzureAuth } from '../azureAuth';
import { TokenUtil } from '../token';
import { AuthError } from '../util';
import { fetchAgentTokens, fetchAgentTokensFromRefreshToken } from './api';
import type { AuthorizedLevel } from './util';
import { AgentAuthUtil, isRelateFlow, setRelateFlowSessionStorageItems } from './util';

let instance: AgentAuthUtil;
let initialized = false;

const isASP = env.static.expId === '2860';

/**
 * Initialize with config values and create AgentAuthUtil instance.
 * Make sure to initialize `storage` before calling this function.
 *
 * @throws {AuthError} Calling this function more than once throws.
 */
const initializeAsc = (agentAuthBaseUrl: string, expId: string): void => {
  if (initialized) throw new AuthError(`${AgentAuthUtil.name} has already been intialized.`);

  const token = new TokenUtil(getTime);
  const fetchAgentTokensApi = fetchAgentTokens(agentAuthBaseUrl, expId);
  const fetchAgentTokensFromRefreshTokenApi = fetchAgentTokensFromRefreshToken(agentAuthBaseUrl);

  instance = new AgentAuthUtil(
    token,
    fetchAgentTokensApi,
    fetchAgentTokensFromRefreshTokenApi,
    sessionStorage,
  );
};

// TODO Declared statically, otherwise need to add it to agent token value as a custom claim (which we most likely won't)
// This will be build in EDSP-14420
const authorizedLevels: AuthorizedLevel[] = isASP
  ? [
      {
        id: '100',
        name: 'Amfam Advance',
        segments: [
          {
            id: '1001',
            name: 'Amfam Advance',
            accounts: [
              {
                id: '2860',
                name: '2860:Amfam Advance Agent',
                experienceId: '2860',
              },
            ],
          },
        ],
      },
    ]
  : [
      {
        id: '559',
        name: 'Costco Wholesale',
        segments: [
          {
            id: '5590',
            name: 'Costco Wholesale Direct',
            accounts: [
              {
                id: '55900',
                name: '55900:Costco Wholesale Direct',
                experienceId: '7192',
              },
            ],
          },
        ],
      },
    ];

const instanceAzure: AgentAuthUtil = Object.defineProperties(
  {},
  {
    token: {
      async get(): Promise<string> {
        const result = await azureAuth.authResult;

        // There is also result.accessToken available
        if (result) return result.idToken;

        throw new AuthError('agentAuth.token did not return any token.');
      },
    },
    isAuth: {
      get(): boolean {
        // TODO we typically redirect to PagePath.AGENT_LOGIN whenever this returns false
        // but Azure upon successful login automatically redirects to the last visited path+query+hash
        // so we would lose this ability and will always get redirected to PagePath.AGENT_LOGIN
        // so instead of return azureAuth.isAuth; we do this for now:
        if (azureAuth.isAuth) return true;
        // TODO Hack to achieve two results (while not touching any base sales implementation):
        //      1) automatic login and re-login
        //      2) automatic redirect from Azure to the app page from which redirect to Azure was initiated
        else return Boolean(azureAuth.authResult);
      },
    },
    authorizedLevels: {
      get() {
        return authorizedLevels;
      },
    },
    logout: {
      // TODO implement?
      value: noop,
    },
    addEventListener: {
      // TODO implement?
      value: noop,
    },
  },
) as AgentAuthUtil;

const initialize = async ({
  expId,
  clientId,
  authority,
  redirectEndpoint,
}: {
  expId: string;
  clientId: string;
  authority: string;
  redirectEndpoint: string;
}): Promise<void> => {
  if (initialized) throw new AuthError('agentAuthAdv has already been intialized.');

  const msalConfig: Configuration = {
    auth: {
      clientId,
      authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: `${window.location.origin}/${redirectEndpoint}`,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    // TODO Keep it here so that we can enable @azure/msal-browser logging to the console, which is actually better to control via app log level in the env file so that our logging is uniform across the whole app
    // system: { loggerOptions: { loggerCallback: console.log, logLevel: LogLevel.Verbose } },
  };

  const loginRequest: SilentRequest = {
    scopes: [
      'email',
      'offline_access',
      'openid',
      'profile',
      // TODO Not sure if SAPI v4 will require any api scope, keep it here for now until we know more
      // `api://${env.azureClientId}/AgentTooling`,
    ],
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  await initializeAzureAuth({ msalInstance, loginRequest });

  initialized = true;
};

if (isRelateFlow) {
  instance = instanceAzure;
  setRelateFlowSessionStorageItems();
}
if (isASP) {
  instance = instanceAzure;
}

export { initializeAsc, initialize, instance };
