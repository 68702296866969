import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { location } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import {
  ADDRESS_PREFILL_SUCCESSFUL,
  LINE_OF_BUSINESS,
  LOB_ORDER,
  STATE_CODE_PREFIX,
} from '@ecp/features/sales/shared/constants';
import {
  fetchCityAndStateSuggestionsThunkAction,
  getDalSessionId,
  getLobOrder,
  getPrimaryInsuredAddressRef,
  jumpToPage,
  setZipLookupBypassed,
  submitPolicyType,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { Answers } from '@ecp/features/sales/shared/types';
import {
  getLineOfBusinessFromQuery,
  isLineOfBusinessSupported,
} from '@ecp/features/shared/product';

import metadata from './metadata';

/**
 * This is an initial fix to read query string params and handle
 * routing based on these parameters in the initialization. Will need
 * to revisit to line up with LandingPage better.
 *
 * Read query string params and bypass the landing page if it's not a
 * chase link, not a retrieve, and zip code and product are valid.
 *
 * NOTE: Applicable only to consumer experience.
 */
export const initializeUsingQuery: ThunkAction<Promise<void>> = async (dispatch, getState) => {
  if (env.static.isAgent) return;

  const dalSessionId = getDalSessionId(getState());

  if (!dalSessionId) return;

  const {
    city,
    emailUUID,
    line1,
    line2,
    product,
    retrieve,
    showRetrieve,
    zip: zipcode,
  } = location.search;

  // Return early if there's nothing related to inquiry in the query string params
  if (!product && !zipcode && !emailUUID && !showRetrieve && !retrieve) return;

  const state = getState();
  const lineOfBusiness = getLineOfBusinessFromQuery(product);
  const primaryInsuredAddressRef = getPrimaryInsuredAddressRef(state);
  const answersToBeUpdated: Answers = {};

  if (isLineOfBusinessSupported(lineOfBusiness)) {
    answersToBeUpdated[LINE_OF_BUSINESS] = lineOfBusiness;
  }

  if (line1) answersToBeUpdated[`${primaryInsuredAddressRef}.line1`] = line1;
  if (line2) answersToBeUpdated[`${primaryInsuredAddressRef}.line2`] = line2;
  if (city) answersToBeUpdated[`${primaryInsuredAddressRef}.city`] = city;
  if (zipcode) answersToBeUpdated[`${primaryInsuredAddressRef}.zipcode`] = zipcode;

  // Zillow scenario only at this moment where we expect full address in the URL
  if (line1 && city && zipcode) return;

  const cityAndStateSuggestionResult = await dispatch(
    fetchCityAndStateSuggestionsThunkAction(zipcode),
  );
  const cityAndStateSuggestion = cityAndStateSuggestionResult?.response;
  dispatch(setZipLookupBypassed(!!cityAndStateSuggestion?.bypass));

  // For CA GMI, we dont patch state and city to avoid 400 from SAPI
  // And land of landing page
  const allowCARedirect = env.caConnectRedirectHomeUrl || env.caConnectRedirectAutoUrl;
  const isCARedirect = allowCARedirect && cityAndStateSuggestion?.state === 'CA';

  if (cityAndStateSuggestion?.state && !isCARedirect) {
    answersToBeUpdated[
      `${primaryInsuredAddressRef}.state`
    ] = `${STATE_CODE_PREFIX}${cityAndStateSuggestion.state}`;
  }

  if (cityAndStateSuggestion?.city && !isCARedirect) {
    answersToBeUpdated[`${primaryInsuredAddressRef}.city`] = cityAndStateSuggestion.city;
  }

  // Zillow catch-all scenario if there's not enough address key-values in the URL
  answersToBeUpdated[ADDRESS_PREFILL_SUCCESSFUL] = false;

  if (isLineOfBusinessSupported(lineOfBusiness)) {
    const lobOrder = getLobOrder(state, lineOfBusiness);

    answersToBeUpdated[LOB_ORDER] = lobOrder;
  }

  await dispatch(updateAnswers({ answers: answersToBeUpdated }));

  // Only redirect to page other than landing if we have valid lob, zip, REDIRECT_TO_PNI flag is true and not retrieve
  const shouldRedirectToPni: boolean = flagValues[FeatureFlags.REDIRECT_TO_PNI];
  if (
    zipcode &&
    isLineOfBusinessSupported(lineOfBusiness) &&
    cityAndStateSuggestion?.state &&
    metadata.redirectPath &&
    showRetrieve !== 'true' &&
    shouldRedirectToPni &&
    !retrieve
  ) {
    await dispatch(submitPolicyType());
    await dispatch(jumpToPage({ page: metadata.redirectPath, skipError: true }));
  }
};

export const composeFullStreetAddress = (...args: [string, string | undefined]): string =>
  args.join(' ');
