/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ConfigState, PageFlow } from './types';

const init = {
  id: '',
  value: {
    menuItems: [],
    navigationOrder: [],
    byPageId: {},
    pageFlows: [],
  },
};

const initialState: ConfigState = {
  intro: init,
  products: [],
  secondaryNamedInsured: init,
  homePrefillSummary: init,
  initialSummary: init,
  deltas: [],
  thirdPartyInterest: init,
  thirdPartyReports: init,
  finalSummary: init,
  purchase: init,
  productOrder: [],
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setIntroApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.intro = payload;
    },
    setProductApiGetSuccess(state, { payload }: PayloadAction<PageFlow[]>) {
      state.products = payload;
    },
    setSecondaryNamedInsuredApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.secondaryNamedInsured = payload;
    },
    setHomePrefillSummaryApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.homePrefillSummary = payload;
    },
    setInitialSummaryApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.initialSummary = payload;
    },
    setDeltaApiGetSuccess(state, { payload }: PayloadAction<PageFlow[]>) {
      state.deltas = payload;
    },
    setThirdPartyInterestApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.thirdPartyInterest = payload;
    },
    setThirdPartyReportsApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.thirdPartyReports = payload;
    },
    setFinalSummaryApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.finalSummary = payload;
    },
    setPurchaseApiGetSuccess(state, { payload }: PayloadAction<PageFlow>) {
      state.purchase = payload;
    },
  },
});

export const {
  setIntroApiGetSuccess,
  setProductApiGetSuccess,
  setSecondaryNamedInsuredApiGetSuccess,
  setHomePrefillSummaryApiGetSuccess,
  setInitialSummaryApiGetSuccess,
  setDeltaApiGetSuccess,
  setThirdPartyInterestApiGetSuccess,
  setThirdPartyReportsApiGetSuccess,
  setFinalSummaryApiGetSuccess,
  setPurchaseApiGetSuccess,
} = configSlice.actions;
