import { memo } from 'react';

import { Grid } from '@mui/material';

import { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import {
  getDalSessionId,
  getErrorDetailsForOffers,
  getGlobalError,
  getInquiryId,
  getOfferSetId,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { ErrorDetails } from '../ErrorDetails';
import { useStyles } from './DevDiagnostic.styles';

interface Props {
  errorReason?: string;
  details?: string;
}

export const DevDiagnostic: React.FC<Props> = memo((props) => {
  const { details, errorReason } = props;
  const { classes } = useStyles();

  const dalSessionId = useSelector(getDalSessionId);
  const inquiryId = useSelector(getInquiryId);
  const offerId = useSelector(getOfferSetId);
  const { requestId, transactionId, text } = useSelector(getGlobalError);
  const errorDetails = useSelector(getErrorDetailsForOffers);

  const showDnqCnqErrorDetails =
    errorReason === OfferStatusCode.CNQ || errorReason === OfferStatusCode.DNQ;

  return showDnqCnqErrorDetails ? (
    <ErrorDetails
      dalSessionId={dalSessionId}
      inquiryId={inquiryId}
      offerSetId={offerId}
      xRequestId={requestId}
      transactionId={transactionId}
      errorDetails={errorDetails}
    />
  ) : (
    <Grid container className={classes.root} alignItems='flex-start'>
      <Grid item className={classes.wrapword}>
        <p>sales-websession-id: {dalSessionId}</p>
      </Grid>
      <Grid item className={classes.wrapword}>
        <p>INQUIRYID: {inquiryId}</p>
      </Grid>
      <Grid item className={classes.wrapword}>
        <p>OFFERID: {offerId}</p>
      </Grid>
      <Grid item className={classes.wrapword}>
        <p>X-Request-Id: {requestId}</p>
      </Grid>
      <Grid item className={classes.wrapword}>
        <p>Transaction-Id: {transactionId}</p>
      </Grid>
      <Grid item className={classes.wrapword}>
        <p>{details}</p>
      </Grid>
      <br />
      <Grid item className={classes.wrapword}>
        <p>{text}</p>
      </Grid>
    </Grid>
  );
});
