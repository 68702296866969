import { useCallback, useRef } from 'react';

import { Box, Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { castToBoolean } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, RadioGroupWithOptions } from '@ecp/components';
import { useGetConditionValues, useGetFields, useInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { getLineOfBusinessOrder, useField, useForm } from '@ecp/features/sales/shared/store';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconCardAuto, IconCardHome } from '@ecp/themes/base';
import type { Field } from '@ecp/types';

import { AutoTPIPageForm } from './AutoTPIPageForm';
import { HomeTPIPageForm } from './HomeTPIPageForm';
import { useStyles } from './ThirdPartyInterestForm.styles';

export interface ThirdPartyInterestFormProps {
  onNext: () => void;
}
interface ContentProps {
  selectedProduct: string;
}
export const ThirdPartyInterestForm: React.FC<ThirdPartyInterestFormProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const hasThirdPartyInterestInd: Field = useField('static.delta.hasThirdPartyInterestInd');
  const selectedProducts = useSelector(getLineOfBusinessOrder);
  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();
  useInitValues({ [hasThirdPartyInterestInd.key]: false });
  const { isPatchFormInProgress } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });
  const handleSubmit = useCallback(async () => {
    dispatch(updatePageStatus(NavStatus.VALID));
    await onNext();
  }, [dispatch, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container className={classes.content}>
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...hasThirdPartyInterestInd.props}
              label='Do any policies have interested parties that need to be included? (optional)'
              variant='yesNoButton'
              trackingName='PriorInsurance'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          {castToBoolean(hasThirdPartyInterestInd.value) && (
            <>
              <Divider className={classes.divider} />
              <GridItem>
                <p className={classes.contentTitle}>Add an interested party where applicable.</p>
              </GridItem>
              {selectedProducts.map((selectedProduct) => (
                <GridItem xs={12} key={selectedProduct}>
                  <Content selectedProduct={selectedProduct} />
                </GridItem>
              ))}
            </>
          )}
          <Divider className={classes.divider} />
          <GridItem xs={12}>
            <Button
              className={classes.next}
              variant='primary'
              onClick={handleSubmit}
              trackingName='sni_details_continue'
              trackingLabel={GoogleAnalyticsLabels.CONTINUE}
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};

const Content: React.FC<ContentProps> = (props): React.ReactElement => {
  const { classes } = useStyles();
  const { selectedProduct } = props;

  const isAutoProductSwapStateRollout = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];

  const label = (icon: React.ReactElement, label: string): React.ReactElement => {
    return (
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>{icon}</div>
        </Box>
        <p className={classes.productTitle}>{label} Policy</p>
      </GridItem>
    );
  };

  return (
    <>
      {(selectedProduct === LineOfBusiness.AUTO || selectedProduct === LineOfBusiness.BUNDLE) &&
        !isAutoProductSwapStateRollout && (
          <GridItem>
            {label(<IconCardAuto />, 'Auto')}
            <AutoTPIPageForm />
          </GridItem>
        )}
      {(selectedProduct === LineOfBusiness.HOME || selectedProduct === LineOfBusiness.BUNDLE) && (
        <GridItem>
          {label(<IconCardHome />, 'Home')}
          <HomeTPIPageForm />
        </GridItem>
      )}
    </>
  );
};
