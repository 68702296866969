import { createReducer } from '@reduxjs/toolkit';

import { setProducerApiPostSuccess } from './actions';
import type { ProducerState } from './types';

const ProducerInitialState: ProducerState = {
  producer: {
    name: '',
    addresses: [],
    phones: [],
    emails: [],
  },
};

export const producerReducer = createReducer(ProducerInitialState, (builder) => {
  builder.addCase(setProducerApiPostSuccess, (state, { payload }) => {
    state.producer = payload;
  });
});
