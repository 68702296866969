import { useCallback, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';

import { emptyArray, isDlNumberMasked, isTruthy } from '@ecp/utils/common';
import { DateConstants } from '@ecp/utils/date';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { useGetFieldRefs } from '@ecp/features/sales/form';
import {
  DRIVERS_REF,
  INVALID_LICENSE_NUMBER_MSG,
  PrefillFlow,
  SECONDARY_INSURED_PERSON_REF,
} from '@ecp/features/sales/shared/constants';
import type { FormCallbacks } from '@ecp/features/sales/shared/store';
import {
  createRef,
  deleteAnswers,
  deleteInquiryRef,
  getAllDriverRefs,
  getAllValues,
  getAnswer,
  getAnswers,
  getCurrentFlows,
  getDriver,
  getDriverAutoDeltaQuestions,
  getDriverDiscountMetadata,
  getDriverFields,
  getDriverIsPrimaryInsured,
  getDriverIsSNI,
  getDriverQuestions,
  getDrivers,
  getDriversFields,
  getDriversValues,
  getDriverValues,
  getField,
  getFieldValue,
  getIncidentRefsForDriver,
  getInferredValueForYesNoButton,
  getInquiryLoaded,
  getNavTracking,
  getOfferProductsSelectedByType,
  getOffersExist,
  getPersonRefForDriver,
  getPrimaryInsuredPersonRef,
  getPriorInsuranceRefsForDriver,
  getQuestion,
  getReferencePagePaths,
  getSecondaryInsuredPersonRef,
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  setPageStatusRemoved,
  updateAddedRef,
  updateAnswers,
  updateSecondaryPolicyHolderByDriver,
  useField,
  useFieldWithPrefix,
  useForm,
} from '@ecp/features/sales/shared/store';
import type {
  RootStore,
  ThunkAction,
  ValidateFormParams,
  ValidateFormResult,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AnswerValue,
  Condition,
  Driver,
  DriverDiscountMetadata,
  DriversLicense,
  FieldsDef,
  Incident,
  QuestionsMetadata,
} from '@ecp/features/sales/shared/types';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';
import type { Field, Fields, Question } from '@ecp/types';

import { postDriverLicenseValidation } from '../../api';
import {
  DRIVER_INTL,
  DRIVER_UNLICENSED,
  DRIVER_VALID,
  INVALID_DL_DRIVER_REFS,
} from '../../constants';
import type { AddIncidentResult } from '../../state';
import {
  isFutureIncidentDate,
  useAddIncident,
  useAndEnsureCurrentIncidentRef,
  useAttachIncidentToDriver,
  useIncident,
  useIncidents,
  useRemoveIncident,
} from '../../state';

export const INCIDENT_TYPE_VIOLATION = 'INCIDENT_TYPE.VIOLATION';
export const INCIDENT_TYPE_CLAIM_OR_ACCIDENT = 'INCIDENT_TYPE.CLAIM_OR_ACCIDENT';

export const useIncidentRefForDriver = (driverRef: string): string[] =>
  useSelector((state: RootStore) => getIncidentRefsForDriver(state, { driverRef }));

export const usePriorInsuranceRefForDriver = (driverRef: string): string[] =>
  useSelector((state: RootStore) => getPriorInsuranceRefsForDriver(state, { driverRef }));

export const useDriver = (driverRef: string): Driver => {
  const allValues = useSelector(getAllValues);
  const personRef = useSelector(getPersonRefForDriver(driverRef));
  const currentInsuranceRef = usePriorInsuranceRefForDriver(driverRef)[0];
  const getQuestionFn = useSelector(
    (state: RootStore) =>
      (key: string, questionKey: string): Question =>
        getQuestion(key, questionKey)(state),
  );

  return getDriver(driverRef, personRef as string, currentInsuranceRef, allValues, getQuestionFn);
};

export interface AddDriverResult {
  // the api has received the update
  done: Promise<[string, string]>;
  // the refs used
  driverRef: string;
  personRef: string;
}
// adds a driver to the store, and updates the api about the change
export const useAddDriver = (): (() => AddDriverResult) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const allDriverRefs = useSelector(getAllDriverRefs);
  const noExistingDrivers = allDriverRefs.length === 0;
  // NOTE: currently implemented to return "undefined" or "null" when missing
  const primaryInsuredRef = useSelector(getPrimaryInsuredPersonRef);

  return useCallback(() => {
    // FIXME: assume inquiry is loaded?
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/driverModelUtil.ts',
          functionOrigin: 'useAddDriver/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const driverRef = dispatch(createRef('driver'));
    // if no person id, need to create new person ref as well
    const personRef: string = noExistingDrivers ? primaryInsuredRef : dispatch(createRef('person'));

    const updateDriverWork = dispatch(
      updateAnswers({
        answers: {
          [`${driverRef}.person.ref`]: personRef,
        },
      }),
    );

    const updateDriversWork = dispatch(updateAddedRef({ type: DRIVERS_REF, newRef: driverRef }));

    const done = Promise.all([updateDriverWork, updateDriversWork]).then((): [string, string] => [
      driverRef,
      personRef,
    ]);

    return {
      done,
      driverRef,
      personRef,
    };
  }, [inquiryLoaded, dispatch, noExistingDrivers, primaryInsuredRef]);
};

// returns the current driver ref, and if it's not set,
// makes sure one is created via addDriver
export const useAndEnsureCurrentDriverRef = (
  driverRefParam?: string,
): { isPni: boolean; driverRef: string | undefined } => {
  const drivers = useSelector(getAllDriverRefs);
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const driversLastRef = drivers[drivers.length - 1];
  const driverRef = driverRefParam || driversLastRef; // if no passed-in driverRef , use the last driverRef.

  // FIXME: assume inquiry is loaded?
  if (!inquiryLoaded) {
    datadogLog({
      logType: 'error',
      message: 'inquiry not loaded',
      context: {
        logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/driverModelUtil.ts',
        functionOrigin: 'useAndEnsureCurrentDriverRef',
      },
    });
    throw new Error('inquiry not loaded');
  }

  // FIXME: we should avoid using useEffect here.
  useEffect(() => {
    dispatch(createFirstDriver());
  }, [dispatch]);

  const isPni = useSelector(getDriverIsPrimaryInsured(driverRef));

  return { isPni, driverRef };
};

// FIX IT: The following 4 useDriverXXX hooks are not referenced anywhere.
export const useDriverValues = (ref: string): Driver => {
  return useSelector((state: RootStore) => getDriverValues(state, ref));
};

export const useDriversValues = (refs: string[]): Record<string, Driver> => {
  return useSelector((state: RootStore) => getDriversValues(state, refs));
};

export const useDriverFields = (ref: string): FieldsDef<Driver> => {
  const dispatch = useDispatch();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  return useSelector((state: RootStore) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getDriverFields(state, ref, dispatch, autoOfferProduct!),
  );
};

export const useDriversFields = (refs: string[]): Record<string, FieldsDef<Driver>> => {
  const dispatch = useDispatch();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  return useSelector((state: RootStore) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getDriversFields(state, refs, dispatch, autoOfferProduct!),
  );
};

export const useGetDriverDiscountMetadata = (
  drivers: Driver[],
  stateCode: string,
): DriverDiscountMetadata[] => {
  return useSelector((state: RootStore) => getDriverDiscountMetadata(state, drivers, stateCode));
};

export const useDriverAutoDeltaMetadata = (
  drivers: Driver[],
  pniRef: string,
): { metadata: QuestionsMetadata; fields: Fields; driver: Driver }[] => {
  const dispatch = useDispatch();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  return useSelector((state: RootStore) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getDriverAutoDeltaQuestions(state, drivers, dispatch, autoOfferProduct!, pniRef),
  );
};

const driverFormKeys: {
  [key in PrefillFlow]: Array<{ type: 'person' | 'driver' | 'priorInsurance'; key: string }>;
} = {
  [PrefillFlow.LONG]: [
    { type: 'person', key: 'firstName' },
    { type: 'person', key: 'lastName' },
    { type: 'person', key: 'dob' },
    { type: 'person', key: 'gender' },
    { type: 'person', key: 'married' },
    { type: 'driver', key: 'license.ageFirstLicensed' },
    { type: 'driver', key: 'license.status' },
    { type: 'person', key: 'phone' },
    { type: 'person', key: 'email' },
    { type: 'person', key: 'sr22FinancialRespFiling' },
    { type: 'person', key: 'incidents' },
    { type: 'driver', key: 'hasIncidents' },
    { type: 'driver', key: 'hasPriorInsurance' },
    { type: 'priorInsurance', key: 'carrierName' },
    { type: 'priorInsurance', key: 'carrierNameText' },
    { type: 'priorInsurance', key: 'AMBestNumber' },
    { type: 'priorInsurance', key: 'years' },
    { type: 'priorInsurance', key: 'inceptionDate' },
    { type: 'priorInsurance', key: 'policyEndDate' },
    { type: 'priorInsurance', key: 'riskType' },
    { type: 'priorInsurance', key: 'lapse' },
    { type: 'priorInsurance', key: 'coverages.policy.bodilyInjury' },
    { type: 'priorInsurance', key: 'coverages.vehicle.comprehensive' },
    { type: 'priorInsurance', key: 'coverages.vehicle.collision' },
    { type: 'driver', key: 'person.ref' },
  ],
  [PrefillFlow.SHORT]: [
    { type: 'person', key: 'firstName' },
    { type: 'person', key: 'lastName' },
    { type: 'person', key: 'dob' },
    { type: 'person', key: 'gender' },
    { type: 'person', key: 'married' },
    { type: 'driver', key: 'license.ageFirstLicensed' },
    { type: 'driver', key: 'license.status' },
    { type: 'driver', key: 'license.number' },
    { type: 'driver', key: 'license.state' },
    { type: 'driver', key: 'person.ref' },
    { type: 'priorInsurance', key: 'carrierName' },
  ],
};

export const useGetDriverFieldsForValidations = (drivers: Driver[], flow: PrefillFlow): Fields => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) =>
    getDriverQuestions(state, drivers, dispatch, driverFormKeys[flow], flow),
  );
};

export type DriverProps = Omit<Driver, 'ref' | 'personRef' | 'driversLicense'>;

// consider only these two fields for this page, else errors out in form validation
export interface DriverLicense {
  driversLicense: Pick<DriversLicense, 'ageFirstLicensed' | 'status' | 'number' | 'state'>;
}
export type DriverFinalProps = DriverProps & DriverLicense;
export interface DriverFields extends Fields {
  driver: FieldsDef<DriverFinalProps>;
  residencyType: Field;
}
export interface IncidentFields extends Fields {
  incident: FieldsDef<Incident>;
}

const useGetFields = (driverRef: string): DriverFields => {
  const personRef = useSelector(getPersonRefForDriver(driverRef)) as string;
  const currentInsuranceRef = usePriorInsuranceRefForDriver(driverRef)[0];
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const userInsuranceField = useFieldWithPrefix(currentInsuranceRef, 'priorInsurance.<id>');

  return {
    driver: {
      dateOfBirth: usePersonField('dob'),
      driversLicense: {
        ageFirstLicensed: useDriverField('license.ageFirstLicensed'),
        status: useDriverField('license.status'),
        number: useDriverField('license.number'),
        state: useDriverField('license.state'),
      },
      currentInsurance: {
        state: userInsuranceField(`state`),
        carrier: userInsuranceField(`carrierName`),
        carrierNameText: userInsuranceField(`carrierNameText`),
        years: userInsuranceField(`years`),
        policyInceptionDate: userInsuranceField('policyInceptionDate'),
        endDate: userInsuranceField(`policyEndDate`),
        lapse: userInsuranceField(`lapse`),
        limit: userInsuranceField(`coverages.policy.bodilyInjury`),
        coverage: useField('static.insurancePriorCoverage'),
        vehicleComprehensive: userInsuranceField(`coverages.vehicle.comprehensive`),
        vehicleCollision: userInsuranceField(`coverages.vehicle.collision`),
      },
      priorInsuranceRefs: useDriverField('priorInsurance.ref'),
      phone: usePersonField('phone'),
      email: usePersonField('email'),
      insuredType: usePersonField('insuredType'),
      needFinancialForm: useDriverField('sr22FinancialRespFiling'),
      firstName: usePersonField('firstName'),
      fromPrefill: useDriverField('fromPrefill'),
      gender: usePersonField('gender'),
      hasIncidents: useDriverField('hasIncidents'),
      hasPriorInsurance: useDriverField('hasPriorInsurance'),
      isSni: useDriverField('static.isSni'),
      incidents: useDriverField('incidents'),
      incidentRefs: useDriverField('incidents.ref'),
      lastName: usePersonField('lastName'),
      maritalStatus: usePersonField('married'),
      relationshipToApplicant: usePersonField('relationshipToApplicant'),
      militaryDeploymentDate: useDriverField('militaryDeploymentDate'),
      driverStatus: useDriverField('driverStatus'),
    },
    residencyType: useField('residencyType'),
  };
};

export const useGetIncidentFields = (incidentRef: string): IncidentFields => {
  const userIncidentField = useFieldWithPrefix(incidentRef, 'incident.<id>');

  return {
    incident: {
      // FIXME: should create noun-selector for incidents
      // this call is incorrect
      // (useField() below, it should have the *value* of incidentRef, not the location incidentRef)
      ref: useField(incidentRef),
      type: userIncidentField('type'),
      date: useField('static.incidentDate'),
      year: userIncidentField('year'),
      month: userIncidentField('month'),
      violationDescription: userIncidentField('violation.description'),
      claimDescription: userIncidentField('claim.description'),
      lossAmount: userIncidentField('lossAmount'),
      claimAmount: userIncidentField('claimAmount'),
      day: userIncidentField('day'),
      lossAmountUserEntered: userIncidentField('lossAmountUserEntered'),
      incidentSource: userIncidentField('incidentSource'),
      incidentStatus: userIncidentField('incidentStatus'),
    },
  };
};

export const GetSavedIncidentDescription = (ref: string): AnswerValue => {
  return useSelector((state: RootStore) => getIncidentDescription(state, ref));
};

const getIncidentDescription = (state: RootStore, ref: string): AnswerValue => {
  const answers = getAnswers(state);
  const violationDescription = answers[`${ref}.violation.description`];
  const claimDescription = answers[`${ref}.claim.description`];

  return violationDescription ? violationDescription : claimDescription ? claimDescription : '';
};

export const useHasIncidentsValue = (
  hasIncidents: Field,
  incidentList: Incident[],
): boolean | null => {
  return getInferredValueForYesNoButton({
    yesNoField: hasIncidents,
    getValue: () => incidentList.length > 0,
  });
};

export const useIsSniValue = (personRef: string): boolean | null => {
  const sniPersonRef = useSelector(getSecondaryInsuredPersonRef);

  return !!personRef && personRef === sniPersonRef;
};

// ASP gets one ACL report URL per household on an auto policy
export const useAclReportForPerson = (personRef: string): string | null => {
  const key = `auto.riskReport.${personRef}.ACL.riskReportLink`;
  const reportLink = useSelector((state: RootStore) => getFieldValue(state, key));

  return reportLink as string;
};

// ASP gets one MVR report URL per household on an auto policy
export const useMvrReportForPerson = (personRef: string): string | null => {
  const key = `auto.riskReport.${personRef}.MVR.riskReportLink`;
  const reportLink = useSelector((state: RootStore) => getFieldValue(state, key));

  return reportLink as string;
};

export const useAllowAddingSNI = (driverRef?: string): boolean => {
  const secondaryPersonRef = useSelector(getSecondaryInsuredPersonRef);
  const haveSecondaryPersonRef =
    secondaryPersonRef !== 'undefined' && secondaryPersonRef !== 'null';
  const driverRefIsSNI = useSelector(getDriverIsSNI(driverRef));
  const sniQuestionsEnabled = flagValues[FeatureFlags.SNI_DRIVER_QUESTIONS];

  if (!sniQuestionsEnabled) return false;

  // otherwise, if we don't already have one, or we do, but it's this one,
  // we should show the question.
  return !haveSecondaryPersonRef || driverRefIsSNI;
};

interface SetupDriverFormHookReturnType {
  isPni: boolean;
  driverRef: string | undefined;
  driverFields: DriverFields;
  incidentFields: IncidentFields;
  currentIncidentRef: string | undefined;
  currentIncident: Incident;
  incidentList: Incident[];
  addIncident: () => AddIncidentResult;
  addIncidentToDriver: (incidentRef: string) => AddIncidentResult;
  removeIncident: (incident: Incident | undefined) => void;
  removeCurrentIncident: (incident: Incident | undefined) => void;
  validateIncidentFields: (incidentType: Field, incidentDesc: Field, lossAmount: Field) => boolean;
  validateIncidentDate: (incidentDate: Field, incidentMonth: Field, incidentYear: Field) => boolean;
  incidentRequired: boolean;
  setIncidentRequired: (value: boolean) => void;
  validateFormAndIncidents: (
    validateForm: (params?: ValidateFormParams) => ValidateFormResult,
    inDialog: boolean,
  ) => boolean;
  allowAddSNI: boolean;
  handleBIComplete: (value: AnswerValue) => void;
}

export const useSetupDriverForm = (driverId?: string): SetupDriverFormHookReturnType => {
  const dispatch = useDispatch();
  const driverRefParam = driverId && `driver.${driverId}`;
  const { isPni, driverRef } = useAndEnsureCurrentDriverRef(driverRefParam);
  const getFieldRefs = useGetFieldRefs();

  const driverFields = useGetFields(driverRef || '');
  const incidentList = useIncidents(driverRef || '');
  const currentIncidentRef = useAndEnsureCurrentIncidentRef(driverRef);
  const currentIncident = useIncident(currentIncidentRef || '');

  const incidentFields = useGetIncidentFields(currentIncidentRef || '');
  const addIncident = useAddIncident(driverRef);
  const addIncidentToDriver = useAttachIncidentToDriver(driverRef || '');
  const removeIncident = useRemoveIncident(driverRef || '');
  const removeCurrentIncident = useRemoveIncident('');
  const [incidentRequired, setIncidentRequired] = useState(false);
  const hasIncidentsValue = useHasIncidentsValue(driverFields.driver.hasIncidents, incidentList);

  const validateIncidentFields = useCallback(
    (incidentType: Field, incidentDesc: Field, lossAmount: Field) => {
      let isValid = true;
      if (!incidentDesc.value) {
        dispatch(
          setFormErrorsChangedByField({ key: incidentDesc.key, errors: ['Required field'] }),
        );
        isValid = false;
      }
      if (incidentType.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT && !lossAmount.value) {
        dispatch(setFormErrorsChangedByField({ key: lossAmount.key, errors: ['Required field'] }));
        isValid = false;
      }

      return isValid;
    },
    [dispatch],
  );

  const validateIncidentDate = useCallback(
    (incidentDate: Field, incidentMonth: Field, incidentYear: Field) => {
      if (incidentMonth.errors.length > 0 || incidentYear.errors.length > 0) {
        dispatch(
          setFormErrorsChangedByField({
            key: incidentDate.key,
            errors: [...incidentMonth.errors, ...incidentYear.errors],
          }),
        );

        return false;
      }

      if (incidentYear.value) {
        if (
          isFutureIncidentDate({
            month: incidentMonth.value as number,
            year: incidentYear.value as number,
          })
        ) {
          dispatch(
            setFormErrorsChangedByField({
              key: incidentDate.key,
              errors: ['date is in the future.'],
            }),
          );

          return false;
        }
      }

      return true;
    },
    [dispatch],
  );

  const validateFormAndIncidents = useCallback(
    (validateForm: (params?: ValidateFormParams) => ValidateFormResult, inDialog: boolean) => {
      const { isValid } = validateForm();
      if (hasIncidentsValue && incidentList.length === 0) {
        setIncidentRequired(true);
        if (inDialog) {
          const fieldRefs = getFieldRefs();
          const refEntry = fieldRefs.find((ref) => ref.name === incidentFields.incident.type.key);
          const focusEl = refEntry?.ref?.current || null;
          if (focusEl) focusEl.focus();
        } else {
          goToFirstError();
        }

        return false;
      }

      return isValid;
    },
    [hasIncidentsValue, incidentList.length, getFieldRefs, incidentFields.incident.type.key],
  );

  const allowAddSNI = useAllowAddingSNI(driverRef);

  // BI defaulting to requested coverages
  const requestCoveragesBI = useField('offer.requestedCoverages.policy.bodilyInjury');

  const getRequestedOfferBI = (priorBIValue: string): string => {
    const { options } = requestCoveragesBI.props;
    if (!priorBIValue || !options) return '';
    const last = priorBIValue.substring(priorBIValue.lastIndexOf('.') + 1);
    const match = options.find(
      (option) => option.value.substring(option.value.lastIndexOf('.') + 1) === last,
    );
    if (!match) {
      // only one that doesn't match
      return 'REQUESTED_COVERAGES.POLICY.BODILY_INJURY.TWOHUNDREDFIFTY_FIVEHUNDRED';
    }

    return match.value;
  };

  const offersExist = useSelector(getOffersExist);
  const handleBIComplete = async (value: AnswerValue): Promise<void> => {
    const biLimit = driverFields.driver.currentInsurance.limit;
    biLimit.props.actionOnComplete(value);
    if (value && isPni && !offersExist) {
      // not really a great way to default since request coverages BI already exists by the time PNI selects this
      // so it becomes a conditional "overwrite". We will overwrite it until we get offers. After that, we would run the risk
      // of overwriting a user-customized value on customize coverage feature
      await dispatch(
        updateAnswers({
          answers: { [requestCoveragesBI.key]: getRequestedOfferBI(value as string) },
        }),
      );
    }
  };

  return {
    isPni,
    driverRef,
    driverFields,
    incidentFields,
    currentIncidentRef,
    currentIncident,
    incidentList,
    addIncident,
    addIncidentToDriver,
    removeIncident,
    removeCurrentIncident,
    validateIncidentFields,
    validateIncidentDate,
    incidentRequired,
    setIncidentRequired,
    validateFormAndIncidents,
    allowAddSNI,
    handleBIComplete,
  };
};

const getDriverFormConditions = (fields: DriverFields): Condition[] => {
  const {
    driver: {
      driversLicense: { ageFirstLicensed, status, number, state },
    },
  } = fields;

  return [
    {
      conditionalFields: [ageFirstLicensed],
      isExcluded: () =>
        status.value === DRIVER_UNLICENSED || (!status.value && !number.value && !state.value), // TODO Consider moving these conditions to the SAPI question required property
    },
    {
      conditionalFields: [status],
      isRequiredOverride: () => status.exists,
    },
  ];
};

const getPriorInsuranceFormConditions = (
  fields: DriverFields,
  isPni: boolean,
  isSniValue: boolean | null,
): Condition[] => {
  const {
    driver: {
      currentInsurance: {
        state,
        carrier,
        carrierNameText,
        years,
        policyInceptionDate,
        endDate,
        limit,
        lapse,
      },
      email,
      hasPriorInsurance,
    },
  } = fields;

  return [
    {
      conditionalFields: [email],
      isExcluded: () => !isPni,
    },
    {
      conditionalFields: [
        state,
        carrier,
        carrierNameText,
        years,
        policyInceptionDate,
        endDate,
        limit,
      ],
      isExcluded: () =>
        (!isPni && !isSniValue) || (hasPriorInsurance.exists && !hasPriorInsurance.value),
    },
    {
      conditionalFields: [years, policyInceptionDate, endDate, limit],
      isExcluded: () => carrier.props.value === 'CARRIER.NONE',
    },
    {
      conditionalFields: [lapse],
      isExcluded: () => lapse.props.value !== null,
    },
  ];
};

export const getAllDriverFormConditions = (
  fields: DriverFields,
  isPni: boolean,
  isSniValue: boolean | null,
): Condition[] => [
  ...getDriverFormConditions(fields),
  ...getPriorInsuranceFormConditions(fields, isPni, isSniValue),
];

type DriverProfileProps = Pick<
  Driver,
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'suffix'
  | 'dateOfBirth'
  | 'gender'
  | 'maritalStatus'
  | 'driversLicense'
>;

export interface DriverProfileFields extends Fields {
  driver: FieldsDef<DriverProfileProps>;
}

const useGetDriverProfileFields = (driverRef: string): DriverProfileFields => {
  const personRef = useSelector(getPersonRefForDriver(driverRef)) as string;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');

  return {
    driver: {
      firstName: usePersonField('firstName'),
      lastName: usePersonField('lastName'),
      middleName: usePersonField('middleName'),
      suffix: usePersonField('suffix'),
      dateOfBirth: usePersonField('dob'),
      gender: usePersonField('gender'),
      maritalStatus: usePersonField('married'),

      driversLicense: {
        ageFirstLicensed: useDriverField('license.ageFirstLicensed'),
        status: useDriverField('license.status'),
        number: useDriverField('license.number'),
        state: useDriverField('license.state'),
        country: useDriverField('license.country'),
        licensedEighteenMonths: useDriverField('license.usCanadaDriverLast18Months'),
        relationshipToInsured: useDriverField('license.relationshipToInsured'),
      },
    },
  };
};

interface UniqueDriverHookReturnType {
  duplicateDriver: boolean;
  validateUniqueDriver: () => boolean;
}

export const useUniqueDriver = (
  driverRef: string,
  firstName: Field,
  lastName: Field,
  dateOfBirth: Field,
): UniqueDriverHookReturnType => {
  const [duplicateDriver, setDuplicateDriver] = useState(false);
  const drivers = useSelector(getDrivers);
  const validateUniqueDriver = useCallback((): boolean => {
    for (let i = 0; i < drivers.length; i += 1) {
      const driver = drivers[i];
      const differentRef = driverRef !== driver.ref;
      const sameFirstName =
        !!firstName.value &&
        !!driver.firstName &&
        firstName.value.toString().toLowerCase() === driver.firstName.toLowerCase();
      const sameLastName =
        !!lastName.value &&
        !!driver.lastName &&
        lastName.value.toString().toLowerCase() === driver.lastName.toLowerCase();
      const sameDOB = dateOfBirth.value === driver.dateOfBirth;
      if (differentRef && sameFirstName && sameLastName && sameDOB) {
        setDuplicateDriver(true);

        return false;
      }
    }
    setDuplicateDriver(false);

    return true;
  }, [drivers, firstName, lastName, dateOfBirth, driverRef]);

  useEffect(() => {
    validateUniqueDriver();
  }, [validateUniqueDriver]);

  return { duplicateDriver, validateUniqueDriver };
};

export const useSetupDriverProfileForm = (
  driverRefPassed?: string,
): {
  driverRef: string;
  fields: DriverProfileFields;
  formCallbacks: FormCallbacks;
} & UniqueDriverHookReturnType => {
  const { driverRef } = useAndEnsureCurrentDriverRef(driverRefPassed);
  const fields = useGetDriverProfileFields(driverRef || '');
  const {
    driver: {
      firstName,
      lastName,
      dateOfBirth,
      driversLicense: { ageFirstLicensed, status, number, state, country },
    },
  } = fields;

  const flow = useSelector(getCurrentFlows);
  const isFlowShortAuto = flow.auto === PrefillFlow.SHORT;

  const initValues = useRef({});
  const formCallbacks = useForm({
    initValues,
    fields,
    // TODO: TECH DEBT - These conditionals need to be updated to be strictly metadata-driven.
    // we should use the new useDriverAutoDeltaMetadata selector once PR-1121 is merged.
    conditions: [
      {
        conditionalFields: [number],
        isExcluded: () => status.value === DRIVER_UNLICENSED,
      },
      {
        conditionalFields: [state],
        isExcluded: () => status.value === DRIVER_UNLICENSED || status.value === DRIVER_INTL,
      },
      {
        conditionalFields: [ageFirstLicensed],
        isExcluded: () =>
          !(status.value === DRIVER_VALID || status.value === DRIVER_INTL) ||
          (!status.value && !number.value && !state.value), // TODO Consider moving these conditions to the SAPI question required property
      },
      {
        // FIXME ASAP
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        conditionalFields: [ageFirstLicensed, status, state, number, country!],
        isRequiredOverride: () => isFlowShortAuto,
      },
    ],
  });

  const results = useUniqueDriver(driverRef || '', firstName, lastName, dateOfBirth);

  return {
    ...results,
    driverRef: driverRef || '',
    fields,
    formCallbacks,
  };
};

export const getDriverProfileFormConditions = (fields: DriverProfileFields): Condition[] => {
  const {
    driver: {
      driversLicense: { ageFirstLicensed, status, number, state },
    },
  } = fields;

  return [
    {
      conditionalFields: [number],
      isExcluded: () => status.value === DRIVER_UNLICENSED,
      isRequiredOverride: () => status.value !== DRIVER_UNLICENSED,
    },
    {
      conditionalFields: [state],
      isExcluded: () => status.value === DRIVER_UNLICENSED || status.value === DRIVER_INTL,
      isRequiredOverride: () => status.value !== DRIVER_UNLICENSED && status.value !== DRIVER_INTL,
    },
    {
      conditionalFields: [ageFirstLicensed],
      isExcluded: () =>
        !(status.value === DRIVER_VALID || status.value === DRIVER_INTL) ||
        (!status.value && !number.value && !state.value), // TODO Consider moving these conditions to the SAPI question required property
    },
    {
      conditionalFields: [status],
      isRequiredOverride: () => true,
    },
  ];
};

export const useRemoveDriver = (): ((
  driverRef: string,
  personRefToBeRemoved: string,
  driverIncidentRefs: string[],
) => Promise<void>) => {
  const dispatch = useDispatch();
  const secondaryPersonRef = useSelector(getSecondaryInsuredPersonRef);

  return useCallback(
    async (
      driverRef: string,
      personRefToBeRemoved: string,
      driverIncidentRefs: string[],
      adding?: boolean,
    ) => {
      // Delete the refs in following order using the DELETE endpoint from SAPI
      // incidents -> person -> driver at the end.
      driverIncidentRefs?.forEach(async (incident) => {
        await dispatch(deleteInquiryRef(incident));
      });

      if (personRefToBeRemoved === secondaryPersonRef) {
        // FIXME: remove call, possibly only needed for SAPIv3
        await dispatch(deleteAnswers({ ref: SECONDARY_INSURED_PERSON_REF }));
      }

      await dispatch(deleteInquiryRef(personRefToBeRemoved));
      await dispatch(deleteInquiryRef(driverRef));
    },
    [dispatch, secondaryPersonRef],
  );
};

// useDeepRemoveDriver is a hook that not only removes the driver but removes the ref from the invalid driver list. This hook is called from multiple places.
export const useDeepRemoveDriver = (): ((driverRef: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const drivers = useSelector(getDrivers);
  const invalidDlRefs = useSelector(getInvalidDlRefs);
  const removeDriver = useRemoveDriver();
  const navTracking = useSelector(getNavTracking);

  return useCallback(
    async (driverRef: string) => {
      // Delete page status for referenced driver edit page, since this page won't ever be visited again
      const paths = driverRef ? getReferencePagePaths(navTracking, driverRef.split('.')[1]) : [];
      paths.forEach((path) => dispatch(setPageStatusRemoved(path)));

      const driver = drivers.find((d) => d.ref === driverRef) as Driver;
      if (driver) {
        await removeDriver(driverRef, driver.personRef, driver.incidentRefs);
      }
      // remove deleted driverRef from invalid DL driver refs
      if (invalidDlRefs.includes(driverRef)) {
        invalidDlRefs.splice(invalidDlRefs.indexOf(driverRef), 1);
        await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }));
      }
    },
    [dispatch, drivers, invalidDlRefs, navTracking, removeDriver],
  );
};

export const useUpdateSecondaryPolicyHolderRef = (): ((driverRef: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const updateSecondaryPolicyHolderRef = (driverRef: string): Promise<void> =>
    dispatch(updateSecondaryPolicyHolderByDriver(driverRef));

  return updateSecondaryPolicyHolderRef;
};

export const validateDriverLicense = async (
  driverLicenseNumber: string,
  state: string,
): Promise<boolean> => {
  const requestEffectiveDate = dayjs().format(DateConstants.ANSWERS_FORMAT);
  // if driver license is entered, but not yet entered the state, the UI should not show error
  if (driverLicenseNumber && !state) {
    return true;
  }
  const res = await postDriverLicenseValidation({
    driverLicenseNumber,
    state,
    requestEffectiveDate,
  });
  if (res.DLValidationResponse?.isValid === 'true') return true;

  return false;
};

export interface DriversLicenseNumberAndState {
  [key: number]: {
    // key is 0, 1 etc
    'license.number': Field;
    'license.state': Field;
  };
}

export const getInvalidDlRefs = (state: RootStore): string[] =>
  getAnswer(state, INVALID_DL_DRIVER_REFS)?.toString().split(',') ||
  (emptyArray as unknown as string[]);

export const useValidateDriverLicenseNumberForMutipleDrivers: () => (
  drivers: DriversLicenseNumberAndState,
) => Promise<boolean> = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (drivers: DriversLicenseNumberAndState) => {
      const validationPromises = [];
      for (let idx = 0; idx < Object.keys(drivers).length; idx += 1) {
        validationPromises.push(
          (async () => {
            const driver = drivers[idx];
            const licenseNumberField = driver['license.number'];
            const licenseStateField = driver['license.state'];
            // Only validate if we actually have DL number/state
            if (!licenseNumberField || !licenseStateField) return true;
            // Skip validation for masked DL number
            if (isDlNumberMasked(licenseNumberField.value)) return true;

            const validationResult = await validateDriverLicense(
              licenseNumberField.value as string,
              licenseStateField.value as string,
            );
            if (!validationResult) {
              dispatch(
                setFormErrorsChangedByField({
                  key: licenseNumberField.key as string,
                  errors: [INVALID_LICENSE_NUMBER_MSG],
                }),
              );

              return false;
            }
            // clear the error if the validation passes
            dispatch(
              setFormErrorsResetByField({
                key: licenseNumberField.key as string,
              }),
            );

            return true;
          })(),
        );
      }
      const results = await Promise.all(validationPromises);
      const noError = results.every(isTruthy);
      if (!noError) {
        goToFirstError();
      }

      return noError;
    },
    [dispatch],
  );
};

export const useValidateDriverLicenseNumberForOneDriver: (
  dlNumberField: Field,
  dlStateField: Field,
) => () => Promise<boolean> = (dlNumberField: Field, dlStateField: Field) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    // Skip validation for masked DL number
    if (isDlNumberMasked(dlNumberField.value)) return true;
    const state = ((dlStateField.value as string) || '.').split('.')[1];
    const validationResult = await validateDriverLicense(dlNumberField.value as string, state);
    // call default actionOnComplete only when validation passes, otherwise the default validation will overwrite it
    if (!validationResult) {
      dispatch(
        setFormErrorsChangedByField({
          key: dlNumberField.key,
          errors: [INVALID_LICENSE_NUMBER_MSG],
        }),
      );

      return false;
    }
    // reset driver license number field error if validation is passed
    dispatch(setFormErrorsResetByField({ key: dlNumberField.key }));

    return true;
  }, [dispatch, dlNumberField, dlStateField]);
};

// CSUI-1474
export const useCheckDriverDiscount = (lastKey: string): boolean => {
  const GM_EMPLOYEE_REGEX = new RegExp(`^driver\\.[^.]+\\.discount\\.${lastKey}$`);
  const answers = useSelector(getAnswers);
  const gmEmployeeKeys = Object.keys(answers).filter((key) => GM_EMPLOYEE_REGEX.test(key));

  return gmEmployeeKeys && gmEmployeeKeys.find((key) => answers[key] === true) !== undefined;
};

export const createFirstDriver = (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const inquiryLoaded = getInquiryLoaded(state);
  const allDriverRefs = getAllDriverRefs(state);
  // Use the form field to update driver refs in store in case
  // updateAnswer has not updated redux store yet (as answers response is still in the flight)
  const driverRefsField = getField(state, { key: DRIVERS_REF, dispatch });

  const noExistingDrivers = allDriverRefs.length === 0;
  const primaryInsuredRef = getPrimaryInsuredPersonRef(state);

  // FIXME: assume inquiry is loaded?
  if (!inquiryLoaded) {
    datadogLog({
      logType: 'error',
      message: 'inquiry not loaded',
      context: {
        logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/driverModelUtil.ts',
        functionOrigin: 'useAddDriver/useCallback',
      },
    });
    throw new Error('inquiry not loaded');
  }

  if (noExistingDrivers) {
    const driverRef = dispatch(createRef('driver'));
    // if no person id, need to create new person ref as well
    const personRef: string = noExistingDrivers ? primaryInsuredRef : dispatch(createRef('person'));

    const updateDriverWork = dispatch(
      updateAnswers({
        answers: {
          [`${driverRef}.person.ref`]: personRef,
        },
      }),
    );

    const updateDriversWork = dispatch(updateAddedRef({ type: DRIVERS_REF, newRef: driverRef }));
    // Update form value
    driverRefsField.update([driverRef]);
    Promise.all([updateDriverWork, updateDriversWork]).then((): [string, string] => [
      driverRef,
      personRef,
    ]);
  }
};
