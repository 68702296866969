import { createReducer } from '@reduxjs/toolkit';

import {
  teardownStore,
  updateBridgeOver,
  updateGlobalError,
  updateGlobalSeed,
  updateInitializing,
  updatePurchaseError,
  updateShouldTeardownApp,
  updateShowThirdPartyBanner,
  updateStartedInitializing,
} from './actions';
import type { GlobalState } from './types';

const initGlobal: GlobalState = {
  error: { hasError: false, requestId: '', transactionId: '' },
  purchaseError: null,
  initializing: true,
  seed: 0,
  startedInitializing: false,
  showThirdPartyBanner: true,
  shouldTeardownApp: false,
  bridgeOver: false,
};

export const globalReducer = createReducer(initGlobal, (builder) => {
  builder
    .addCase(updateGlobalError, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(updatePurchaseError, (state, { payload }) => {
      state.purchaseError = payload;
    })
    .addCase(updateGlobalSeed, (state, { payload }) => {
      state.seed = payload;
    })
    .addCase(updateInitializing, (state, { payload }) => {
      state.initializing = payload;
    })
    .addCase(updateStartedInitializing, (state, { payload }) => {
      state.startedInitializing = payload;
    })
    .addCase(updateShowThirdPartyBanner, (state, { payload }) => {
      state.showThirdPartyBanner = payload;
    })
    .addCase(updateShouldTeardownApp, (state, { payload }) => {
      state.shouldTeardownApp = payload;
    })
    .addCase(updateBridgeOver, (state, { payload }) => {
      state.bridgeOver = payload;
    })
    // Reset state to the initial value
    .addCase(teardownStore, () => initGlobal);
});
