import dayjs from 'dayjs';

import { getYearsOptions } from '@ecp/utils/date';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { ROOF_REPLACED } from '../../../../constants/questions';

const fromYear = 1920;
const endYear = dayjs().year();
const roofYearsOptions = getYearsOptions(endYear, fromYear);

export const RoofInstallationDateQuestion: React.FC<QuestionProps> = (props) => {
  const roofInstallOrReplace = useField(ROOF_REPLACED);

  const {
    groupLabel = roofInstallOrReplace.question?.title,
    trackingName = 'roof_age_dropdown',
    dataTestId = 'roofInstallOrReplace',
    hideError = false,
  } = props;

  const error = hideError ? undefined : roofInstallOrReplace.props.error;

  useAddFields({ roofInstallOrReplace });
  useAddConditionValues({
    conditionalFields: [roofInstallOrReplace],
    isRequiredOverride: () => true,
  });

  if (!roofInstallOrReplace.exists) return null;

  return (
    <Select
      {...roofInstallOrReplace.props}
      error={error}
      fullWidth={false}
      options={roofYearsOptions}
      groupLabel={groupLabel}
      id='roofInstallOrReplace'
      data-testid={dataTestId}
      trackingName={trackingName}
      inputButtonAriaLabel='roofInstallOrReplace'
    />
  );
};
