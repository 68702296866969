import { useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem, NumberFormat } from '@ecp/components';
import { Button, Form, Select, TextField } from '@ecp/features/sales/shared/components';
import {
  getFieldErrors,
  getPrimaryInsuredAddressLock,
  getPrimaryInsuredPersonInfo,
  makeSurePrefillFlowInStore,
  useForm,
  useGetAddressFields,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';

import { useStyles } from './PersonAddressForm.styles';

interface Props {
  onNext: () => void;
}

interface SubmitParams {
  onNext: () => void;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  stateKey: string;
  validateForm: () => ValidateFormResult;
  pniInfoSubmitted: boolean;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    stateKey,
    validateForm,
    pniInfoSubmitted,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (...[dispatch, getState]) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();

      if (getFieldErrors(getState(), stateKey).length) {
        setIsSubmitting(false);
        goToFirstError();

        return;
      }
      // if PNI data has not been inputted yet, prefill call should not be made yet.
      if (pniInfoSubmitted) {
        await dispatch(makeSurePrefillFlowInStore());
      }
      onNext();
    } else {
      setIsSubmitting(false);
    }
  };

export const PersonAddressForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { firstName, lastName } = useSelector(getPrimaryInsuredPersonInfo);
  const pniInfoSubmitted: boolean = firstName && lastName ? true : false;

  const { address } = useGetAddressFields();

  const initValues = useRef({});
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues,
    fields: address,
  });
  const primaryInsuredAddressLock = useSelector(getPrimaryInsuredAddressLock);

  const disabled = primaryInsuredAddressLock;

  const handleSubmit = useEvent(async () => {
    dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        stateKey: address.state.key,
        validateForm,
        pniInfoSubmitted,
      }),
    );
  });

  return (
    <div className={classes.root} id='AddressForm'>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container columnSpacing={4}>
          {address.line1.exists && (
            <GridItem topSpacing='sm' xs={12} md={6}>
              <TextField
                {...address.line1.props}
                id='streetAddress'
                label='Street address'
                trackingName='address'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                disabled={disabled}
                inputProps={{ 'data-testid': 'PersonAddressFormStreetTextField' }}
                autoComplete='address-line1'
              />
            </GridItem>
          )}
          {address.line2.exists && (
            <GridItem topSpacing='sm' xs={12} md={6}>
              <TextField
                {...address.line2.props}
                id='line2'
                label='Apt./Unit # (optional)'
                trackingName='address_line_2'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                disabled={disabled}
                inputProps={{ 'data-testid': 'PersonAddressFormLine2TextField' }}
                autoComplete='address-line2'
              />
            </GridItem>
          )}
          {address.city.exists && (
            <GridItem topSpacing='sm' xs={12} md={address.state.exists ? 4 : 6}>
              <TextField
                {...address.city.props}
                id='city'
                label='City'
                trackingName='city'
                disabled={disabled}
                inputProps={{ 'data-testid': 'PersonAddressFormCityTextField' }}
                autoComplete='address-level2'
              />
            </GridItem>
          )}
          {address.state.exists && (
            <GridItem topSpacing='sm' xs={12} md={4}>
              <Select
                {...(address.state.props as OptionProps)}
                id='state'
                label='State'
                trackingName='state'
                disabled
                inputButtonAriaLabel='Person Address State Field'
                autoComplete='address-level1'
              />
            </GridItem>
          )}
          {address.zipcode.exists && (
            <GridItem topSpacing='sm' xs={12} md={address.state.exists ? 4 : 6}>
              <NumberFormat
                {...address.zipcode.props}
                id='zipCode'
                formatType='zipcode'
                label='ZIP code'
                trackingName='zip_code'
                disabled
                data-testid='PersonAddressFormZipcodeField'
                autoComplete='postal-code'
              />
            </GridItem>
          )}
        </Grid>
        <GridItem topSpacing='sm' xs={12}>
          <Button
            id='submitAddressFormButton'
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress || isSubmitting}
            className={classes.next}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='person_address_continue'
            analyticsElement='choice.addressPage.continueButton'
            data-testid='PersonAddressContinueButton'
          >
            Continue
          </Button>
        </GridItem>
      </Form>
    </div>
  );
};
