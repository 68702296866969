import type { Answers } from '@ecp/features/sales/shared/types';

import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { getAnswers } from '../selectors';
import { updateAnswers } from './updateAnswers';

type DeleteAnswers = { ref: string } | { keys: string[] };

/**
 * DEPRECATED FUNCTION: SHOULD ONLY BE USED WITH CAUTION
 * if used on person or other "top-level" entities (from SAPIv4's perspective)
 * it will lead to errors. (as we will try to clear fields that sapi will not allow clearing)
 * you MUST instead delete the entity using deleteInquiryRef()
 *
 * NOTE: deleteInquiryRef() (on SAPIv3) probably does not work on non-"top-level" entities
 * (please update this comment if it's found that this is not the case).
 * e.g. does it work on floortypes? it does work on persons
 *
 * Deleting an answer in SAPI is done via assigning the `null` value to the answer
 * and updating the answers as usual.
 * Response will include all non-null answers.
 *
 * @param params.ref Delete all answers associated with this ref.
 * @param params.keys Delete all answers by these answers keys.
 *
 * @deprecated Replace usage of this function with ref option with the deleteInquiryRef
 */
export const deleteAnswers = wrapThunkActionWithErrHandler<DeleteAnswers>(
  (params) => async (dispatch, getState) => {
    const state = getState();
    const allAnswers = getAnswers(state);
    const answersDraft: Answers = {};

    if ('ref' in params) {
      Object.keys(allAnswers).forEach((key) => {
        if (key.startsWith(params.ref)) answersDraft[key] = null;
      });
    } else if ('keys' in params) {
      params.keys.forEach((key) => {
        answersDraft[key] = null;
      });
    }

    await dispatch(updateAnswers({ answers: answersDraft }));
  },
  'deleteAnswers',
);
