import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddConditionValues } from '@ecp/features/sales/form';
import {
  DateOfBirthQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import {
  getPrimaryInsuredPersonLock,
  useFieldWithPrefix,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useIsMobile } from '@ecp/themes/base';

import { useStyles } from '../LandingPageForm.styles';
import metadata from './metadata';

interface PersonQuestionsProps {
  disabled?: boolean;
}

export const PersonQuestions: React.FC<PersonQuestionsProps> = (props) => {
  const { disabled: propsDisabled } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const primaryInsuredPersonLock = useSelector(getPrimaryInsuredPersonLock);

  useAddConditionValues({
    conditionalFields: [usePersonField('dob'), usePersonField('email')],
    isExcluded: () => metadata.hideDobEmailQuestionSection,
    isRequiredOverride: () => true,
  });

  const disabled = primaryInsuredPersonLock || !!propsDisabled;

  return (
    <div>
      <h2 className={classes.subtitle}>Personal information</h2>
      <Grid container>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <FirstNameQuestion personRef={pniRef} disabled={disabled} />
        </GridItem>
        {metadata.showMiddleAndSuffixSection && (
          <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
            <MiddleNameQuestion personRef={pniRef} disabled={disabled} />
          </GridItem>
        )}
        <GridItem
          topSpacing={isMobile ? 'md' : 'sm'}
          xs={12}
          md={6}
          className={metadata.showMiddleAndSuffixSection ? classes.columnLeft : classes.columnRight}
        >
          <LastNameQuestion personRef={pniRef} disabled={disabled} />
        </GridItem>
        {metadata.showMiddleAndSuffixSection && (
          <GridItem
            topSpacing={isMobile ? 'md' : 'sm'}
            xs={12}
            md={6}
            className={classes.columnRight}
          >
            <SuffixQuestion personRef={pniRef} disabled={disabled} />
          </GridItem>
        )}
        {!metadata.hideDobEmailQuestionSection && (
          <>
            <GridItem topSpacing='md' xs={12} md={6} className={classes.columnLeft}>
              <DateOfBirthQuestion personRef={pniRef} disabled={disabled} />
            </GridItem>
            <GridItem topSpacing='md' xs={12} md={6} className={classes.columnRight}>
              <EmailQuestion personRef={pniRef} disabled={disabled} />
            </GridItem>
          </>
        )}
      </Grid>
    </div>
  );
};
