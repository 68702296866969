import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { datadogLog } from '@ecp/utils/logger';
import type { RequestInitExtended } from '@ecp/utils/network';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { requestWithToken } from '@ecp/features/sales/shared/utils/network';

type Event = 'render' | 'select' | 'click' | 'captured' | 'update' | 'load';

export interface TrackSapiAnalyticsEventParams {
  element: string;
  event: Event;
  module?: string | null;
  eventDetail?: string | null;
  traceId?: string | undefined;
  transactionId?: string | undefined;
}

export const trackSapiAnalyticsEvent = async ({
  element,
  event,
  module: moduleParam,
  eventDetail: eventDetailParam,
  traceId,
  transactionId,
}: TrackSapiAnalyticsEventParams): Promise<Response | void> => {
  try {
    const module = moduleParam || 'choice-ui-analytics-log';
    const eventDetail = eventDetailParam || 'true';
    const body = JSON.stringify({
      action: {
        element,
        event,
        eventDetail,
        interactionID: interactionId.get(),
        dalSessionId: sessionStorage.getItem('dalSessionId'),
        inquiryId: sessionStorage.getItem('inquiryId'),
        offerSetId: sessionStorage.getItem('offerSetId'),
        timestamp: +new Date(),
      },
      callerInfo: {
        application: 'choice',
        version: env.static.releaseVersion,
        serverIP: 'N/A',
        module,
      },
    });
    const init: RequestInitExtended = {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await requestWithToken({
      url: `${env.ecpDalRoot}/analytics/event/action`,
      init,
      allowAfeHeaders: true,
      requestId: traceId,
      transactionId,
    })();

    if (!response) return Promise.resolve(undefined);

    return response;
  } catch (error) {
    const e = error as Error;

    datadogLog({
      logType: 'warn',
      message: e?.message,
      context: {
        logOrigin: 'libs/features/sales/shared/utils/analytics/lib/src/trackSapiAnalyticsEvent.ts',
        functionOrigin: 'trackSapiAnalyticsEvent',
        message: e?.message,
      },
      error: e,
    });
  }
};
