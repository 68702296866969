import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';
import type { Product } from '@ecp/features/shared/product';

import type { DocumentURLResponse } from './types';

// NOTE: this isn't going to work right now,
// this returns an octet stream, but requires special headers.
// Either SAPI needs to not require the special headers,
// or otherwise we have to use special methods such as FileAPI
// or the base64 uri trick to get the data to the user as a file.
// Additionally, the return type here is not correct.
export const fetchDocuments = async ({
  dalSessionId,
  product,
  documentTypeName,
}: {
  dalSessionId: string;
  product: AnswerValue;
  documentTypeName: string;
}): Promise<SalesResponse<Blob>> => {
  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/documents?product=${product}&documentTypeName=${documentTypeName}`,
    raw: true,
    options: {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    },
  });
};

export const fetchDocumentsURL = async ({
  dalSessionId,
  product,
}: {
  dalSessionId: string;
  product: Product[];
}): Promise<SalesResponse<DocumentURLResponse>> => {
  const params: string = product?.map((x) => `product=${x}`).join('&');

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/documents-urls?${params}`,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};
