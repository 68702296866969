import type { ReactNode } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { get as getInteractionId } from '@ecp/utils/analytics/interaction-id';
import { trackClick } from '@ecp/utils/analytics/tracking';
import { isEmpty, upperFirst, uuid } from '@ecp/utils/common';
import { FeatureFlags, flagValues, flagVariables } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';
import { scrollToTop } from '@ecp/utils/web';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { Button, Form } from '@ecp/features/sales/shared/components';
import {
  getDalSessionId,
  getOfferSetId,
  getPrimaryInsuredPersonInfo,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { LineOfBusiness, Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct, hasRentersProduct } from '@ecp/features/shared/product';
import { IconUICheck as SuccessIcon } from '@ecp/themes/base';
import {
  GraphicCrossSellLifeDesktopImageUrl,
  GraphicCrossSellLifeMobileImageUrl,
  GraphicCrossSellPetDesktopImageUrl,
  GraphicCrossSellPetMobileImageUrl,
} from '@ecp/themes/partners/costco';
import type { Fields } from '@ecp/types';

import { getPostBindAnswers, getPurchaseResponse, pushPostBindAnswers } from '../../state';
import type { PostBindFields, PostBindSummary, PurchaseResults } from '../../types';
import { getPolicyNumber, type PaymentFields, useAdditionalInterestFields } from '../../util';
import metadata from './metadata';
import { useStyles } from './PostBindForm.styles';
import { PostBindFormBody } from './PostBindFormBody';
import { SubtitleTextCard } from './SubtitleTextCard';

interface Props {
  currentPostBindRetryCount?: number;
  disclosureScripts?: ReactNode;
  disclosureScriptsDialog?: ReactNode;
  fieldsByProduct: Record<Product, PaymentFields>;
  hasTokens?: boolean;
  hidePageInfo: boolean;
  lineOfBusiness: LineOfBusiness;
  marketingFields: PostBindFields;
  onNext: () => void;
  onResubmitPayment?: () => Promise<void>;
  productsSummary: PostBindSummary[];
  purchaseResults: PurchaseResults;
  submittingPayment?: boolean;
  hasBankPayToken: boolean;
  hasRecurringPayToken: boolean;
}

export const PostBindForm: React.FC<Props> = (props) => {
  const {
    disclosureScripts,
    disclosureScriptsDialog,
    productsSummary,
    fieldsByProduct,
    onResubmitPayment,
    hasTokens,
    submittingPayment,
    currentPostBindRetryCount,
    purchaseResults,
    hasBankPayToken,
    hasRecurringPayToken,
  } = props;

  const { classes, cx } = useStyles();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsFormSubmitting] = useState(false);
  const dispatch = useDispatch();
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);
  const getInitValues = useGetInitValues();
  const getConditions = useGetConditionValues();
  const conditions = getConditions();
  const getFields = useGetFields();
  const fields = getFields();
  const { firstName } = useSelector(getPrimaryInsuredPersonInfo);
  const purchaseResponse = useSelector(getPurchaseResponse);
  const { purchased, failed } = purchaseResults;
  const additionalInterestFeatureFlag = flagValues[FeatureFlags.ADDITIONAL_INTEREST];
  const isAdditionalInterestSubmitting = additionalInterestFeatureFlag && isSubmitting;
  const showAgentPostBindPageEnhancements = env.static.isAgent;
  const { allFields } = useAdditionalInterestFields();
  const { exists } = allFields;

  const existsAdditionalInterest = !!exists.value;

  const hasRenters = hasRentersProduct(purchased);

  const policyNumberForEventLabel = productsSummary
    .map(({ product, offerCheckoutDetails }) =>
      offerCheckoutDetails ? getPolicyNumber(purchaseResponse, offerCheckoutDetails, product) : '',
    )
    .join(', ');

  // We need to remove pre-existing static fields  related to payment or else form will not be valid.
  const getFieldsWhileDeletingPaymentRelatedFields = (): Fields => {
    const fieldsToBeFiltered = fields;
    const fieldKeys = Object.keys(fieldsToBeFiltered);
    fieldKeys.forEach((key) => {
      if (key.includes('static') || key.includes('phone') || isEmpty(fieldsToBeFiltered[key])) {
        delete fieldsToBeFiltered[key];
      }
    });

    return fieldsToBeFiltered;
  };
  const fieldsWithoutPaymentRelatedFields = getFieldsWhileDeletingPaymentRelatedFields();
  const { validateForm, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: fieldsWithoutPaymentRelatedFields,
    conditions,
  });

  const titleText = useMemo(() => {
    if (purchased.length && failed.length) {
      return (
        <>
          <h1 className={cx(classes.title, classes.errorText)}>
            We’re sorry, something went wrong.
          </h1>
          <ProductQuoteNumber />
        </>
      );
    }

    return (
      <div className={showAgentPostBindPageEnhancements ? classes.titleAgent : classes.title}>
        {!showAgentPostBindPageEnhancements ||
          (metadata.shouldRenderIcon && (
            <div className={classes.successIconContainer}>
              <SuccessIcon className={classes.successIcon} />
            </div>
          ))}
        <h1
          className={
            showAgentPostBindPageEnhancements ? classes.titleHeaderAgent : classes.titleHeader
          }
        >
          {showAgentPostBindPageEnhancements && purchased.length > 0 && metadata.showWelcomeTitle
            ? `Congratulations, ${firstName}!`
            : `${upperFirst(getProductNameFromProduct(purchased[0]))}${
                purchased.length > 1
                  ? ` & ${upperFirst(getProductNameFromProduct(purchased[1]))}`
                  : ''
              }${' '}purchase was successful`}
        </h1>
      </div>
    );
  }, [
    failed.length,
    purchased,
    showAgentPostBindPageEnhancements,
    classes.titleAgent,
    classes.title,
    classes.successIconContainer,
    classes.successIcon,
    classes.titleHeaderAgent,
    classes.titleHeader,
    classes.errorText,
    firstName,
    cx,
  ]);

  const postbindAnswers = useSelector(getPostBindAnswers);
  const handleSubmit = useEvent(async () => {
    const { isValid } = validateForm();
    if (isValid && dalSessionId && offerSetId) {
      setIsFormSubmitting(true);
      setFormSubmitted(true);
      const submitResult = await dispatch(
        pushPostBindAnswers({ dalSessionId, answers: postbindAnswers }),
      );
      setIsFormSubmitting(false);
      if (hasRenters && additionalInterestFeatureFlag && existsAdditionalInterest) {
        trackClick({
          action: 'add_landlord_submit',
          label: policyNumberForEventLabel,
        });
        trackSapiAnalyticsEvent({
          element: 'postbindPage.addLandlordContinue',
          event: 'click',
          eventDetail: `API response: ${submitResult?.response}, ${policyNumberForEventLabel}`,
        });
      }

      // Scroll to top of the page only in case of additional interest submission errors ECP-11447
      if (additionalInterestFeatureFlag && !submitResult?.response) return;
      scrollToTop();
    }
  });

  const hasAnyPostBindFields = !isEmpty(fieldsWithoutPaymentRelatedFields);

  const postBindCrossSell = flagValues[FeatureFlags.POST_BIND_CROSS_SELL];
  const lifeHeader = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].LIFE_HEADER
    : '';
  const lifeText = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].LIFE_TEXT
    : '';
  const lifeText2 = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].LIFE_TEXT2
    : '';
  const lifeButtonName = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].LIFE_BUTTON_NAME
    : '';
  const lifeButtonLink = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].LIFE_BUTTON_LINK
    : '';
  const petHeader = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].PET_HEADER
    : '';
  const petText = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].PET_TEXT
    : '';
  const petText2 = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].PET_TEXT2
    : '';
  const petButtonName = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].PET_BUTTON_NAME
    : '';
  const petButtonLink = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].PET_BUTTON_LINK
    : '';
  const postBindCrossSellDisclaimerText = flagValues[FeatureFlags.POST_BIND_CROSS_SELL]
    ? flagVariables[FeatureFlags.POST_BIND_CROSS_SELL].POST_BIND_CROSS_SELL_DISCLAIMER_TEXT
    : '';

  let textHeader: string | undefined = '';
  let copyText: string | undefined = '';
  let copyText2: string | undefined = '';
  let buttonName: string | undefined = '';
  let buttonLink: string | undefined = '';
  let queryParams: string | undefined = '';

  const guid = uuid();
  const expId = env.static.expId;

  if (postBindCrossSell === 'LIFE_INSURANCE_CROSS_SELL') {
    textHeader = lifeHeader;
    copyText = lifeText;
    copyText2 = lifeText2;
    buttonName = lifeButtonName;
    queryParams = `?utm_source=banner&utm_medium=website&utm_campaign=PB0115&utm_content=0272024AFNANAPLNA2482&cs_redirectId=${guid}&cs_id=protective_1&cs_source=${expId}&cs_medium=3&cs_campaign=life_insurance&cs_content=pb_button_1`;
    buttonLink = `${lifeButtonLink}${queryParams}`;
  } else if (postBindCrossSell === 'PET_INSURANCE_CROSS_SELL') {
    textHeader = petHeader;
    copyText = petText;
    copyText2 = petText2;
    buttonName = petButtonName;
    queryParams = `?utm_source=costco&utm_medium=affiliate&utm_campaign=connect-auto-home&cs_redirectId=${guid}&cs_id=figo_1&cs_source=${expId}&cs_medium=3&cs_campaign=pet_insurance&cs_content=pb_button_1`;
    buttonLink = `${petButtonLink}${queryParams}`;
  }

  const interactionId = getInteractionId();

  const handleLifeInsuranceLinkClick = useEvent(() => {
    trackSapiAnalyticsEvent({
      element: 'postbindPage.LifeInsuranceButton',
      event: 'click',
      eventDetail: [
        'Learn More',
        `interaction_id = ${interactionId}`,
        `offerset_id = ${offerSetId}`,
        policyNumberForEventLabel,
        guid,
        queryParams,
      ].join(', '),
    });
  });

  const handlePetInsuranceLinkClick = useEvent(() => {
    trackSapiAnalyticsEvent({
      element: 'postbindPage.PetInsuranceButton',
      event: 'click',
      eventDetail: [
        'Fetch a Quote',
        `interaction_id = ${interactionId}`,
        `offerset_id = ${offerSetId}`,
        policyNumberForEventLabel,
        guid,
        queryParams,
      ].join(', '),
    });
  });

  useEffect(() => {
    if (postBindCrossSell === 'LIFE_INSURANCE_CROSS_SELL') {
      trackSapiAnalyticsEvent({
        element: 'postbindPage.crossSellOpportunitiesRender',
        event: 'render',
        eventDetail: 'life insurance campaign, LifeInsuranceButton',
      });
    } else if (postBindCrossSell === 'PET_INSURANCE_CROSS_SELL') {
      trackSapiAnalyticsEvent({
        element: 'postbindPage.crossSellOpportunitiesRender',
        event: 'render',
        eventDetail: 'pet insurance campaign, PetInsuranceButton',
      });
    }
  }, [postBindCrossSell]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        {showAgentPostBindPageEnhancements && disclosureScriptsDialog}
        <Grid item container>
          <Grid item xs={12}>
            {titleText}
          </Grid>
          {!showAgentPostBindPageEnhancements ||
            (metadata.shouldRenderSubTitle && (
              <Grid item xs={12}>
                <SubtitleTextCard
                  purchaseResults={purchaseResults}
                  productsSummary={productsSummary}
                />
              </Grid>
            ))}

          <PostBindFormBody
            currentPostBindRetryCount={currentPostBindRetryCount}
            fieldsByProduct={fieldsByProduct}
            formSubmitted={formSubmitted}
            formSubmitting={isSubmitting}
            hasTokens={!!hasTokens}
            onResubmitPayment={onResubmitPayment}
            productsSummary={productsSummary}
            purchaseResults={purchaseResults}
            submittingPayment={!!submittingPayment}
            disclosureScripts={disclosureScripts}
            firstName={firstName}
            hasBankPayToken={hasBankPayToken}
            hasRecurringPayToken={hasRecurringPayToken}
          />
          {((hasAnyPostBindFields && !formSubmitted) || isAdditionalInterestSubmitting) && (
            <Button
              variant='primary'
              className={classes.submitButton}
              onClick={handleSubmit}
              type='submit'
              isProcessing={isSubmitting}
              trackingName='submit_answers_button'
              trackingLabel='submit_answers_button'
            >
              Submit answers
            </Button>
          )}
        </Grid>
        {/* PostBind Cross sell A/B test.*/}
        {(postBindCrossSell === 'LIFE_INSURANCE_CROSS_SELL' ||
          postBindCrossSell === 'PET_INSURANCE_CROSS_SELL') && (
          <Grid className={classes.crossSellContainer} container direction='row'>
            <GridItem>
              <img
                src={
                  postBindCrossSell === 'LIFE_INSURANCE_CROSS_SELL'
                    ? GraphicCrossSellLifeDesktopImageUrl
                    : GraphicCrossSellPetDesktopImageUrl
                }
                alt='CrossSell Banner'
                data-testid='CrossSell Banner'
                className={classes.helpImageDesktop}
              />
            </GridItem>
            <GridItem className={classes.TextContainer}>
              <h2 className={classes.headerText}>{textHeader}</h2>
              <img
                src={
                  postBindCrossSell === 'LIFE_INSURANCE_CROSS_SELL'
                    ? GraphicCrossSellLifeMobileImageUrl
                    : GraphicCrossSellPetMobileImageUrl
                }
                alt='CrossSell Banner'
                data-testid='CrossSell Banner'
                className={classes.helpImageMobile}
              />
              <p className={classes.contentText}>{copyText}</p>
              <p className={classes.contentText}>{copyText2}</p>
              {postBindCrossSell === 'LIFE_INSURANCE_CROSS_SELL' ? (
                <Button
                  className={classes.crossSellButton}
                  variant='primary'
                  href={buttonLink}
                  target='_blank'
                  data-testid='LifeInsuranceCrossSellButton'
                  trackingName='life_insurance_button'
                  trackingLabel={`${policyNumberForEventLabel}, ${guid}, ${queryParams}, ${buttonName}`}
                  onClick={handleLifeInsuranceLinkClick}
                  analyticsElement='postBindPage.LifeInsuranceCrossSellButton'
                >
                  {buttonName}
                </Button>
              ) : (
                <Button
                  className={classes.crossSellButton}
                  variant='primary'
                  href={buttonLink}
                  target='_blank'
                  data-testid='PetInsuranceCrossSellButton'
                  trackingName='pet_insurance_button'
                  trackingLabel={`${policyNumberForEventLabel}, ${guid}, ${queryParams}, ${buttonName}`}
                  onClick={handlePetInsuranceLinkClick}
                  analyticsElement='postBindPage.PetInsuranceCrossSellButton'
                >
                  {buttonName}
                </Button>
              )}
            </GridItem>
            <p className={classes.disclaimerText}>{postBindCrossSellDisclaimerText}</p>
          </Grid>
        )}
      </Form>
    </div>
  );
};
