import type { ConfigureStoreOptions, Middleware } from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import type { AnyAction, Dispatch, ReducersMapObject } from 'redux';

import { env } from '@ecp/env';
import {
  acknowledgementsSlice,
  enrollmentsReducer,
  paymentOptionsReducer,
  postbindReducer,
  purchaseReducer,
} from '@ecp/features/sales/checkout';
import { vehiclesSlice } from '@ecp/features/sales/quotes/auto';
import {
  apiReducer,
  configSlice,
  formReducer,
  globalReducer,
  inquiryReducer,
  navReducer,
  offersReducer,
  producerReducer,
  ratingCriteriaReducer,
  sessionSync,
} from '@ecp/features/sales/shared/store';
import type { RootActions, RootStore } from '@ecp/features/sales/shared/store/types';

export const getReducers = (): ReducersMapObject<RootStore, RootActions> => ({
  acknowledgement: acknowledgementsSlice.reducer,
  api: apiReducer,
  config: configSlice.reducer,
  enrollments: enrollmentsReducer,
  form: formReducer,
  global: globalReducer,
  inquiry: inquiryReducer,
  offers: offersReducer,
  producer: producerReducer,
  paymentoptions: paymentOptionsReducer,
  purchase: purchaseReducer,
  nav: navReducer,
  ratingCriteria: ratingCriteriaReducer,
  postbind: postbindReducer,
  vehicles: vehiclesSlice.reducer,
});

// @reduxjs/toolkit adds redux-thunk
// and when not in production it adds
// redux-immutable-state-invariant
// and a custom invariant that checks that the state is serializable.
// also when not in production it will also enable dev tools

export const storeConfiguration = (
  options?: Record<string, unknown> | undefined,
): ConfigureStoreOptions<RootStore, RootActions> => {
  const middleware: (
    | Middleware<Record<string, unknown>, RootStore, Dispatch<AnyAction>>
    | Middleware
  )[] = [...getDefaultMiddleware(options), sessionSync];

  return {
    reducer: getReducers(),
    middleware,
    devTools: env.static.nodeEnv !== 'production',
    ...(options?.preloadedState as ConfigureStoreOptions<RootStore, RootActions>['preloadedState']),
  };
};
export type StoreConfigType = typeof storeConfiguration;
