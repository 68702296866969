import { AuthError } from '@ecp/utils/auth';
import { uuid } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import type { CustomFetchRequestInitExtended } from '@ecp/utils/network';
import {
  makeUrl,
  statusExpiredSession,
  statusNotImplemented,
  statusUnauthorized,
} from '@ecp/utils/network';
import { Queue } from '@ecp/utils/queue';

import { env } from '@ecp/env';
import { ErrorReason } from '@ecp/features/sales/shared/constants';
import type { ProductName } from '@ecp/features/shared/product';

import { requestWithToken } from './requestWithToken';

/** This queue is the default for all requests, so only one happens at a time */
export const salesQueue = new Queue();

// ensure we only fetch using strings
type RequestInitBodyString = Omit<CustomFetchRequestInitExtended, 'body'> & {
  body?: string | null;
};

type ErrorReasonType = ErrorReason;

export class SalesRequestError extends Error {
  public errorReason?: ErrorReasonType;

  public requestUrl?: string;

  public requestId?: string;

  public transactionId?: string;

  public requestOptions?: CustomFetchRequestInitExtended;

  public response?: Response;

  public errorStack?: string;

  public errorBody?: string;

  public errorCode?: string;

  public errorData?: {
    availablePolicyTypes?: ProductName[];
    unavailableRequestedPolicyTypes?: ProductName[];
  };

  public constructor(
    message?: string,
    requestUrl?: string,
    requestId?: string,
    transactionId?: string,
    requestOptions?: CustomFetchRequestInitExtended,
    errorReason?: ErrorReasonType,
    response?: Response,
    errorStack?: string,
    errorBody?: string,
    errorCode?: string,
    errorData?: {
      availablePolicyTypes?: ProductName[];
      unavailableRequestedPolicyTypes?: ProductName[];
    },
  ) {
    super(message);

    // Set the prototype explicitly to work properly with es5 - https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, SalesRequestError.prototype);

    // @ts-ignore FIXME ASAP
    if (Error.captureStackTrace) {
      // @ts-ignore FIXME ASAP
      Error.captureStackTrace(this, SalesRequestError);
    }

    this.name = 'SalesRequestError';
    this.requestUrl = requestUrl;
    this.requestOptions = requestOptions;
    this.response = response;
    this.requestId = requestId;
    this.transactionId = transactionId;
    this.errorReason = errorReason;
    this.errorStack = errorStack;
    this.errorBody = errorBody;
    this.errorCode = errorCode;
    this.errorData = errorData;
  }
}

/**
 * Note: salesRequest() and SalesResponse do not `check` if the payload actually matches the declared type.
 * You may get runtime errors if the actual response has something outside the definition.
 */
export interface SalesResponse<T> {
  status: number;
  payload: T;
  headers: Record<string, string>;
  requestId?: string;
  transactionId?: string;
}

interface SalesRequestParams {
  queue?: Queue;
  endpoint: string;
  params?: { [key: string]: string };
  options?: RequestInitBodyString;
  allResults?: boolean;
  key?: string;
  hasBody?: boolean;
  raw?: boolean;
}
interface SalesRequestParamsWithBodyNotRaw extends SalesRequestParams {
  raw?: false;
}
interface SalesRequestParamsWithoutBodyNotRaw extends SalesRequestParams {
  hasBody?: false;
  raw?: false;
}
interface SalesRequestParamsWithBodyRaw extends SalesRequestParams {
  hasBody?: true;
  raw?: true;
}
interface SalesRequestParamsWithoutBodyRaw extends SalesRequestParams {
  hasBody?: false;
  raw?: true;
}

// used by other files in this directory to define each
// kind of request, not used directly outside of api/sales
export async function salesRequest<T>(
  params: SalesRequestParamsWithBodyNotRaw,
): Promise<SalesResponse<T>>;
export async function salesRequest(
  params: SalesRequestParamsWithBodyRaw,
): Promise<SalesResponse<Blob>>;
export async function salesRequest(
  params: SalesRequestParamsWithoutBodyNotRaw,
): Promise<SalesResponse<null>>;
export async function salesRequest(
  params: SalesRequestParamsWithoutBodyRaw,
): Promise<SalesResponse<null>>;
export async function salesRequest<T>({
  queue = salesQueue,
  endpoint: relativeEndpoint,
  params,
  options: requestOptions = {},
  allResults = false,
  key,
  hasBody = true,
  raw = false,
}: SalesRequestParams): Promise<undefined | SalesResponse<T | null | Blob>> {
  const baseUrl = `${env.ecpDalRoot}/${relativeEndpoint}`;
  const requestUrl = makeUrl({ url: baseUrl, params, method: requestOptions.method });
  const requestId = uuid();
  const transactionId = uuid();
  const agentCrossAcountRecallEnabled = flagValues[FeatureFlags.AGENT_CROSS_ACCOUNT_RECALL];

  const res = await queue.add({
    key,
    work: requestWithToken({
      url: requestUrl,
      init: requestOptions,
      requestId,
      transactionId,
      allowAfeHeaders: true,
    }),
  });

  if (!res) return Promise.resolve(undefined);

  // TODO This will break when raw === false and response is not of type application/json
  const payload = raw ? await res.blob() : await res.json();
  const errorBody = JSON.stringify(payload).toLowerCase();
  const headers = Object.fromEntries(res.headers.entries());

  if (!allResults) {
    if (statusNotImplemented(res.status))
      return { status: res.status, payload: null, headers, requestId, transactionId };

    if (statusExpiredSession(res.status) || payload.errorCode === ErrorReason.SESSION_EXPIRED) {
      throw new SalesRequestError(
        `session expired [${res.status}] ${baseUrl}`,
        requestUrl,
        requestId,
        transactionId,
        requestOptions,
        ErrorReason.SESSION_EXPIRED,
        res,
        payload.message,
        errorBody,
        payload.errorCode,
        payload.errorData,
      );
    }
    // In case of agent and 401, throw an AuthError as `agentAuth` checks whether token is active (with default 60s deviation),
    // and if it's not active - refreshes the token, so all SAPI requests are expected to receive an active token
    // and having unauthorized SAPI response code means something weird happened
    if (env.static.isAgent && statusUnauthorized(res.status)) {
      // Error - When Agent not assigned to a state
      if (errorBody.includes('amfamexclusiveagentnotlicensed')) {
        throw new SalesRequestError(
          `amfam exclusive agent not licensed [${res.status}] ${baseUrl}`,
          requestUrl,
          requestId,
          transactionId,
          requestOptions,
          ErrorReason.AGENT_NOT_LICENSED_FOR_STATE,
          res,
          payload.message,
          errorBody,
          payload.errorCode,
          payload.errorData,
        );
      } else {
        throw new AuthError(errorBody, res.status);
      }
    }

    if (!res.ok) {
      if (
        payload.errorCode === ErrorReason.CROSS_ACCOUNT_RETRIEVE &&
        agentCrossAcountRecallEnabled
      ) {
        return { status: res.status, payload: payload, headers, requestId, transactionId };
      }

      const errorReason = errorBody.includes('rules_exception')
        ? ErrorReason.RULES_EXCEPTION
        : ErrorReason.GLOBAL;

      throw new SalesRequestError(
        `API (response) has bad error status [${res.status}] from ${baseUrl} - ${errorReason}, ${payload.message}`,
        requestUrl,
        requestId,
        transactionId,
        requestOptions,
        errorReason,
        res,
        payload.message,
        errorBody,
        payload.errorCode,
        payload.errorData,
      );
    }
  }

  if (!hasBody) return { status: res.status, payload: null, headers, requestId, transactionId };

  return { status: res.status, payload, headers, requestId, transactionId };
}
