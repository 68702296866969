import { Grid } from '@mui/material';

import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { AdvisorNameSearch } from '@ecp/features/sales/shared/ameriprise-advisor';
import {
  CallLexisNexisQuestion,
  DateOfBirthQuestion,
  HearAboutUsReferenceNumberQuestions,
  HelpYouQuestion,
  RecentLifeEventQuestion,
  VehicleKeptAddressQuestion,
} from '@ecp/features/sales/shared/questions';
import {
  getBridgeType,
  useIsPrimaryInsuredPersonLock,
  usePrimaryInsuredPersonRef,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import metadata from './metadata';
import { useStyles } from './PersonLivingForm.styles';

interface Props {
  setVehicleKeptAddressSuggestions(value: GeoAddress[]): void;
  vehicleKeptAddressSuggestions: GeoAddress[];
  lineOfBusiness: LineOfBusiness;
  isDisabled?: boolean;
}

export const PersonLivingFormQuestions: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { lineOfBusiness, setVehicleKeptAddressSuggestions, vehicleKeptAddressSuggestions } = props;
  const isPniLocked = useIsPrimaryInsuredPersonLock();
  const disabled = props.isDisabled || isPniLocked;
  const pniRef = usePrimaryInsuredPersonRef();
  const bridgeType = useSelector(getBridgeType);

  return (
    <>
      <Grid container className={classes.container}>
        <GridItem xs={12}>
          <p className={classes.subheader}>When were you born?</p>
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <DateOfBirthQuestion id='DOB' personRef={pniRef} disabled={disabled} />
        </GridItem>
      </Grid>
      {metadata.showHearAboutUsReferenceNumberQuestions && <HearAboutUsReferenceNumberQuestions />}
      {metadata.showAdvisorNameSearchQuestions && <AdvisorNameSearch disabled={disabled} />}
      <GridItem topSpacing='lg' xs={12}>
        <RecentLifeEventQuestion disabled={disabled} />
      </GridItem>
      {lineOfBusiness === LineOfBusiness.BUNDLE && (
        <GridItem topSpacing='lg'>
          <VehicleKeptAddressQuestion
            disabled={disabled}
            setVehicleKeptAddressSuggestions={setVehicleKeptAddressSuggestions}
            vehicleKeptAddressSuggestions={vehicleKeptAddressSuggestions}
          />
        </GridItem>
      )}
      <GridItem topSpacing='lg' md={6} xs={12} className={classes.columnLeft}>
        <HelpYouQuestion disabled={disabled} />
      </GridItem>
      {bridgeType !== 'customerPrefill' && (
        <GridItem topSpacing='lg' xs={12}>
          {/* Clean this up after this logic comes from SAPI part of SAPIv4  */}
          {(lineOfBusiness === LineOfBusiness.AUTO ||
            lineOfBusiness === LineOfBusiness.BUNDLE ||
            lineOfBusiness === LineOfBusiness.BUNDLE_AUTO_RENTERS) &&
            !env.static.isAgent && <CallLexisNexisQuestion disabled={disabled} />}
        </GridItem>
      )}
    </>
  );
};
