import { useEffect } from 'react';

import { Container } from '@mui/material';

import { env } from '@ecp/env';
import { PageProvider } from '@ecp/features/sales/form';
import { Navbar } from '@ecp/features/sales/navigationbar';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import type { PageHeaderProps } from '../PageHeader';
import { PageHeader } from '../PageHeader';
import { Topbar } from '../Topbar';
import { useStyles } from './Page.styles';

export type PageProps = Pick<PageHeaderProps, 'subTitle' | 'subTitleHelpText' | 'variant'> & {
  topSpacer?: boolean;
  topBanner?: React.ReactNode;
  sidebarProps?: React.ComponentProps<typeof Navbar>;
  topbarProps?: React.ComponentProps<typeof Topbar>;
  title?: PageHeaderProps['children'];
  analyticsElement?: string;
  analyticsEventDetail?: string;
  className?: string;
  enableBackgroundGradient?: boolean;
  mainWithoutSidebar?: boolean;
  abovePageHeaderSlot?: React.ReactNode;
  actionBarItems?: React.ReactNode;
};

export const Page: React.FC<React.PropsWithChildren<PageProps>> = (props) => {
  const {
    children,
    topbarProps,
    topBanner,
    sidebarProps,
    title,
    subTitle,
    subTitleHelpText,
    analyticsElement,
    analyticsEventDetail,
    variant,
    topSpacer,
    className,
    mainWithoutSidebar,
    abovePageHeaderSlot,
    actionBarItems,
    enableBackgroundGradient = false,
  } = props;
  const { classes, cx } = useStyles({ forceSwipeable: !!sidebarProps?.forceSwipeable });

  useEffect(() => {
    if (analyticsElement) {
      trackSapiAnalyticsEvent({
        element: analyticsElement,
        event: 'render',
        eventDetail: analyticsEventDetail || 'true',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsElement, analyticsEventDetail]);

  return (
    <div
      className={cx(classes.root, className, { [classes.gradient]: !!enableBackgroundGradient })}
    >
      {!!topSpacer && (!env.static.isAgent || !sidebarProps) && (
        <div id='outerTopSpacer' className={classes.spacer} />
      )}
      {topbarProps && <Topbar {...topbarProps} />}
      {topBanner}
      <Container
        className={cx(classes.bottom, {
          [classes.bottomWithSidebar]: !!sidebarProps,
        })}
        disableGutters
        id='content'
        tabIndex={-1}
        maxWidth={false}
      >
        {sidebarProps && (
          <div className={classes.navbarContainer}>
            <Navbar {...sidebarProps} />
          </div>
        )}
        <div
          className={cx(
            classes.main,
            sidebarProps
              ? classes.mainWithSidebar
              : mainWithoutSidebar && classes.mainWithoutSidebar,
          )}
          role='main'
        >
          {!!topSpacer && sidebarProps && env.static.isAgent && (
            <div id='innerTopSpacer' className={classes.spacer} />
          )}
          {abovePageHeaderSlot}
          {title && (
            <PageHeader
              variant={variant}
              subTitle={subTitle}
              subTitleHelpText={subTitleHelpText}
              actionBarItems={actionBarItems}
            >
              {title}
            </PageHeader>
          )}
          <PageProvider>
            <div>{children}</div>
          </PageProvider>
          {env.static.isAgent && sidebarProps && (
            <div id='innerBtmSpacer' className={classes.spacer} />
          )}
        </div>
      </Container>
      {(!env.static.isAgent || !sidebarProps) && (
        <div id='outerBtmSpacer' className={classes.spacer} />
      )}
    </div>
  );
};
