export interface Metadata {
  showDueTodayAmt?: boolean;
  isVariantACoverageStyle?: boolean;
}

const metadata: Metadata = {
  showDueTodayAmt: true,
  // VariantA - Zillow
  isVariantACoverageStyle: false,
};

export default metadata;
