import { useCallback, useEffect, useState } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';

import { LogoSpinner } from '@ecp/components';
import { env } from '@ecp/env';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page, ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import {
  getAllPageFlowsFromConfig,
  PagePath,
  useNavigateToNextPage,
} from '@ecp/features/sales/shared/routing';
import {
  getDeclinationNoticeDisplayedPagePath,
  getIsBundleForOfferProductsSelected,
  getLobForOfferProductsSelected,
  getRecalculateValue,
  getSapiAnalyticsSelectedOfferEventDetail,
  getUserSelection,
  patchUserSelectionAndUpdateOffer,
  setShouldRecalc,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { useCheckoutCardProps } from '@ecp/features/sales/shell';
import { isProductAuto, UserSelection } from '@ecp/features/shared/product';

import { DeclinationDialog, getOfferErrors, useDeclinationNotices } from '../../components';
import { useQuotesSelection } from '../Quotes/QuotesTabs/useQuotesSelection';
import { CoveragesForm } from './CoveragesForm';
import { useStyles } from './CoveragesPage.styles';
import type { Metadata } from './metadata';
import metadata from './metadata';

type Props = Metadata;

export const CoveragesPage: React.FC<Props> = (props) => {
  const {
    checkoutButtonText = metadata.checkoutButtonText,
    variant = metadata.variant,
    title = metadata.title,
  } = props;
  const { classes, cx } = useStyles();

  const dispatch = useDispatch();

  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const lineOfBusiness = useSelector(getLobForOfferProductsSelected);
  const sapiAnalyticsOfferEventDetail = useSelector(getSapiAnalyticsSelectedOfferEventDetail);

  // To handle forward and back navigation for product.userSelection
  const bundleTypeFromInquires = useSelector(getUserSelection);
  const bundleTypeOfSelectedProduct = isBundle ? UserSelection.BUNDLED : UserSelection.UNBUNDLED;

  // a slight delay is needed for the logo to appear in the logoSpinner
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  setTimeout(() => setSpinnerLoading(false), 400);

  // Loading indicator to handle the following edge case
  // User back navigates from coverages page -> userSelection is removed
  // User now forward navigates without changing/selecting any products from the quotes page
  // Update SAPI with the previous userSelection to get appropriate discounts from carriers
  const [loading, setLoading] = useState(false);
  const recalculate = useSelector(getRecalculateValue);

  const { isUserSelectionUpdating } = useQuotesSelection();
  const declinationNoticeDisplayedPagePath = useSelector(getDeclinationNoticeDisplayedPagePath);
  const { erroredProducts } = useDeclinationNotices();
  const { isDNQError, isCNQError } = useSelector(getOfferErrors);
  // Show declination dialog only for auto offer DNQ
  const erroredAutoProduct = erroredProducts.find((product) => isProductAuto(product));
  const isAutoErrored =
    !!erroredAutoProduct && declinationNoticeDisplayedPagePath !== PagePath.QUOTES;
  // Set this flag to true only if auto DNQ after auto delta
  const isAutoOfferDNQ = isDNQError && isAutoErrored;
  const isAutoOfferDeclined = (isDNQError || isCNQError) && isAutoErrored;
  const [showDeclinationDialog, setShowDeclinationDialog] = useState<boolean>(isAutoOfferDNQ);
  const onNext = useNavigateToNextPage();
  const checkoutCardProps = {
    ...useCheckoutCardProps({ checkoutButtonText, onNext }),
  };

  // The dependencies would make sure user selection gets properly updated.
  // Also, this would avoid any mismatch because of edge case back nav scenarios
  // TODO - Revisit this useEffect after SAPI resolves confusion regarding product.userSelection in answers
  useEffect(() => {
    if (bundleTypeFromInquires === null || bundleTypeFromInquires !== bundleTypeOfSelectedProduct) {
      // On navigation change set correct user selection and updated offers
      setLoading(true);
      dispatch(patchUserSelectionAndUpdateOffer()).then(() => {
        if (recalculate) dispatch(setShouldRecalc(false));
        setLoading(false);
      });
    }
    if (isAutoOfferDeclined) {
      // getAllPageFlowsFromConfig is called to get the updated selectedOffersProducts and remove additional inforamtion from the stepper if Auto DNQs or CNQs on coverages page
      dispatch(getAllPageFlowsFromConfig({}));
    }
  }, [
    bundleTypeFromInquires,
    bundleTypeOfSelectedProduct,
    dispatch,
    lineOfBusiness,
    recalculate,
    isUserSelectionUpdating,
    isAutoOfferDeclined,
  ]);

  // A/B test metric tracking function for checkout page view
  useEffect(() => {
    trackEvent(Events.COVERAGES_PAGE);
  }, []);

  const handleDeclinationDialogClose = useCallback(() => {
    setShowDeclinationDialog(false);
  }, []);

  return (
    <div className={cx(classes.root)}>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.COVERAGES} />,
          forceSwipeable: !env.static.isAgent,
        }}
        title={!loading && !isUserSelectionUpdating && title}
        subTitle={<ProductQuoteNumber />}
        variant={variant}
        analyticsElement='choice.policyCoveragePage.page'
        analyticsEventDetail={sapiAnalyticsOfferEventDetail}
      >
        {loading || isUserSelectionUpdating || spinnerLoading ? (
          <div className={classes.loading}>
            <LogoSpinner />
          </div>
        ) : (
          <CoveragesForm checkoutCardProps={checkoutCardProps} isVariantACoverageStyle />
        )}
        {showDeclinationDialog && erroredAutoProduct && (
          <DeclinationDialog
            onClose={handleDeclinationDialogClose}
            open={showDeclinationDialog}
            product={erroredAutoProduct}
          />
        )}
      </Page>
    </div>
  );
};
