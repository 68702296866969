import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormControl, FormLabel, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { datadogLog } from '@ecp/utils/logger';

import { useShowMoreOrLess } from '@ecp/components';
import { NATURAL_NUMBER_REGEX } from '@ecp/features/sales/shared/constants';
import {
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Percentages, SelectedOption } from '@ecp/features/sales/shared/types';
import type { CardOption, Field } from '@ecp/types';

import { CheckboxGroup } from '../CheckboxGroup';
import { useStyles } from './PercentageGroup.styles';
import type { PercentageInputProps } from './PercentageInput';
import { PercentageInput } from './PercentageInput';

const REQUIRED_ERROR_MSG = 'Required';
const PERCENTAGE_ERROR_MSG = 'Percentage must be greater than 0 and less than or equal 100';

interface Props {
  options: CardOption[];
  percentages: Percentages;
  uiField: Field;
  groupTitle?: React.ReactNode;
  percentageTitle: string;
  helperText?: string;
  hidePercentageSelected?: boolean;
  onAdd: (key: string, value: string) => Promise<void>;
  onUpdate: (percentages: Percentages) => Promise<void>;
  onRemove: (key: string) => Promise<void>;
  optionTrackingName?: string;
  percentTrackingName?: string;
  moreLessTrackingName?: string;
  moreLessTrackingLabel?: (value: string) => string;
  onAddAndUpdateAll?: (key: string, value: string, percentages: Percentages) => Promise<void>;
  sortByKey?: boolean;
}

export const PercentageGroup: React.FC<Props> = (props) => {
  const {
    options: questionOptions,
    percentages,
    uiField,
    percentageTitle: title,
    groupTitle,
    helperText,
    onAdd,
    onUpdate,
    onRemove,
    optionTrackingName,
    percentTrackingName,
    moreLessTrackingName,
    moreLessTrackingLabel,
    onAddAndUpdateAll,
    hidePercentageSelected = false,
    sortByKey,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const allOptions: CardOption[] = useMemo(() => questionOptions, [questionOptions]);

  const validateInputs = useCallback(
    (selectedOptionsList: SelectedOption[], touchedOptionsList: string[]) => {
      const options = selectedOptionsList.map((option) => {
        const { value: percentage } = option;
        const numberValue = Number(percentage);
        if (!percentage) {
          return { ...option, error: REQUIRED_ERROR_MSG };
        }
        if (Number.isNaN(numberValue) || numberValue <= 0 || numberValue > 100) {
          return { ...option, error: PERCENTAGE_ERROR_MSG };
        }

        return { ...option, error: undefined };
      });
      const touchedOption = options.find((option) => {
        const { key, error } = option;
        if (touchedOptionsList.includes(key) && error) {
          dispatch(setFormErrorsChangedByField({ key, errors: [error] }));

          return true;
        }

        return false;
      });

      return { isValid: !touchedOption, options };
    },
    [dispatch],
  );

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [touchedOptions, setTouchedOptions] = useState<string[]>([]);
  const [inProgress, setInProgress] = useState(false);

  const addTouchedOptions = useCallback(
    (key: string) => {
      const newTouchedOptions = [...touchedOptions, key];
      setTouchedOptions(newTouchedOptions);

      return newTouchedOptions;
    },
    [touchedOptions],
  );

  const removeTouchedOptions = useCallback((touchedOptionsList: string[], key: string) => {
    const newTouchedOptions = touchedOptionsList.filter((optionKey) => optionKey !== key);
    setTouchedOptions(newTouchedOptions);

    return newTouchedOptions;
  }, []);

  const getSelectedOptionByKey = useCallback(
    (key: string | null = '') => selectedOptions.find((option) => option.key === key),
    [selectedOptions],
  );

  const calculateOptionsPercentages = useCallback((options: SelectedOption[], total: number) => {
    if (options.length > 0) {
      const average = total > 0 ? Math.floor(total / options.length) : 0;
      let remainder = total > 0 ? total % options.length : 0;

      return options.map((option) => {
        const percentage = average + (remainder > 0 ? 1 : 0);
        if (remainder > 0) {
          remainder -= 1;
        }

        return { ...option, value: String(percentage) };
      });
    }

    return options;
  }, []);

  const getTouchedOptionsPercentagesTotal = useCallback(
    (selectedOptionsList: SelectedOption[], touchedOptionsList: string[]) => {
      return touchedOptionsList.reduce((total: number, key: string): number => {
        const selectedOption = selectedOptionsList.find((option) => option.key === key);
        if (selectedOption) {
          const percentage = Number(selectedOption.value);

          return total + (Number.isNaN(percentage) ? 0 : percentage);
        }

        return total;
      }, 0);
    },
    [],
  );

  const getUnTouchedOptions = useCallback(
    (selectedOptionsList: SelectedOption[], touchedOptionsList: string[]) => {
      return selectedOptionsList.filter((option) => !touchedOptionsList.includes(option.key));
    },
    [],
  );

  const autoCalculatePercentage = useCallback(
    (selectedOptionsList: SelectedOption[], touchedOptionsList: string[]) => {
      if (selectedOptionsList.length > 0 && touchedOptionsList.length === 0) {
        return calculateOptionsPercentages(selectedOptionsList, 100);
      }
      if (selectedOptionsList.length > 0 && touchedOptionsList.length > 0) {
        const touchedOptionsPercentagesTotal = getTouchedOptionsPercentagesTotal(
          selectedOptionsList,
          touchedOptionsList,
        );
        const unTouchedOptions = getUnTouchedOptions(selectedOptionsList, touchedOptionsList);
        const newUnTouchedOptions = calculateOptionsPercentages(
          unTouchedOptions,
          100 - +touchedOptionsPercentagesTotal,
        );

        return selectedOptionsList.map((option) => {
          const unTouchedOption = newUnTouchedOptions.find((o) => o.key === option.key);

          return unTouchedOption || option;
        });
      }

      return selectedOptionsList;
    },
    [calculateOptionsPercentages, getTouchedOptionsPercentagesTotal, getUnTouchedOptions],
  );

  const validatePercentagesTotal = useCallback(
    (selectedOptionsList: SelectedOption[]) => {
      const percentageTotal = selectedOptionsList.reduce((total: number, option): number => {
        const percentage = Number(option.value);

        return total + (Number.isNaN(percentage) ? 0 : percentage);
      }, 0);
      const isValid = percentageTotal === 100;
      let options = selectedOptionsList;
      if (!isValid) {
        // Auto-distribute the percentages in case of invalid total percentage
        options = autoCalculatePercentage(selectedOptionsList, []);
      }

      return { isValid, options };
    },
    [autoCalculatePercentage],
  );

  // Initialize selectedOptions, validate and auto-calculate if needed when percentage data comes invalid from sapi
  useEffect(() => {
    const options = Object.entries(percentages).map(([key, value]) => ({ key, value }));
    const { options: newOptions } = validateInputs(options, []);
    const { isValid, options: optionsList } = validatePercentagesTotal(newOptions);
    setSelectedOptions(optionsList);
    if (!isValid) {
      const newPercentages = optionsList.reduce((result, { key, value }) => {
        result[key] = value;

        return result;
      }, {} as Percentages);
      // Patch updated percentages if percentage data was invalid and has been recalculated in `validatePercentagesTotal`
      onUpdate(newPercentages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSelectedOptions = useCallback(
    async (
      selectedOptionsList: SelectedOption[],
      touchedOptionsList: string[],
      addKey?: string,
      removeKey?: string,
    ) => {
      const { options } = validateInputs(selectedOptionsList, touchedOptionsList);
      const { options: newOptions } = validatePercentagesTotal(options);
      options.forEach(({ key, error }) => {
        if (error) dispatch(setFormErrorsChangedByField({ key, errors: [error] }));
        else setFormErrorsResetByField({ key });
      });
      setSelectedOptions(newOptions);
      setInProgress(true);
      const newPercentages = newOptions.reduce((result, { key, value }) => {
        if (key !== addKey) result[key] = value;

        return result;
      }, {} as Percentages);
      if (addKey) {
        const addedOption = newOptions.find(({ key }) => key === addKey);
        if (!addedOption) {
          setInProgress(false);
          datadogLog({
            logType: 'warn',
            message: `Not found option for key "${addKey}"`,
            context: {
              logOrigin:
                'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroup.tsx',
              functionOrigin: 'updateSelectedOptions',
            },
          });
          throw new Error(`Not found option for key "${addKey}"`);
        }
        if (onAddAndUpdateAll) {
          await onAddAndUpdateAll(addedOption.key, addedOption.value, newPercentages);
        } else {
          await onAdd(addedOption.key, addedOption.value);
          await onUpdate(newPercentages);
        }
      }
      if (removeKey) {
        dispatch(setFormErrorsResetByField({ key: removeKey }));
        await onRemove(removeKey);
        await onUpdate(newPercentages);
      }

      if (percentTrackingName) {
        const trackingPercentages = newOptions.reduce((result, { key, value }) => {
          result[key] = value;

          return result;
        }, {} as Percentages);
        trackClick({ action: percentTrackingName, label: JSON.stringify(trackingPercentages) });
      }
      setInProgress(false);
    },
    [
      dispatch,
      validateInputs,
      validatePercentagesTotal,
      onAdd,
      onUpdate,
      onRemove,
      percentTrackingName,
      onAddAndUpdateAll,
    ],
  );

  const addSelectedOption = useCallback(
    async (key = '') => {
      const newSelectedOptions = autoCalculatePercentage(
        [...selectedOptions, { key, value: '' }],
        touchedOptions,
      );
      await updateSelectedOptions(newSelectedOptions, touchedOptions, key);
    },
    [autoCalculatePercentage, selectedOptions, touchedOptions, updateSelectedOptions],
  );

  const removeSelectedOption = useCallback(
    async (key = '') => {
      const newSelectedOptions = selectedOptions.filter((option) => option.key !== key);
      let newTouchedOptions = removeTouchedOptions(touchedOptions, key);
      if (newSelectedOptions.length === 1) {
        newTouchedOptions = removeTouchedOptions(newTouchedOptions, newSelectedOptions[0].key);
      }
      const newOptions = autoCalculatePercentage(newSelectedOptions, newTouchedOptions);
      await updateSelectedOptions(newOptions, newTouchedOptions, undefined, key);
    },
    [
      selectedOptions,
      removeTouchedOptions,
      touchedOptions,
      autoCalculatePercentage,
      updateSelectedOptions,
    ],
  );

  const handleCheckboxGroupChange = useCallback(
    async (newArray: string[]) => {
      uiField.props.actionOnComplete(newArray.join(','));
      // TODO Figure out another way to find out element checked/ unchecked.
      // To find out if an item is checked. elementAdded would have at max one element.
      const elementAdded = newArray.filter(
        (item) => selectedOptions.findIndex(({ key }) => key === item) === -1,
      );
      const checked = elementAdded.length > 0;
      if (checked) {
        await addSelectedOption(elementAdded[0]);
        (document.querySelector(`[value="${elementAdded[0]}"]`) as HTMLElement)?.focus();
      } else {
        // If unchecked, or item removed
        const elementDeleted = selectedOptions.filter(
          (item) => newArray.findIndex((v) => v === item.key) === -1,
        );
        // elementDeleted would have one item. Use key to remove it.
        await removeSelectedOption(elementDeleted[0].key);
        (document.querySelector(`[value="${elementDeleted[0].key}"]`) as HTMLElement)?.focus();
      }
    },
    [uiField.props, addSelectedOption, removeSelectedOption, selectedOptions],
  );

  const handleInputChange = useCallback<PercentageInputProps['onChange']>(
    (event) => {
      const dataKey = event.currentTarget.getAttribute('name');
      const { value } = event.currentTarget;
      if (value && !value.match(NATURAL_NUMBER_REGEX)) {
        return;
      }
      const option = getSelectedOptionByKey(dataKey);
      if (option) {
        const newSelectedOptions = selectedOptions.map((selectedOption) => {
          if (selectedOption.key === option.key) {
            return { ...selectedOption, value };
          }

          return selectedOption;
        });
        setSelectedOptions(newSelectedOptions);
        if (!touchedOptions.includes(option.key)) {
          addTouchedOptions(option.key);
        }
      }
    },
    [selectedOptions, touchedOptions, getSelectedOptionByKey, addTouchedOptions],
  );

  const handleInputBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { isValid, options } = validateInputs(selectedOptions, touchedOptions);
      if (!isValid) {
        setSelectedOptions(options);
      } else {
        const newSelectedOptions = autoCalculatePercentage(options, touchedOptions);
        await updateSelectedOptions(newSelectedOptions, touchedOptions);
        if (touchedOptions.length === options.length) {
          setTouchedOptions([]);
        }
        (event.relatedTarget as HTMLElement).focus();
      }
    },
    [
      validateInputs,
      selectedOptions,
      touchedOptions,
      autoCalculatePercentage,
      updateSelectedOptions,
    ],
  );

  const { displayOptions, showMore, handleClick } = useShowMoreOrLess(
    allOptions,
    4,
    moreLessTrackingName,
    moreLessTrackingLabel,
    sortByKey,
  );
  const selectedQuestionOptions = selectedOptions.map(
    ({ key }) => questionOptions.find((option) => option.value === key) ?? null,
  );

  const checkboxGroupOptions = useMemo(() => {
    return displayOptions.map((option) => ({ ...option, disabled: inProgress }));
  }, [displayOptions, inProgress]);

  const handleRemove = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const dataKey = event.currentTarget.getAttribute('data-key');
      if (dataKey) {
        await removeSelectedOption(dataKey);
      }
    },
    [removeSelectedOption],
  );

  return (
    <>
      <CheckboxGroup
        {...uiField.props}
        label={groupTitle}
        helperText={helperText}
        values={selectedOptions.map((option) => String(option.key))}
        options={checkboxGroupOptions}
        actionOnComplete={handleCheckboxGroupChange}
        showMoreOrLessProps={{ showMore, onClick: handleClick }}
        trackingName={optionTrackingName}
        allOptions={allOptions}
      />
      {selectedQuestionOptions.length > 1 && !hidePercentageSelected && (
        <Grid container>
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend' focused={false} className={classes.percentageTitle}>
                {title}
              </FormLabel>
              {selectedQuestionOptions.map((selectedQuestionOption) => {
                if (!selectedQuestionOption) return null;
                const { label, value: name } = selectedQuestionOption;
                const selectedOption = getSelectedOptionByKey(name);
                if (!selectedOption) return null;
                const { value, error } = selectedOption;

                return (
                  <PercentageInput
                    key={name}
                    id={value}
                    label={label}
                    name={name}
                    value={value}
                    error={error}
                    disabled={inProgress}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onRemove={handleRemove}
                  />
                );
              })}
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};
