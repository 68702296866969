import { useAddFields } from '@ecp/features/sales/form';
import { Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { NUMBER_OF_ROOMS_WITH_CROWN_MOLDING } from '../../../../constants/questions';

export const RoomsWithCrownMoldingQuestion: React.FC<QuestionProps> = (props) => {
  const roomsWithCrownMolding = useField(NUMBER_OF_ROOMS_WITH_CROWN_MOLDING);
  const {
    groupLabel = roomsWithCrownMolding.question?.title,
    trackingName = 'crown_molding_slider',
  } = props;

  useAddFields({ roomsWithCrownMolding });

  if (!roomsWithCrownMolding.exists) return null;

  return (
    <Slider {...roomsWithCrownMolding.props} groupLabel={groupLabel} trackingName={trackingName} />
  );
};
