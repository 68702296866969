import { useCallback } from 'react';

import {
  PRIMARY_INSURED_PERSON_REF,
  SECONDARY_INSURED_PERSON_REF,
} from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { FieldsDef, Person } from '@ecp/features/sales/shared/types';

import { getPrimaryInsuredPersonRef, useFieldWithPrefix } from '../form';
import { getAllValues } from '../form/selectors';
import { updateAnswers } from '../inquiry';
import {
  getPersonFields,
  getPersonsFields,
  getPersonsValues,
  getPersonValues,
} from '../inquiry/noun-selectors';

export const usePniRef = (): string =>
  useSelector(getAllValues)[PRIMARY_INSURED_PERSON_REF] as string;

export const usePersonValues = (ref: string): Person => {
  return useSelector((state: RootStore) => getPersonValues(state, ref));
};

export const usePersonsValues = (refs: string[]): Record<string, Person> => {
  return useSelector((state: RootStore) => getPersonsValues(state, refs));
};

export const usePersonFields = (ref: string): FieldsDef<Person> => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) => getPersonFields(state, ref, dispatch));
};

export const usePersonsFields = (refs: string[]): Record<string, FieldsDef<Person>> => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) => getPersonsFields(state, refs, dispatch));
};

export const usePrimaryInsuredPersonRef = (): string => {
  return useSelector(getPrimaryInsuredPersonRef);
};

export const useSniRef = (): string =>
  useSelector(getAllValues)[SECONDARY_INSURED_PERSON_REF] as string;

export const useUpdatePNIInsuredType = (): (() => void) => {
  const dispatch = useDispatch();
  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const insuredType = usePersonField('insuredType');

  const updatePNIInsuredType = useCallback(async (): Promise<void> => {
    if (!insuredType.value) {
      await dispatch(
        updateAnswers({
          answers: { [insuredType.key]: 'PRIMARY_INSURED' },
        }),
      );
    }
  }, [dispatch, insuredType]);

  return updatePNIInsuredType;
};
