import { memo } from 'react';

import { Grid } from '@mui/material';

import { env } from '@ecp/env';
import { useIsMobile } from '@ecp/themes/base';

import { CoveragesPageTitle } from './CoveragesPageTitle';
import { useStyles } from './CoveragesPageUpperSection.styles';

export const CoveragesPageUpperSection: React.FC = memo(() => {
  const { classes } = useStyles();

  const isMobile = useIsMobile();

  return (
    <Grid
      container
      justifyContent={!env.static.isAgent && isMobile ? 'left' : 'center'}
      className={classes.root}
    >
      <CoveragesPageTitle />
    </Grid>
  );
});
