import { datadogLog } from '@ecp/utils/logger';
import { Headers } from '@ecp/utils/network';

import { ErrorReason } from '@ecp/features/sales/shared/constants';
import type { Answers } from '@ecp/features/sales/shared/types';
import { SalesRequestError, type SalesResponse } from '@ecp/features/sales/shared/utils/network';

import type { SapiTarget } from '../../../api';
import { setSapiTarget } from '../../../api';
import { getPrimaryInsuredAddressRef, setFormErrorsChangedByField } from '../../../form';
import { wrapThunkActionWithErrHandler } from '../../../util/wrapThunkActionWithErrHandler';
import { setAnswersApiPatchSuccess } from '../../actions';
import { patchAnswers } from '../../api';
import { getAnswers, getDalSessionId } from '../../selectors';
import type { AnswersResponse } from '../../types';
import { errorExists, formatErrors } from '../../util/errorUtil';
import { getErrorMessageFromErrorReason } from '../../util/getErrorMessageFromErrorReason';
import { fetchAnswers } from '../fetchAnswers';
import { postprocessAnswers, preprocessAnswers } from '../processors';

export const updateAnswers = wrapThunkActionWithErrHandler<{
  answers: Answers;
  force?: boolean;
}>(
  ({ answers, force = false }) =>
    async (dispatch, getState) => {
      if (!answers || !Object.keys(answers).length) return;

      const state = getState();

      // TODO I think we can temporarily save answers in local state + session storage
      // so create inquiry can pull them; and possibly log a warning
      const dalSessionId = getDalSessionId(state);
      const primaryInsuredAddressRef = getPrimaryInsuredAddressRef(state);
      const zipKey = `${primaryInsuredAddressRef}.zipcode`;

      if (!dalSessionId) {
        return;
      }
      const allAnswers = getAnswers(state);
      const preprocessedAnswers = !force ? preprocessAnswers(answers, allAnswers) : answers;

      // If after pre-processing we get an empty object, there is nothing to update on the back-end.
      // However, we might have some answers which we want to persist locally
      // TODO We might want to extract most of the logic below and re-use in e.g. getAnswers
      let responsePayload: AnswersResponse | undefined;
      const answersKeys = Object.keys(preprocessedAnswers);
      if (answersKeys.length) {
        try {
          let response: SalesResponse<AnswersResponse> = {} as SalesResponse<AnswersResponse>;
          response = await patchAnswers({
            dalSessionId,
            answers: preprocessedAnswers,
          });

          responsePayload = response.payload;
          const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
          if (!hasSapiTarget) {
            datadogLog({
              logType: 'error',
              message: 'sapi target not found in response headers for updateAnswers',
              context: {
                logOrigin:
                  'libs/features/sales/shared/store/lib/src/inquiry/thunks/updateAnswers/updateAnswers.ts',
                functionOrigin: 'updateAnswers',
              },
            });

            throw new Error('sapi target not found in response headers for updateAnswers');
          }

          const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
          dispatch(setSapiTarget(sapiTarget));

          // We need to format and use the errors object which SAPI returns in PATCH ANSWERS response payload
          // TODO Everything inside this if block should not happen here, dependency direction is incorrect,
          // form/fields need to listen on the inquiry errors updates, inquiry should not be aware of our forms
          if (responsePayload?.data?.errors?.length) {
            const formattedErrors = formatErrors(responsePayload.data.errors);
            Object.keys(formattedErrors).forEach((key) => {
              if (errorExists(key)) {
                dispatch(setFormErrorsChangedByField({ key, errors: [formattedErrors[key]] }));
              }
            });
          }
        } catch (e) {
          const error = e as Error | SalesRequestError;

          datadogLog({
            logType: 'error',
            message: `Error updating answers - ${error?.message}`,
            context: {
              logOrigin:
                'libs/features/sales/shared/store/lib/src/inquiry/thunks/updateAnswers/updateAnswers.ts',
              functionOrigin: 'updateAnswers',
              ...(error instanceof SalesRequestError && { errorData: error.errorData }),
            },
            error,
          });

          // This is added because SAPI doesn't send answers in response payload for 400s. So we are fetching it separately.
          // TODO - Remove this after SAPI adds answers in response payload for 400s part of ECP-2752
          await dispatch(fetchAnswers({ dalSessionId }));

          let errorReason = null;
          if (e instanceof SalesRequestError) {
            const errorBody = e.errorBody?.toLowerCase();

            if (errorBody) {
              if (errorBody.includes('lob_home_not_eligible')) {
                errorReason = ErrorReason.LOB_HOME_NOT_ELIGIBLE;
              } else if (errorBody.includes('lob_auto_not_eligible')) {
                errorReason = ErrorReason.LOB_AUTO_NOT_ELIGIBLE;
              } else if (errorBody.includes('lob_renters_not_eligible')) {
                errorReason = ErrorReason.LOB_RENTERS_NOT_ELIGIBLE;
              } else if (errorBody.includes('lob_bundle_not_eligible')) {
                errorReason = ErrorReason.LOB_BUNDLE_NOT_ELIGIBLE;
              } else if (errorBody.includes('partner has no eligible products')) {
                errorReason = ErrorReason.PARTNER_NOT_ELIGIBLE;
              }
            }

            if (errorReason) {
              const errorMessage = getErrorMessageFromErrorReason(errorReason);

              // Set form error for zip code to show inline error for this scenario
              dispatch(
                setFormErrorsChangedByField({
                  key: zipKey,
                  errors: errorMessage,
                }),
              );

              return;
            }
          }

          throw e;
        }
      }

      // Merge all local answers in the following order (which is important):
      // inquiry local state <- updater function argument <- preprocessed
      // This gets us all latest unmasked and protected values,
      // whereas other values will be pulled from the response in postprocessAnswers
      const localAnswersMerged = { ...allAnswers, ...answers, ...preprocessedAnswers };
      const postprocessedAnswers = postprocessAnswers(
        responsePayload?.data?.answers || allAnswers,
        localAnswersMerged,
      );

      // A few workarounds until retiring reducers as we started skipping patch request if nothing to patch
      dispatch(
        setAnswersApiPatchSuccess({
          answers: postprocessedAnswers,
          ...(responsePayload && { errors: responsePayload.data.errors }),
        }),
      );
    },
  'updateAnswers',
);
