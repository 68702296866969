import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'MobileMonthlyPaymentsCheckoutCard',
})((theme) => ({
  button: {
    margin: '10px 0 10px 12px',
    width: '94%',
  },
  hideButtonText: {
    display: 'none',
  },
  spinnerOnly: {
    paddingRight: 15,
    [theme.breakpoints.only('md')]: {
      minWidth: 230,
    },
  },
  items: {
    marginTop: 15,
  },
  headerContainer: {},
  product: {
    marginRight: 'auto',
    paddingTop: 10,
  },
  section: {
    padding: '0 5px',
  },
  sectionHeader: { ...theme.typography.h3, textAlign: 'right' },
  sectionText: { ...theme.typography.h3, textAlign: 'right' },

  horizontalDivider: {
    margin: '10px 0',
  },
  details: {
    paddingLeft: 15,
    marginLeft: 15,
    borderLeft: `1px solid ${theme.palette.other.divider}`,
    textAlign: 'right',
    minWidth: 115,
  },
  recalculateCardContainer: {
    backgroundColor: theme.palette.warning.light,
  },
  recalculateContainer: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  recalculateBody: {
    textAlign: 'left',
    marginTop: 10,
  },
  recalculateButton: {
    margin: 'auto',
    width: '100%',
  },
  recalcButtonBg: {
    backgroundColor: theme.palette.other.caution,
    '&:hover': {
      backgroundColor: theme.palette.other.caution,
    },
  },
  infoIcon: {
    color: theme.palette.warning.main,
    display: 'block',
    margin: 'auto',
  },
  bottomDetailsWrapper: {
    margin: '0 auto',
  },
  bottomCheckoutBarContainer: {
    ...theme.mixins.shadow2,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: theme.zIndex.drawer - 148,
    padding: '5px 25px 20px 25px',
    minHeight: 83,
    [theme.breakpoints.down('md')]: {
      padding: '10px 15px',
    },
    [theme.breakpoints.only('md')]: {
      paddingBottom: 5,
    },
  },
  variantBottomCheckoutBarContainer: {
    ...theme.mixins.shadow2,
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: theme.zIndex.drawer - 149,
    padding: '20px 25px',
    minHeight: 83,
    [theme.breakpoints.down('md')]: {
      padding: '15px 15px',
      display: 'block',
    },
  },
  bottomCheckoutBarContainerRegularBG: {
    backgroundColor: theme.palette.grey[50],
  },
  bottomCheckoutBarContainerErrorBG: {
    backgroundColor: theme.palette.error.light,
  },
  bottomCheckoutBar: {
    display: 'flex',
    marginTop: 22,
  },
  bottomCheckoutBarTextDiv: {
    alignSelf: 'center',
  },
  policyBrief: {
    color: theme.palette.text.disabled,
  },
  bottomCheckoutBarButton: {
    marginLeft: 'auto',
    padding: '11px 0px 0px 0px',
  },
  checkoutBarDivider: {
    paddingLeft: 10,
    paddingRight: 10,
    height: '100%',
  },
  checkoutBarPremium: {
    display: 'flex',
    alignItems: 'center',
  },
  checkoutBarPremiumValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  checkOutBarPremiumContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  checkoutBarButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '20px 0 10px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '10px 8px',
    },
  },
  checkoutButtonRoot: {
    [theme.breakpoints.down('md')]: {
      padding: '10px 25px',
    },
  },
  fullWidthCheckoutButtonRoot: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  checkoutBarPremiumDuration: {
    ...theme.typography.body3,
    color: theme.palette.text.tertiary,
    lineHeight: '10px',
  },

  checkoutBarStateFees: {
    alignSelf: 'flex-start',
    height: 'auto',
    maxWidth: '90%',
  },
  mobileCheckoutBarWrapper: {
    textAlign: 'center',
  },
  premiumContainer: {
    ...theme.typography.body2,
    marginTop: 5,
    '& p': {
      textAlign: 'center',
    },
  },
  premiumsDividerContainer: {
    margin: '0 auto',
  },
  premiumBold: {
    ...theme.typography.body3Bold,
    textAlign: 'right',
  },
  premiums: {
    ...theme.typography.body2Bold,
    textAlign: 'right',
  },
  premiumFeesInfo: {
    ...theme.typography.body2Bold,
    textAlign: 'left',
  },
  policyDurationHeaderColumn: theme.typography.body1,
  policyDurationHeader: {
    marginBottom: 4,
  },
  policyDurationHeaderProduct: {
    ...theme.typography.body1,
  },
  premiumsDivider: {
    backgroundColor: theme.palette.other.divider,
    margin: '5px 0',
    [theme.breakpoints.down('md')]: {
      margin: '5px 0 3px 0',
    },
  },
  policyCheckoutHeaders: {
    ...theme.typography.body2,
    color: theme.palette.grey[800],
  },
  rightColumnContainer: {
    [theme.breakpoints.only('md')]: {
      borderLeft: '1px solid',
      borderColor: theme.palette.other.border,
      marginLeft: 20,
      paddingLeft: 20,
    },
  },
  leftColumnContainer: {
    [theme.breakpoints.only('md')]: {
      marginLeft: 40,
    },
  },
  mobileHeading: theme.typography.body2Bold,
  hideDetailsButton: {
    color: theme.palette.text.link,
    textAlign: 'center',
    marginBottom: 15,
    marginTop: 15,
  },
  installmentStatement: {
    ...theme.typography.body2,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 10,
  },
  textCenter: {
    textAlign: 'center',
  },
  carrierButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    gap: 15,
  },
  variantACarrierButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: 20,
  },
  divider: {
    backgroundColor: theme.palette.divider,
    border: 'none',
    height: 1,
    width: 136,
    margin: '10px 0 2px 0',
  },
  mobileVariantAStyleBottomDetailsWrapper: {
    marginLeft: 10,
  },
  variantAInstallmentStatement: {
    ...theme.typography.body2Italics,
    color: theme.palette.grey[800],
    marginTop: 5,
    marginBottom: -5,
  },
}));
